<template>
    <div class="w-full checked_body">
        <input
            :disabled="disabled"
            type="checkbox"
            :name="name"
            @input="$emit('update:modelValue', Boolean($event.target.checked))"
            :checked="modelValue">

        <div class="label_title" :class="{is_disabled: disabled}">
            {{ title }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'InputTypeCheckbox',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'update:modelValue': null,
    }
}
</script>
<style lang="scss" scoped>
.checked_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    input[type='checkbox'] {
        outline: none !important;
        color: #61CC2C;
    }
}

.label_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}

.is_disabled {
    opacity: 0.5;
}
</style>
