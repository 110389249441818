<template>
    <div class="w-full header_mobile">
        <div class="logo_div_mobile">
            <img src="@/assets/img/logo.png" alt="logo">
        </div>
    </div>
</template>
<script>
export default {
    name: 'HeaderMobile',
}
</script>
<style lang="scss" scoped>
.header_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 22px;
    gap: 10px;
    background: #0C1F38;
    height: 74px;
}

.logo_div_mobile {
    width: 100px;
    height: 44px;

    img {
        width: 100%;
    }
}
</style>
