<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body add_news_body_template">
            <first-active-row
                :name="trailblazer?.quest ? trailblazer.quest.name : 'Noname'"
                :rout_name="'TrailblazersList'"
                :title="'Trailblazer prizes'"/>

            <div class="add_news_body_template">
                <div class="period_row" v-if="start && end && diff">
                    <div class="subtitle_three">
                        Trailblazer period:
                    </div>

                    <div class="subtitle_three">
                        {{start}} - {{end}}
                    </div>

                    <div class="text_three">
                        {{diff}}
                    </div>
                </div>

                <div class="info_block"
                     v-for="num in $options.NUM_ARR"
                     :key="`prize_info_${num}`">
                    <div class="period_row">
                        <div class="subtitle_three">
                            <span>{{ $options.PLACE_SHORT_NAME[num] }}</span> place prize:
                        </div>

                        <div class="prize_info">
                            <div>
                        <span class="subtitle_four">
                            {{this[`place_${num}`]}} Xcoins
                        </span>
                                <span class="text_four">
                            for now
                        </span>
                            </div>

                            <div class="text_five" v-if="place_extra_prize_1">
                                include {{this[`place_extra_prize_${num}`]}} from sponsor
                            </div>
                        </div>
                    </div>

                    <div class="user_info" v-if="rewards[num - 1]">
                        <div class="avatar">
                            <img v-if="rewards[num - 1]?.user?.avatar" :src="rewards[num - 1].user.avatar" alt="avatar">
                            <img v-else src="../../../assets/img/ava-default.png" alt="default">
                        </div>

                        <div class="prize_info">
                            <div class="subtitle_four">
                                {{rewards[num - 1].user.full_name}}
                            </div>

                            <div class="text_four">
                                {{rewards[num - 1].user.email}}
                            </div>

                            <input-type-checkbox
                                :title="'Gift was sent'"
                                :name="`gift_was_issued_${num}`"
                                v-model="rewards[num - 1].gift_was_issued"/>

                            <input-text-area-news :title="'Notes'" :name="`notes_${num}`" v-model="rewards[num - 1].notes"/>

                            <button
                                class="white_green_btn"
                                @click="saveChange(rewards[num - 1])">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </admin-layouts>
</template>
<script>
import FirstActiveRow from "@/components/admin/FirstActiveRow.vue";
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import {
    baseErrHandler,
    getTrailblazerPlacesRewards,
    getTrailblazerWithQuest,
    setAdminNotesInReward
} from "@/services/api";
import {differentNowTime, getPeriodFormat} from "@/helpers/time.helper";
import InputTypeCheckbox from "@/components/UI/inputs/InputTypeCheckbox.vue";
import InputTextAreaNews from "@/components/UI/inputs/InputTextAreaNews.vue";

export default {
    name: 'RevisionTrailblazerPlaces',
    components: {
        InputTextAreaNews,
        InputTypeCheckbox,
        AdminLayouts,
        FirstActiveRow
    },
    props: {
        trailblazer_id: {
            type: [Number, String],
            default: '',
        },
        active_item: {
            type: String,
            default: '',
        },
    },
    NUM_ARR: [1, 2, 3],
    PLACE_SHORT_NAME: {
        1: '1st',
        2: '2nd',
        3: '3rd',
    },
    data() {
        return {
            rewards: [],
            trailblazer: null,
            start: '',
            end: '',
            diff: '',
            place_1: '',
            place_2: '',
            place_3: '',
            place_extra_prize_1: '',
            place_extra_prize_2: '',
            place_extra_prize_3: '',
        };
    },
    methods: {
        async getPlacesInfo() {
            await baseErrHandler(async () => {
                const { rewards } = await getTrailblazerPlacesRewards(this.trailblazer_id);

                if (rewards) {
                    this.rewards = rewards;
                }
            });
        },
        async getTrailblazer() {
            await baseErrHandler(async () => {
                const { trailblazer } = await getTrailblazerWithQuest(this.trailblazer_id);

                if (trailblazer) {
                    this.trailblazer = trailblazer;
                }
            });
        },
        getTrailblazerPeriod() {
            this.start = getPeriodFormat(this.trailblazer.start_time, this.timezoneOffset);
            this.end = getPeriodFormat(this.trailblazer.end_time, this.timezoneOffset);
            this.diff = differentNowTime(this.trailblazer.end_time, this.timezoneOffset);
        },
        getPrize () {
            for (const num of this.$options.NUM_ARR) {
                this['place_' + num] = (this.trailblazer.budget['place_' + num] * this.trailblazer.budget.prize_amount) / 100;
                this['place_extra_prize_' + num] = this.trailblazer.budget['place_extra_prize_' + num] ? this.trailblazer.budget['place_extra_prize_' + num] : '';
            }
        },
        async saveChange(val) {
            await baseErrHandler(async () => {
                await setAdminNotesInReward({
                    id: val.id,
                    notes: val.notes,
                    gift_was_issued: val.gift_was_issued,
                });
            });
        }
    },
    computed: {
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
    },
    async beforeMount() {
        await this.getTrailblazer();
        await this.getPlacesInfo();

        this.getTrailblazerPeriod();
        this.getPrize();
    }
}
</script>
<style lang="scss" scoped>
.add_news_body_template {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 44px;
}

.period_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.info_block {
    background: #EAF7FF;
    border-radius: 8px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.prize_info {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.user_info {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .avatar {
        width: 151px;
        height: 151px;
        overflow: hidden;
        border-radius: 24px;

        img {
            width: 100%;
        }
    }

    .prize_info {
        width: calc(100% - 171px);
        height: 100%;
    }

    .white_green_btn {
        width: 200px;
    }
}
</style>
