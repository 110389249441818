<template>
    <textarea
        ref="textAreaCustomInput"
        type="text"
        :name="name"
        placeholder=" "
        :rows="1"
        class="pt-3 pb-2 block w-full px-0 mt-0 border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
        :style="`height: ${height}px; resize:none; overflow: hidden;`"
        :value="modelValue"
        @input="parseInput($event.target.value)"
    ></textarea>

    <label
        :for="name"
        class="absolute duration-300 origin-0 text-blue-cast label-title">
        {{ title }}
    </label>
</template>
<script>
export default {
    name: 'InputTextArea',
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: 'textarea_input',
        },
    },
    emits: {
        'update:modelValue': null,
    },
    methods: {
        parseInput(val) {
            this.$emit('update:modelValue', val);
            this.auto_grow();
        },
        auto_grow() {
            const height = this.$refs.textAreaCustomInput.scrollHeight;
            if (height > this.height) {
                this.height = height;
            }
        }
    },
    data() {
        return {
            height: 59,
        };
    }
}
</script>
<style lang="scss" scoped>
label {
    top: 5.75rem;
    left: 22px;
}

textarea {
    background: none;
    z-index: 2;
}

.border-blue-cast {
    border-color: #599FD2;;
}
</style>
