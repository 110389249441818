<template>
    <div class="search_input_body" ref="searchInputBody">
        <div class="relative w-full z-0">
            <input
                @click="modelValue = ''"
                type="text"
                name="quest-description"
                placeholder=" "
                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <label for="quest-description" class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title">Search</label>
        </div>

        <div v-if="search_result && !is_selected" class="search_result_body">
            <div
                v-if="search_result?.length"
                @click.stop="itemSelect(item)"
                class="search_result_item"
                v-for="item in search_result"
                :key="item.raw.place_id">
                {{ item.label }}
            </div>

            <div v-else class="empty_search_item">
                <search-blue/>
                <div class="tray_again">
                    Sorry, but nothing found
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import SearchBlue from "@/components/svg/SearchBlue.vue";
export default {
    name: 'SearchLocation',
    components: {SearchBlue},
    props: {
        modelValue: null,
    },
    emits: {
        'update:modelValue': null,
        closeSearch: null,
    },
    beforeUnmount() {
        window.removeEventListener('click', this.outsideClick);
    },
    mounted() {
        window.addEventListener('click', this.outsideClick);
    },
    data() {
        return {
            is_selected: false,
            search_result: null,
        };
    },
    methods: {
        outsideClick(e) {
            if (!this.$el.contains(e.target)) {
                this.is_selected = false;
                this.$emit('closeSearch', null);
                this.search_result = null;
            }
        },
        itemSelect(item) {
            this.is_selected = true;
            this.$emit('closeSearch', item);
        }
    },
    computed: {
        provider() {
            return new OpenStreetMapProvider();
        },
    },
    watch: {
        modelValue(val) {
            if (val && !this.is_selected) {
                setTimeout(async () => {
                    this.search_result = await this.provider.search({ query: val });
                }, 7);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.border-blue-cast {
    border-color: #599FD2;;
}

.search_input_body {
    width: 100%;
    position: relative;
    z-index: 2;
}

.search_result_body {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    gap: 16px;
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 4px;
}

.search_result_item {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: #0C1F38;
    z-index: 3;
}

.empty_search_item {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    svg {
        width: 121px;
        height: 121px;
    }
}

.tray_again {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #599FD2;
}
</style>
