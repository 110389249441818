<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.36 6.64014C19.6184 7.89893 20.4753 9.50258 20.8223 11.2483C21.1693 12.9941 20.9909 14.8035 20.3096 16.4479C19.6284 18.0923 18.4748 19.4977 16.9948 20.4865C15.5148 21.4753 13.7749 22.0031 11.995 22.0031C10.2151 22.0031 8.47515 21.4753 6.99517 20.4865C5.51519 19.4977 4.36164 18.0923 3.68036 16.4479C2.99909 14.8035 2.82069 12.9941 3.16772 11.2483C3.51475 9.50258 4.37162 7.89893 5.63 6.64014" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 2V12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
    name: 'PowerButtonIcon'
}
</script>
