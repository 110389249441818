<template>
    <div class="marker_card"
         :class="{bonus_card: marker.bonus}"
         :id="`marker_card__${marker.id}`" v-if="marker?.id">
        <div class="marker_icon">
            <img :src="icon" alt="icon">
        </div>
        <div class="marker_inf">
            <div class="marker_name">
                {{ marker.name }}
            </div>
            <div class="marker_description">
                {{ marker.description}}
            </div>
        </div>
        <div class="marker_actions" v-if="!is_disabled">
            <button
                @click="disabledMarker(marker.id)"
                :class="{
                    pwr_btn_on: is_invalid,
                    pwr_btn_off: !is_invalid
                }">
                <power-button-icon/>
            </button>
            <button
                @click="openEditMarker(marker)"
                class="btn_edit_pencil">
                <edit-icon/>
            </button>

            <button
                @click="deleteMarker(marker.id)"
                class="btn_delete_box">
                <delete-icon/>
            </button>

            <button
                draggable="true"
                class="btn_drag_icon">
                <drag-icon/>
            </button>
        </div>
    </div>
</template>
<script>
import EditIcon from "@/components/svg/EditIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import DragIcon from "@/components/svg/DragIcon.vue";
import PowerButtonIcon from "@/components/svg/PowerButtonIcon.vue";

export default {
    name: 'MarkersCard',
    components: {
        PowerButtonIcon,
        DragIcon,
        DeleteIcon,
        EditIcon
    },
    props: {
        marker: {
            type: Object,
            default() {
                return {};
            },
        },
        default_marker: {
            type: String,
            default: '',
        },
        is_disabled: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        edit_element: null,
        delete_element: null,
        disable_element: null,
    },
    methods: {
        openEditMarker(item) {
            this.$emit('edit_element', item);
        },
        deleteMarker(marker_id) {
            this.$emit('delete_element', marker_id);
        },
        getImgUrl(path) {
            return require(`@/assets/${path}`);
        },
        disabledMarker(marker_id) {
            this.$emit('disable_element', {marker_id, is_invalid: this.is_invalid});
        }
    },
    computed: {
        icon() {
            if (this.marker?.icon) {
                return this.marker.icon;
            }
            if (this.default_marker) {
                return  this.default_marker;
            }
            return this.getImgUrl('DefMarkerIcon.png');
        }
    },
    watch: {
        ['marker.is_invalid'](val) {
            this.is_invalid = val;
        }
    },
    data() {
        return {
            is_invalid: this.marker.is_invalid,
        };
    },
}
</script>
<style lang="scss" scoped>
.marker_card {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
}

.bonus_card {
    box-shadow: 0 2px 3px rgba(255,140,0, 0.32);
}

.marker_actions {
    max-width: 216px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.marker_icon {
    max-width: 82px;
    min-width: 82px;
    max-height: 82px;
    min-height: 82px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.marker_inf {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    min-height: 82px;
}

.marker_name {
    max-height: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #214B41;

    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap;
}

.marker_description {
    max-height: 48px;
    min-height: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #364652;

    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
}
</style>
