<template>
    <challenge-reviewed-layout
        @close_modal="closeModal"
        @approve_challenge="approve"
        @reject_challenge="openRejectModal"
        :is_quest_blog="is_quest_blog_type"
        :is_blog_type="is_blog_type"
        :show="show"
        :answer="answer"
        :challenge="challenge">
        <div class="reviewed_answer_body">
            <div class="reviewed_answer_column"
                 :class="{width_in_blog_type_first: is_blog_type}">
                <div class="conditions">
                    <div class="sub_title_column">
                        Challenge conditions:
                    </div>

                    <ul v-if="view_conditions.length">
                        <li
                            class="inf_in_column"
                            v-for="(item, index) in view_conditions"
                            :key="`challenge_conditions_${index}_${item.order}`">
                            {{item.condition}}
                        </li>
                    </ul>
                </div>

                <div v-if="challenge.enable_hint" class="conditions">
                    <div class="sub_title_column">
                        Challenge hint:
                    </div>

                    <div class="inf_in_column">
                        {{challenge.hint_text}}
                    </div>

                    <div class="hint_photo" v-if="challenge?.cover">
                        <img :src="challenge.cover" alt="hint_photo">
                    </div>
                </div>
            </div>

            <div class="reviewed_answer_column user_answer_body"
                 :class="{width_in_blog_type_second: is_blog_type}">
                <div class="reviewed_answer_body">
                    <div class="reviewed_answer_column">
                        <div class="sub_title_column">
                            Pending date
                        </div>

                        <div class="inf_in_column">
                            {{returnViewDate(answer.answer_time)}}
                        </div>
                    </div>

                    <div class="reviewed_answer_column">
                        <div class="sub_title_column">
                            User
                        </div>

                        <div class="inf_in_column">
                            {{answer.author_full_name}}
                        </div>
                    </div>
                </div>

                <div class="reviewed_answer_photo" v-if="is_blog_type">
                    <div class="sub_title_column">
                        {{ is_blog_type ? 'Post title' : 'Title' }}
                    </div>

                    <div class="inf_in_column" v-if="answer?.title">
                        {{answer.title}}
                    </div>
                </div>

                <div class="reviewed_answer_photo" v-if="is_blog_type">
                    <div class="sub_title_column">
                        Post
                    </div>

                    <div class="inf_in_column" v-if="answer?.message">
                        {{answer.message}}
                    </div>
                </div>

                <div class="reviewed_answer_photo">
                    <div class="sub_title_column">
                        {{ sub_title_photo_or_video }}
                    </div>

                    <div class="answer_photos" v-if="is_blog_type && answer?.photos">
                        <div
                            class="photo_div"
                            v-for="(item, index) in answer.photos"
                            :key="`answer_photos_${index}`" >
                            <img :src="item" :alt="`answer_photos_${index}`">
                        </div>
                    </div>

                    <div class="answer_photo" v-if="is_photo_type">
                        <img :src="is_receipt ? answer.receipt : answer.photo" alt="answer_photo">
                    </div>

                    <button
                        v-if="is_video_type && answer?.video"
                        class="white_green_btn btn_width"
                        @click="downloadVideo(answer.video)">
                        Download video file
                    </button>
                </div>
            </div>
        </div>
    </challenge-reviewed-layout>
</template>
<script>
import ChallengeReviewedLayout from "@/components/layouts/modals/ChallengeReviewedLayout.vue";
import {baseErrHandler, getChallengeById, setReviewChallenge} from "@/services/api";
import {
    typByCategory,
    typeArchive,
    typeBlog,
    typePhoto,
    typeQuestBlog,
    typeReceipt,
    typeVideo
} from "@/enums/ReviewedChallengeType";
import {randomString} from "@/helpers/functions.helper";
import { saveAs } from 'file-saver';
import {getDateFormat} from "@/helpers/time.helper";

export default {
    name: 'ChallengeReviewModal',
    components: {
        ChallengeReviewedLayout
    },
    props: {
        answer: {
            type: Object,
            required: true,
        },
        challenge: {
            type: Object,
            required: true,
        },
        conditions: {
            type: String,
            default: '',
        },
        selected_type: {
            type: String,
            default: '',
        },
    },
    emits: {
        close_modal: null,
        open_reject_modal: null,
        'update:answer': null,
    },
    methods: {
        async openModal() {
            this.show = true;
        },
        closeModal(confirm) {
            this.$emit('close_modal', confirm);
            this.show = false;
        },
        downloadVideo(url) {
            const name = randomString();
            saveAs(url, name);
        },
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
        openRejectModal() {
            this.$emit('open_reject_modal');
        },
        async approve(is_reviewed, message = null) {
            const requestData = {
                is_reviewed,
                answer_id: this.answer.id,
                type: this.selected_type,
                message,
            };

            console.log({requestData});

            await baseErrHandler(async () => {
                const { answer } = await setReviewChallenge(requestData);

                if (answer) {
                    this.$emit('update:answer', answer);
                }
            });
        },
    },
    data() {
        return {
            show: false,
            type_photo: typePhoto,
            message: null,
        };
    },
    computed: {
        view_conditions() {
            if (this.challenge?.[this.conditions]) {
                return this.challenge[this.conditions];
            }
            return [];
        },
        type() {
            let type;
            if (this.selected_type !== typeArchive) {
                type = this.selected_type;
            } else {
                type = typByCategory(this.challenge.type);
            }
            return type;
        },
        is_photo_type() {
            return typePhoto.includes(this.type);
        },
        is_receipt() {
            return typeReceipt.includes(this.type);
        },
        is_video_type() {
            return typeVideo.includes(this.type);
        },
        is_blog_type() {
            return typeBlog.includes(this.type);
        },
        is_quest_blog_type() {
            return typeQuestBlog.includes(this.type);
        },
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
        sub_title_photo_or_video() {
            if (this.is_photo_type) {
                return 'Photo';
            }
            if (this.is_video_type) {
                return 'Video';
            }
            if (this.is_blog_type) {
                return 'Photos';
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.reviewed_answer_body {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.reviewed_answer_photo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reviewed_answer_column {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
}

.user_answer_body {
    padding: 15px;

    background: #EAF7FF;
    border-radius: 15px;
}

.conditions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 0 0 21px;
    }
}

.sub_title_column {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.inf_in_column {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    color: #364652;

}

.hint_photo {
    width: 50%;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.answer_photo {
    max-width: 50%;

    img {
        width: 100%;
    }
}

.answer_photos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;

    .photo_div {
        width: 197px;

        img {
            width: 100%;
        }
    }
}

.btn_width {
    width: fit-content;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.width_in_blog_type_first {
    width: 25%;
}

.width_in_blog_type_second {
    width: 75%;
}
</style>
