<template>
    <div
        class="modal-shadow saved_popup min-h-screen flex flex-col flex flex-col"
        v-if="show"
        @click.self="closeModal(false)">
        <div class="inner flex-col flex p-6">
            <div class="login_header">
                <div class="logo_div">
                    <img src="../../assets/img/logo.png" alt="logo">
                </div>
            </div>
            <div class="content">
                <div class="tile" v-if="title">
                    {{ title }}
                </div>

                <div class="message in_center" :class="{error_color: is_error}">
                    {{ message }}
                </div>

                <div class="w-full button_row">
                    <button
                        class="green_btn button-text"
                        @click.stop="closeModal(true)">
                        Ok
                    </button>

                    <button
                        v-if="is_cancel_button"
                        class="green_btn button-text"
                        @click.stop="closeModal(false)">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MessageModal',
    props: {
        message: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        is_error: {
            type: Boolean,
            default: false,
        },
        is_cancel_button: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'confirm_actions': null,
        'no_confirm_actions': null,
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        openModal() {
            const body = document.querySelector("body");
            body.style.overflow = "hidden";

            this.show = true;
        },
        closeModal(is_confirm = false) {
            const body = document.querySelector("body");
            body.style.overflow = "auto";

            if (is_confirm) {
                this.$emit('confirm_actions');
            } else {
                this.$emit('no_confirm_actions');
            }

            this.show = false;
        },
    },
}
</script>
<style lang="scss" scoped>
.modal-shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.39);
}
.saved_popup {
    z-index: 10;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px;
    gap: 20px;
    width: 675px;
    background: #FFFFFF;
    border-radius: 24px 24px 20px 20px;
}

.inner .login_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    gap: 10px;
    border-radius: 20px 20px 0 0;

    width: 675px;
    height: 170px;

    background: #0F2542;
}

.inner .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    gap: 20px;

    width: 675px;
}

.inner .btn {
    width: 100px;
    height: 40px;
    margin: 0 20px;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.75;
}
.inner .btn:hover {
    opacity: 1;
}

.logo_div {
    width: 250px;
    height: 110px;
    img {
        width: 100%;
        height: 100%;
    }
}

.tile {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #214B41;
    text-align: left;
}

.message {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    span {
        text-align: center;
    }
}

.button_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.sub_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #61CC2C;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.label-title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
}

.button-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.border_error {
    border-color: #F44A3E;
}

.in_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.error_color {
    color: #F12121;
}
</style>
