<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9144_10535)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 13.7039 3.47351 15.2973 4.29613 16.6556C5.28702 15.8793 6.39853 15.249 7.59778 14.7976C6.90842 13.8788 6.5 12.7371 6.5 11.5C6.5 8.46243 8.96243 6 12 6C15.0376 6 17.5 8.46243 17.5 11.5C17.5 12.7371 17.0916 13.8788 16.4022 14.7976C17.6014 15.2491 18.7129 15.8794 19.7038 16.6557C20.5265 15.2973 21 13.704 21 12C21 7.02944 16.9706 3 12 3ZM6.25808 18.9307C7.71145 20.1361 9.55016 20.8931 11.562 20.9895C11.7072 20.9965 11.8532 21 12 21C12.1359 21 12.2711 20.997 12.4056 20.991C14.43 20.9012 16.2807 20.1426 17.7418 18.9308C16.1464 17.7182 14.1578 17 11.9999 17C9.842 17 7.8535 17.7182 6.25808 18.9307ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM14.5 11.5C14.5 12.8807 13.3807 14 12 14C10.6193 14 9.5 12.8807 9.5 11.5C9.5 10.1193 10.6193 9 12 9C13.3807 9 14.5 10.1193 14.5 11.5Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_9144_10535">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
<script>
export default {
    name: 'UsersIcon',
}
</script>
