<template>
    <div class="admin_sidebar">
        <div class="w-full main_menu_item" :class="{active_item: is_active.quests}">
            <div
                class="w-full menu_item"
                :class="{is_active: is_active.quests}"
                @click="setActive('quests')">
                <quests-menu-icon/>
                Quests
            </div>

            <div class="sub_menu" v-if="is_active.quests">
                <router-link class="sub_menu_item" to="/admin/active-quests">
                    <point-menu-icon/>
                    Active quests
                </router-link>
                <router-link class="sub_menu_item" to="/admin/awaiting-quests">
                    <point-menu-icon/>
                    Awaiting quests
                </router-link>
                <router-link class="sub_menu_item" to="/admin/rejected-quests">
                    <point-menu-icon/>
                    Rejected quests
                </router-link>
                <router-link class="sub_menu_item" to="/admin/ended-quests">
                    <point-menu-icon/>
                    Ended quests
                </router-link>
            </div>
        </div>

        <div class="w-full main_menu_item" :class="{active_item: is_active.trailblazers}">
            <div
                class="w-full menu_item"
                :class="{is_active: is_active.trailblazers}"
                @click="routeGo('TrailblazersList')">
                <prize-icon/>
                Trailblazer prizes
            </div>
        </div>

        <div class="w-full main_menu_item" :class="{active_item: is_active.challenges}">
            <div
                class="w-full menu_item"
                :class="{is_active: is_active.challenges}"
                @click="routeGo('ChallengesAdmin')">
                <challenges-icon/>
                Challenges
            </div>
        </div>

        <div class="w-full main_menu_item" :class="{active_item: is_active.users}">
            <div
                class="w-full menu_item"
                :class="{is_active: is_active.users}"
                @click="routeGo('UsersAdmin')">
                <users-icon/>
                Users
            </div>
        </div>


        <div class="w-full main_menu_item" :class="{active_item: is_active.reports}">
            <div
                class="w-full menu_item"
                :class="{is_active: is_active.reports}"
                @click="routeGo('ReportsAdmin')">
                <reports-menu-icon/>
                Reports
            </div>
        </div>

        <div class="w-full main_menu_item" :class="{active_item: is_active.variables}">
            <div
                class="w-full menu_item"
                :class="{is_active: is_active.variables}"
                @click="routeGo('VariablesAdmin')">
                <variables-icon/>
                Variables
            </div>
        </div>

        <div class="w-full main_menu_item" :class="{active_item: is_active.news}">
            <div
                class="w-full menu_item"
                :class="{is_active: is_active.news}"
                @click="routeGo('NewsAdmin')">
                <news-icon/>
                News
            </div>
        </div>

    </div>
</template>
<script>
import UsersIcon from "@/components/svg/UsersIcon.vue";
import QuestsMenuIcon from "@/components/svg/QuestsMenuIcon.vue";
import PointMenuIcon from "@/components/svg/PointMenuIcon.vue";
import ReportsMenuIcon from "@/components/svg/ReportsMenuIcon.vue";
import ChallengesIcon from "@/components/svg/ChallengesIcon.vue";
import VariablesIcon from "@/components/svg/VariablesIcon.vue";
import NewsIcon from "@/components/svg/NewsIcon.vue";
import PrizeIcon from "@/components/svg/PrizeIcon.vue";
export default {
    name: 'AdminSidebar',
    props: {
        active_item: {
            type: String,
            default: '',
        }
    },
    components: {
        PrizeIcon,
        NewsIcon,
        VariablesIcon,
        UsersIcon,
        ChallengesIcon,
        QuestsMenuIcon,
        PointMenuIcon,
        ReportsMenuIcon,
    },
    data() {
        return {
            is_active: {
                quests: false,
                trailblazers: false,
                reports: false,
                challenges: false,
                users: false,
                variables: false,
                news: false,
            }
        };
    },
    mounted() {
        this.setActive(this.active_item, false);
    },
    methods: {
        setActive(item, is_toggle = true) {
            for (let [key, value] of Object.entries(this.is_active)) {
                if (key === item) {
                    this.is_active[key] = is_toggle ? !value : true;
                } else {
                    this.is_active[key] = false;
                }
            }
        },
        async routeGo(route) {
            await this.$router.push({name: route});
        }
    },
}
</script>
<style lang="scss" scoped>
.admin_sidebar {
    width: 235px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 0;
}

.main_menu_item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border-radius: 16px;
}

.active_item {
    background: #FFFFFF;
    box-shadow: 0 2px 12px rgba(12, 31, 56, 0.05);
    border-radius: 16px;
}

.menu_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 0;
    gap: 8px;
    border-radius: 16px;
    cursor: pointer;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    color: #364652;
}

.is_active {
    color: #4CA12F;
}

.sub_menu {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sub_menu_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 8px;
    border-radius: 12px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    color: #364652;
}

.active-link {
    color: #4CA12F;
    background: #DFF5D5;

    svg {
        transform: scale(1.5);
    }
}
</style>
