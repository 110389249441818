<template>
    <revision-quest-layouts
        :quest_id="quest_id"
        :active_item="active_item"
        :quest_name="quest_name"
        :trailblazer_id="trailblazer_id"
        :active_tab="active_tab">

        <div class="w-full centered_body">
            <div class="card_body">
                <div class="w-full card_title" v-if="trailblazer_id">
                    General badges
                </div>

                <div class="w-full item_in_card">
                    <input-type-checkbox
                        :title="'Badge for completing all markers'"
                        :name="'completing_all_markers'"
                        :disabled="true"
                        v-model="completing_all_markers"/>

                    <image-upload-imit
                        :key="'completing_all_markers_badge'"
                        v-if="completing_all_markers"
                        :default_marker="completing_all_markers_badge"
                        :default_image="'Badge/GreenCheckBadge.png'"/>

                    <input-type-checkbox
                        :title="'Badge for completing all markers and completing all challenges'"
                        :name="'completing_all_challenges'"
                        :disabled="true"
                        v-model="completing_all_challenges"/>

                    <image-upload-imit
                        v-if="completing_all_challenges"
                        :default_marker="completing_all_challenges_badge"
                        :default_image="'Badge/ChallengeBadge.png'"/>

                    <input-type-checkbox
                        :title="'Badge for quest participation'"
                        :name="'is_quest_blog'"
                        :disabled="true"
                        v-model="quest_participation"/>

                    <image-upload-imit
                        v-if="quest_participation"
                        :default_marker="quest_participation_badge"
                        :default_image="'Badge/ParticipationBadge.png'"/>
                </div>
            </div>

            <div class="card_body" v-if="trailblazer_id">
                <div class="w-full card_title">
                    Trailblazer badges:
                </div>

                <div class="item_title">
                    1st place badge
                </div>

                <div class="w-full item_in_card">
                    <image-upload-imit
                        :default_marker="first_place"
                        :default_image="'Badge/FirstPlace.png'"/>
                </div>

                <div class="item_title">
                    2nd place badge
                </div>

                <div class="w-full item_in_card">
                    <image-upload-imit
                        :default_marker="second_place"
                        :default_image="'Badge/SecondPlace.png'"/>
                </div>

                <div class="item_title">
                    3rd place badge
                </div>

                <div class="w-full item_in_card">
                    <image-upload-imit
                        :default_marker="three_place"
                        :default_image="'Badge/ThreePlace.png'"/>
                </div>
            </div>
        </div>
    </revision-quest-layouts>
</template>
<script>
import RevisionQuestLayouts from "@/components/layouts/create_quests/RevisionQuestLayouts.vue";
import {
    baseErrHandler,
    getBadgesByQuestId,
} from "@/services/api";
import InputTypeCheckbox from "@/components/UI/inputs/InputTypeCheckbox.vue";
import ImageUploadImit from "@/components/UI/imitations/ImageUploadImit.vue";

export default {
    name: 'RevisionBadges',
    components: {
        ImageUploadImit,
        InputTypeCheckbox,
        RevisionQuestLayouts,
    },
    props: {
        quest_name: {
            type: String,
            default: '',
        },
        active_item: {
            type: String,
            default: '',
        },
        active_tab: {
            type: String,
            default: 'Badges',
        },
        quest_id: {
            type: Number,
            default: null,
        },
        trailblazer_id: {
            type: [Number, String],
            default: '',
        }
    },
    data() {
        return {
            quest_badges_id: null,
            completing_all_markers: false,
            completing_all_markers_badge: null,
            completing_all_challenges: false,
            completing_all_challenges_badge: null,
            quest_participation: false,
            quest_participation_badge: null,

            trailblazer_badge_id: null,
            first_place: null,
            second_place: null,
            three_place: null,
        };
    },
    async beforeMount() {
        if (this.quest_id) {
            await this.getBadges();
        }
    },
    methods: {
        async initQuestBadgesData(quest_badges) {
            if (quest_badges) {
                this.quest_badges_id = quest_badges.id ? quest_badges.id : null;
                this.completing_all_markers = quest_badges.completing_all_markers;
                this.completing_all_challenges = quest_badges.completing_all_challenges;
                this.quest_participation = quest_badges.quest_participation;
                this.completing_all_markers_badge = quest_badges.completing_all_markers_badge ? quest_badges.completing_all_markers_badge : '';
                this.quest_participation_badge = quest_badges.quest_participation_badge ? quest_badges.quest_participation_badge : '';
                this.quest_participation_badge = quest_badges.completing_all_challenges_badge ? quest_badges.completing_all_challenges_badge : '';
            }
        },
        async initTrailblazerBadge(trailblazer_badge) {
            if (trailblazer_badge) {
                // this.trailblazer_id = trailblazer_badge?.trailblazer_id ? trailblazer_badge.trailblazer_id : null;
                this.trailblazer_badge_id = trailblazer_badge.id ? trailblazer_badge.id : null;
                this.first_place = trailblazer_badge.badge_place_1;
                this.second_place = trailblazer_badge.badge_place_2;
                this.three_place = trailblazer_badge.badge_place_3;
            }
        },
        async getBadges() {
            await baseErrHandler(async () => {
                const { quest_badges, trailblazer_badges } = await getBadgesByQuestId(this.quest_id);

                if (quest_badges) {
                    await this.initQuestBadgesData(quest_badges);
                }

                if (trailblazer_badges) {
                    await this.initTrailblazerBadge(trailblazer_badges);
                }
            });
        },
        addParamToUrl(key, param) {
            const url = new URL(window.location.href);
            url.searchParams.set(key, param);
            window.history.replaceState(null, null, url);
        },
    },
    computed: {
        is_trailblazer() {
            return Boolean(this.trailblazer_id);
        },
    },
    watch: {
        trailblazer_id(val) {
            if (val) {
                this.addParamToUrl('trailblazer_id', val);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.centered_body {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.in_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card_body {
    width: 675px;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.card_title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: #214B41;
}

.item_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}

.item_in_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sub_item_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.sub_item {
    width: 327px;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.border-red-cast {
    border-color: #DD1760;;
}

.gp-20 {
    gap: 20px;
}

.checked_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    input[type='checkbox'] {
        outline: none !important;
        color: #61CC2C;
    }
}

.label_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}

.parent_range {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.range_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.range_label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.percent_view {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: right;

    color: #000000;
}

.w_range {
    width: 611px;
    display: flex;
    align-items: center;

    .range_line {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 3px;
        background: #CCEAFF;
        top: calc(50% - 3px/2);
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            background: #61CC2C;
        }
    }

    .error_line {
        background: #DD1760;
    }
}

.image_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_div {
    width: 300px;
    height: 300px;

    img {
        width: 100%;
        height: 100%;
    }
}

.default_image_div {
    width: 150px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
    }
}

.blog_challenge_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .item_in_row {
        width: 327px;
    }
}
</style>
