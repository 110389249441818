<template>
    <div class="relative w-full z-3">
        <input
            @click="openSelectTime"
            type="text"
            name="time"
            placeholder=" "
            class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
            :class="{'border-red-cast': error}"
            v-model="view_time"
        />
        <label for="time" class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title">{{ title }}</label>

        <div v-if="is_view" class="date_picker_parent">
            <date-picker
                locale="en"
                v-model="date"
                mode="dateTime"
                :timezone="this.calendarTimezone(this.selected_timezone?.gmt ? this.selected_timezone.gmt : null)" />

            <div class="in_center">
                <button class="white_green_btn" @click="selectTime">
                    Select
                </button>
            </div>
        </div>
    </div>

</template>

<script>
import { Calendar, DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
export default {
    name: 'DateAndTimePicker',
    components: {
        Calendar,
        DatePicker,
    },
    props: {
        error: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Date and time',
        },
        selected_timezone: {
            type: Object,
            default() {
                return {};
            }
        },
        preset_time: {
            type: String,
            default: '',
        }
    },
    emits: {
        time_select: null,
    },
    data() {
        return {
            isSelected: false,
            is_view: false,
            date: new Date(),
        };
    },
    unmounted() {
        window.removeEventListener('click', this.outsideClick);
    },
    mounted() {
        window.addEventListener('click', this.outsideClick);
    },
    methods: {
        openSelectTime() {
            this.is_view = true;
        },
        selectTime() {
            this.isSelected = true;
            this.is_view = false;
            this.$emit('time_select', this.date);
        },
        calendarTimezone(gmt) {
            if (gmt && gmt !== 'undefined') {
                return this.$options.CALENDAR_TIMEZONES[gmt].name;
            }
            return 'America/New_York';
        },
        outsideClick(e) {
            if (!this.$el.contains(e.target)) {
                this.is_view = false;
            }
        },
    },
    watch: {
        preset_time(new_value) {
            if (new_value) {
                this.isSelected = true;
                this.date = moment(new_value).toDate();
            } else {
                this.isSelected = false;
            }
        },
    },
    computed: {
        view_time() {
            if (!this.isSelected) {
                return '';
            }
            return moment(this.date).utcOffset(this.selected_timezone.gmt).format(this.time_format)
        },
        time_format() {
            return 'DD-MM-YYYY hh:mm A';
        }
    },
    CALENDAR_TIMEZONES: {
        "-12:00": {
            name: "Asia/Kamchatka",
            tz: -12,
        },
        "-11:00": {
            name: 'Pacific/Niue',
            tz: -11,
        },
        "-10:00": {
            name: 'US/Hawaii',
            tz: 10,
        },
        "-09:00": {
            name: 'America/Anchorage',
            tz: -9,
        },
        "-08:00": {
            name: 'America/Los_Angeles',
            tz: -8,
        },
        "-07:00": {
            name: 'America/Boise',
            tz: -7,
        },
        "-06:00": {
            name: 'America/Chicago',
            tz: -6,
        },
        "-05:00": {
            name: 'America/New_York',
            tz: -5,
        },
        "-04:00": {
            name: 'America/Aruba',
            tz: -4,
        },
        "-03:00": {
            name: 'America/Buenos_Aires',
            tz: -3,
        },
        "-02:00": {
            name: 'Brazil/DeNoronha',
            tz: -2,
        },
        "-01:00": {
            name: 'Atlantic/Azores',
            tz: -1,
        },
        "+00:00": {
            name: 'UTC',
            tz: 0,
        },
        "00:00": {
            name: 'UTC',
            tz: 0,
        },
        "-00:00": {
            name: 'UTC',
            tz: 0,
        },
        "+01:00": {
            name: 'Europe/Amsterdam',
            tz: 1,
        },
        "+02:00": {
            name: 'Europe/Athens',
            tz: 2,
        },
        "+03:00": {
            name: 'Europe/Moscow',
            tz: 3,
        },
        "+04:00": {
            name: 'Indian/Mahe',
            tz: 4,
        },
        "+05:00": {
            name: 'Asia/Ashgabat',
            tz: 5,
        },
        "+06:00": {
            name: 'Asia/Dhaka',
            tz: 6,
        },
        "+07:00": {
            name: 'Asia/Bangkok',
            tz: 7,
        },
        "+08:00": {
            name: 'Asia/Hong_Kong',
            tz: 8,
        },
        "+09:00": {
            name: 'Asia/Pyongyang',
            tz: 9,
        },
        "+10:00": {
            name: 'Australia/Sydney',
            tz: 10,
        },
        "+11:00": {
            name: 'Asia/Magadan',
            tz: 11,
        },
        "+12:00": {
            name: "Pacific/Fiji",
            tz: 12,
        }
    },
}
</script>
<style lang="scss" scoped>
@import '~v-calendar/dist/style.css';

input {
    height: 45px;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.date_picker_parent {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 10px;

    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 4px;
}

.border-red-cast {
    border-color: #DD1760;;
}
</style>
