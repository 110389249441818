<template>
    <create-quest-layouts
        @quest_copied="questCopied"
        :is_create="is_create"
        :quest_id="id_this_quest"
        :quest_name="quest_name"
        :trailblazer_id="trailblazer_id"
        :active_item="active_item"
        :parent_rout_name="parent_rout_name"
        :active_tab="active_tab">

        <div class="w-full centered_body">
            <div class="card_body">
                <div class="w-full card_title">
                    General Info
                </div>

                <div class="w-full item_in_card">
                    <div class="item_title">
                        Quest start:
                    </div>

                    <div class="w-full sub_item_row">
                        <div class="sub_item">
                            <date-and-time-picker
                                :error="errors.quest_start_time"
                                :preset_time="quest_start_time ? quest_start_time : ''"
                                id="data_picker_quest_start"
                                :selected_timezone="start_timezone_quest"
                                @time_select="questStartTimeSelect"
                                :key="'data_picker_quest_start'"/>
                        </div>
                        <div class="sub_item">
                            <timezone-select
                                :timezone_pre_search="quest_start_timezone"
                                :timezones="timezones"
                                :title="'Timezone'"
                                @selected_zone="selectedQuestStartTimeZone"
                                id="selected_quest_start_timezone"
                                :key="'selected_quest_start_timezone'"/>
                        </div>
                    </div>

                    <div class="item_title">
                        Quest end (optional):
                    </div>

                    <div class="w-full sub_item_row">
                        <div class="sub_item">
                            <date-and-time-picker
                                :preset_time="quest_end_time ? quest_end_time : ''"
                                id="data_picker_quest_end"
                                :selected_timezone="end_timezone_quest"
                                @time_select="questEndTimeSelect"
                                :key="'data_picker_quest_end'"/>
                        </div>
                        <div class="sub_item">
                            <timezone-select
                                :timezone_pre_search="quest_end_timezone"
                                :timezones="timezones"
                                :title="'Timezone'"
                                @selected_zone="selectedQuestEndTimeZone"
                                :key="'selected_quest_end_timezone'"/>
                        </div>
                    </div>

                    <div class="item_title">
                        Quest info:
                    </div>

                    <input-type-text
                        :title="`Quest name`"
                        :name="`quest-name`"
                        v-model="quest_name"/>

                    <input-type-text
                        :title="`Quest subtitle`"
                        :name="quest_subtitle"
                        v-model="quest_subtitle"/>

                    <input-type-text
                        :title="`Quest description`"
                        :name="`quest_description`"
                        v-model="quest_description"/>

                    <div class="item_title">
                        Quest cover photo:
                    </div>

                    <upload-image
                        :pre_image="image ? image : ''"
                        :no_deleted="true"
                        @delete_image_in_base="deleteCoverInBase"
                        @selected_image="selectedQuestCover" />

                    <div class="item_title">
                        Quest location:
                    </div>

                    <input-type-select
                        :is_opacity="true"
                        :name="'types_by_locations'"
                        :title="'Quest location'"
                        :view_items="types_by_locations"
                        :view="'name'"
                        v-model="type_location"/>

                    <div class="item_title">
                        Quest categories:

<!--                        <button class="btn_edit_pencil" @click="addQuestCategories">-->
<!--                            <plus-icon/>-->
<!--                        </button>-->
                    </div>

                    <div class="w-full sub_item_row align-items-center">
                        <multi-select
                            :pre_selected_ids="pre_selected_ids"
                            @select_items="selectedCategoryQuest"
                            :items="categories_quest"
                            :error="errors.quest_categories"
                            :view="'name'"
                            id="multiselect_categories_quests"
                            :key="'multiselect_categories_quests'"/>

                        <button class="btn_add_plus" @click="addQuestCategories">
                            <plus-icon/>
                        </button>
                    </div>
<!--                    <multi-select-->
<!--                        :pre_selected_ids="pre_selected_ids"-->
<!--                        @select_items="selectedCategoryQuest"-->
<!--                        :items="categories_quest"-->
<!--                        :error="errors.quest_categories"-->
<!--                        :view="'name'"-->
<!--                        id="multiselect_categories_quests"-->
<!--                        :key="'multiselect_categories_quests'"/>-->

                    <div class="item_title">
                        Quest blog challenge
                    </div>

                    <input-type-checkbox
                        :title="`Enable quest blog challenge`"
                        :name="`is_quest_blog`"
                        v-model="is_quest_blog"/>

                    <quest-blog-inf
                        v-if="is_quest_blog"
                        v-model:users_offered="users_offered"
                        v-model:coins_reward="coins_reward"
                        v-model:character_number="character_number"
                        v-model:number_photos="number_photos"/>

                </div>
            </div>

            <div class="card_body">
                <div class="w-full card_title">
                    Trailblazer
                </div>

                <div class="w-full item_in_card">
                    <div class="w-full checked_body">
                        <input
                            type="checkbox"
                            name="is_trailblazer"
                            v-model="is_trailblazer">

                        <div class="label_title">
                            Enable trailblazer for this quest
                        </div>
                    </div>

                    <div v-if="is_trailblazer" class="item_title">
                        Trailblazer start:
                    </div>

                    <div v-if="is_trailblazer" class="w-full sub_item_row">
                        <div class="sub_item">
                            <date-and-time-picker
                                :error="errors.trailblazer_start_time"
                                :preset_time="trailblazer_start_time ? trailblazer_start_time : ''"
                                id="data_picker_trailblazer_start"
                                :selected_timezone="start_timezone_trailblazer"
                                @time_select="trailblazerStartTimeSelect"
                                :key="'data_picker_trailblazer_start'"/>
                        </div>
                        <div class="sub_item">
                            <timezone-select
                                :timezone_pre_search="trailblazer_start_timezone"
                                :timezones="timezones"
                                :title="'Timezone'"
                                @selected_zone="selectedTrailblazerStartTimeZone"
                                id="selected_trailblazer_start_timezone"
                                :key="'selected_trailblazer_start_timezone'"/>
                        </div>
                    </div>

                    <div v-if="is_trailblazer" class="item_title">
                        Trailblazer end:
                    </div>

                    <div v-if="is_trailblazer" class="w-full sub_item_row">
                        <div class="sub_item">
                            <date-and-time-picker
                                :error="errors.trailblazer_end_time"
                                :preset_time="trailblazer_end_time ? trailblazer_end_time : ''"
                                id="data_picker_trailblazer_end"
                                :selected_timezone="end_timezone_trailblazer"
                                @time_select="trailblazerEndTimeSelect"
                                :key="'data_picker_trailblazer_end'"/>
                        </div>
                        <div class="sub_item">
                            <timezone-select
                                :timezone_pre_search="trailblazer_end_timezone"
                                :timezones="timezones"
                                :title="'Timezone'"
                                @selected_zone="selectedTrailblazerEndTimeZone"
                                :key="'selected_trailblazer_end_timezone'"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card_body">
                <div class="w-full card_title">
                    Budget
                </div>

                <div class="w-full item_in_card">
                    <input-type-number
                        :title="'Quest access price (Xcoins)'"
                        v-model="quest_access_price"
                        :id="'quest_access_price'" />
                </div>
            </div>

            <div v-if="is_trailblazer" class="card_body">
                <div class="w-full card_title">
                    Trailblazer budget
                </div>

                <div class="w-full item_in_card">
                    <input-type-number
                        :title="'Prize amount'"
                        v-model="prize_amount"
                        :id="'prize_amount'" />

                    <div class="item_title">
                        Prize pool distribution
                    </div>

                    <div class="parent_range">
                        <label class="form-label range_label">1st place prize amount</label>

                        <div class="range_body">
                            <div id="_trailblazer_budget" class="relative w_range">
                                <div class="range_line"
                                     :class="{error_line: is_trailblazer_budget_error || errors.trailblazer_budget}"></div>
                                <input
                                    @change="handleHengePlace('place_1')"
                                    type="range"
                                    class="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
                                    min="0"
                                    max="100"
                                    step="1"
                                    v-model="place_1"
                                />
                            </div>

                            <div class="percent_view">
                                {{place_1}}%
                            </div>
                        </div>
                    </div>

                    <div class="parent_range">
                        <label class="form-label range_label">2nd place prize amount</label>

                        <div class="range_body">
                            <div class="relative w_range">
                                <div class="range_line"
                                     :class="{error_line: is_trailblazer_budget_error || errors.trailblazer_budget}"></div>
                                <input
                                    @change="handleHengePlace('place_2')"
                                    type="range"
                                    class="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
                                    min="0"
                                    max="100"
                                    step="1"
                                    v-model="place_2"
                                />
                            </div>

                            <div class="percent_view">
                                {{place_2}}%
                            </div>
                        </div>
                    </div>

                    <div class="parent_range">
                        <label class="form-label range_label">3rd place prize amount</label>

                        <div class="range_body">
                            <div class="relative w_range">
                                <div class="range_line"
                                     :class="{error_line: is_trailblazer_budget_error || errors.trailblazer_budget}"></div>
                                <input
                                    @change="handleHengePlace('place_3')"
                                    type="range"
                                    class="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
                                    min="0"
                                    max="100"
                                    step="1"
                                    v-model="place_3"
                                />
                            </div>

                            <div class="percent_view">
                                {{place_3}}%
                            </div>
                        </div>
                    </div>

                    <div class="item_title">
                        Sponsor’s extra prize
                    </div>

                    <input-type-text
                        :name="`place_extra_prize_1`"
                        :title="`1st place extra prize (Xcoins)`"
                        v-model="place_extra_prize_1"/>

                    <input-type-text
                        :name="`place_extra_prize_2`"
                        :title="`2nd place extra prize (Xcoins)`"
                        v-model="place_extra_prize_2"/>

                    <input-type-text
                        :name="`place_extra_prize_3`"
                        :title="`3rd place extra prize (Xcoins)`"
                        v-model="place_extra_prize_3"/>

                </div>
            </div>

            <div class="card_body">
                <div class="w-full card_title">
                    Private Quest
                </div>

                <div class="w-full item_in_card">
                    <div class="switch_qr_row">
                        <switch-elem
                            :is_disabled="is_disabled_save"
                            @change_value="getDipLinc"
                            v-model="is_private"/>

                        <div class="item_title">
                            Private Quest {{is_private ? 'On' : 'Off'}}
                        </div>
                    </div>

                    <div class="item_title" v-if="private_link && is_private">
                        {{ private_link }}
                    </div>
                </div>
            </div>

            <div class="card_body">
                <div class="in_center gp-20">
                    <button class="white_green_btn" @click="goBack">
                        Cancel
                    </button>

                    <button
                        class="white_green_btn"
                        :disabled="is_disabled_save"
                        @click="questCreate(!!is_edit)">
                        {{ !is_edit ? 'Save quest' : 'Edit quest' }}
                    </button>
                </div>
            </div>
        </div>

        <add-quest-category-modal ref="addQuestCategoryModal" @create_category="addCreatedCategories"/>
    </create-quest-layouts>
</template>
<script>
import CreateQuestLayouts from "@/components/layouts/create_quests/CreateQuestLayouts.vue";
import TimezoneSelect from "@/components/UI/inputs/TimezoneSelect.vue";
import MultiSelect from "@/components/UI/inputs/MultiSelect.vue";
import DateAndTimePicker from "@/components/UI/datepickers/DateAndTimePicker.vue";
import UploadImage from "@/components/UI/images/UploadImage.vue";
import {
    allCategoriesQuest,
    allTimezones,
    baseErrHandler,
    createQuest,
    deleteQuestCover, getQuestTypeByLocation,
    questInfoGet
} from "@/services/api";
import {utsTimeISO} from "@/helpers/time.helper";
import {errorMessage} from "@/services/messages";
import InputTypeText from "@/components/UI/inputs/InputTypeText.vue";
import InputTypeCheckbox from "@/components/UI/inputs/InputTypeCheckbox.vue";
import QuestBlogInf from "@/components/admin/quest/QuestBlogInf.vue";
import InputTypeNumber from "@/components/UI/inputs/InputTypeNumber.vue";
import InputTypeSelect from "@/components/UI/inputs/InputTypeSelect.vue";
import SwitchElem from "@/components/UI/SwitchElem.vue";
import PlusIcon from "@/components/svg/PlusIcon.vue";
import AddQuestCategoryModal from "@/components/modals/admin/quests/AddQuestCategoryModal.vue";

export default {
    name: 'CreateQuestInfo',
    components: {
        AddQuestCategoryModal,
        PlusIcon,
        SwitchElem,
        InputTypeSelect,
        InputTypeNumber,
        QuestBlogInf,
        InputTypeCheckbox,
        InputTypeText,
        CreateQuestLayouts,
        TimezoneSelect,
        MultiSelect,
        DateAndTimePicker,
        UploadImage,
    },
    props: {
        active_item: {
            type: String,
            default: '',
        },
        active_tab: {
            type: String,
            default: 'Info',
        },
        is_create: {
            type: [Boolean, Number, String],
            default: true,
        },
        parent_rout_name: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            is_edit: false,
            timezones: [],
            categories_quest: [],
            timezone_pre_search: null,
            pre_selected_ids: [],
            types_by_locations: [],
            type_location: {
                id: null,
                name: null,
            },

            quest_id: null,
            quest_name: '',
            quest_subtitle: '',
            quest_description: '',
            image: null,
            quest_categories: null,
            is_trailblazer: false,
            is_quest_blog: false,
            quest_start_timezone: null,
            quest_start_time: '',
            quest_end_timezone: {},
            quest_end_time: '',
            location_type_id: null,
            is_private: false,
            private_link: '',
            create_completed: false,

            quest_budget_id: null,
            quest_access_price: null,

            quest_blog_challenge_id: null,
            users_offered: null,
            coins_reward: null,
            character_number: null,
            number_photos: null,

            trailblazer_id: null,
            trailblazer_start_timezone: {},
            trailblazer_start_time: '',
            trailblazer_end_timezone: {},
            trailblazer_end_time: '',

            trailblazer_budget_id: null,
            prize_amount: '',
            place_1: 0,
            place_2: 0,
            place_3: 0,
            place_extra_prize_1: null,
            place_extra_prize_2: null,
            place_extra_prize_3: null,

            errors: {
                quest_name: false,
                quest_subtitle: false,
                quest_start_time: false,
                quest_start_timezone: false,
                quest_categories: false,
                quest_budget: false,
                trailblazer_budget: false,
                users_offered: false,
                character_number: false,
                number_photos: false,
                place_extra_prize_1: false,
                place_extra_prize_2: false,
                place_extra_prize_3: false,
                trailblazer_start_timezone: false,
                trailblazer_start_time: false,
                trailblazer_end_time: false,
            },
        };
    },
    async beforeMount() {
        await this.pageInit();
    },
    async mounted() {
    },
    methods: {
        addQuestCategories() {
            this.$refs.addQuestCategoryModal.openModal();
        },
        async pageInit() {
            await this.getTypesByLocations();
            await this.getTimezones();
            await this.getCategoriesQuest();
            if (this.$route?.query?.quest_id) {
                await this.getQuestInfo(this.$route.query.quest_id);
                this.is_edit = true;
            }
        },
        get_default_img_url (path) {
            return require(path);
        },
        setSelectTime(item, val) {
            if (val) {
                this[item] = utsTimeISO(val);
            } else {
                this[item] = '';
            }
        },
        selectedQuestStartTimeZone(val) {
            this.quest_start_timezone = val;
        },
        questStartTimeSelect(val) {
            this.errors.quest_start_time = false;
            this.setSelectTime('quest_start_time', val);
        },
        selectedQuestEndTimeZone(val) {
            this.quest_end_timezone = val;
        },
        questEndTimeSelect(val) {
            this.setSelectTime('quest_end_time', val);
        },
        selectedTrailblazerStartTimeZone(val) {
            this.trailblazer_start_timezone = val;
        },
        trailblazerStartTimeSelect(val) {
            this.errors.trailblazer_start_time = false;
            this.setSelectTime('trailblazer_start_time', val);
        },
        selectedTrailblazerEndTimeZone(val) {
            this.trailblazer_end_timezone = val;
        },
        trailblazerEndTimeSelect(val) {
            this.errors.trailblazer_end_time = false;
            this.setSelectTime('trailblazer_end_time', val);
        },
        addCreatedCategories(val) {
            this.quest_categories.push(val);
            this.$refs.addQuestCategoryModal.closeModal();
        },
        selectedCategoryQuest(val) {
            this.errors.quest_categories = false;
            this.quest_categories = val;
        },
        selectedQuestCover(image) {
            this.image = image;
        },
        async getTimezones() {
            await baseErrHandler(async () => {
                const { timezones } = await allTimezones();
                this.timezones = timezones;
                this.timezone_pre_search = this.preSearchTimezone();
                this.quest_start_timezone = this.timezone_pre_search;
                this.quest_end_timezone = this.timezone_pre_search;
                this.trailblazer_start_timezone = this.timezone_pre_search;
                this.trailblazer_end_timezone = this.timezone_pre_search;
            });
        },
        async getCategoriesQuest() {
            await baseErrHandler(async () => {
                const { categories } = await allCategoriesQuest();
                this.categories_quest = categories;
            });
        },
        preSearchTimezone() {
            if (this.timezones && this.timezones.length) {
                for (const elem of this.timezones) {
                    if (elem.gmt === this.timezoneOffset) {
                        return elem;
                    }
                }
            }
        },
        handleHengePlace(item) {
            if (this.is_trailblazer_budget_error) {
                if (item === 'place_3') {
                    this.place_3 = 100 - this.place_1 - this.place_2;
                }
                if (item === 'place_2') {
                    this.place_2 = 100 - this.place_1 - this.place_3;
                }
                if (item === 'place_1') {
                    this.place_1 = 100 - this.place_3 - this.place_2;
                }
            }
        },
        dateValidate() {
            let result = false;
            let error_item_id = '';

            if (!this.quest_name) {
                result = true;
                this.errors.quest_name = true;
                if (!error_item_id) {
                    error_item_id = '_quest_name';
                }
            }

            if (!this.quest_subtitle) {
                result = true;
                this.errors.quest_subtitle = true;
                if (!error_item_id) {
                    error_item_id = '_quest_subtitle';
                }
            }

            if (!this.quest_start_time) {
                result = true;
                this.errors.quest_start_time = true;
                if (!error_item_id) {
                    error_item_id = 'data_picker_quest_start';
                }
            }

            if (!this.quest_start_timezone || !this.quest_start_timezone?.id) {
                result = true;
                this.errors.quest_start_timezone = true;
                if (!error_item_id) {
                    error_item_id = 'selected_quest_start_timezone';
                }
            }

            if (!this.quest_categories || !this.quest_categories.length) {
                result = true;
                this.errors.quest_categories = true;
                if (!error_item_id) {
                    error_item_id = 'multiselect_categories_quests';
                }
            }

            if (this.is_trailblazer && this.trailblazer_budget_sum !== 100) {
                result = true;
                this.errors.trailblazer_budget = true;
                if (!error_item_id) {
                    error_item_id = '_trailblazer_budget';
                }
            }

            if (this.is_quest_blog) {
                if (!this.users_offered) {
                    result = true;
                    this.errors.users_offered = true;
                    if (!error_item_id) {
                        error_item_id = '_users_offered';
                    }
                }

                if (!this.character_number) {
                    result = true;
                    this.errors.character_number = true;
                    if (!error_item_id) {
                        error_item_id = '_character_number';
                    }
                }

                if (!this.number_photos) {
                    result = true;
                    this.errors.number_photos = true;
                    if (!error_item_id) {
                        error_item_id = '_number_photos';
                    }
                }
            }

            if (this.is_trailblazer) {
                if (!this.trailblazer_start_time) {
                    result = true;
                    this.errors.trailblazer_start_time = true;
                    if (!error_item_id) {
                        error_item_id = 'data_picker_trailblazer_start';
                    }
                }

                if (!this.trailblazer_start_timezone) {
                    result = true;
                    this.errors.trailblazer_start_timezone = true;
                    if (!error_item_id) {
                        error_item_id = 'selected_trailblazer_start_timezone';
                    }
                }

                if (!this.trailblazer_end_time) {
                    result = true;
                    this.errors.trailblazer_end_time = true;
                    if (!error_item_id) {
                        error_item_id = 'data_picker_trailblazer_end';
                    }
                }
            }

            if (error_item_id) {
                document.getElementById(error_item_id).scrollIntoView();
            }

            return result;
        },
        skipError() {
            this.errors =  {
                quest_name: false,
                quest_subtitle: false,
                quest_start_time: false,
                quest_start_timezone: false,
                quest_categories: false,
                quest_budget: false,
                trailblazer_budget: false,
                users_offered: false,
                character_number: false,
                number_photos: false,
                place_extra_prize_1: false,
                place_extra_prize_2: false,
                place_extra_prize_3: false,
                trailblazer_start_timezone: false,
                trailblazer_start_time: false,
                trailblazer_end_time: false,
            };
        },
        async getQuestInfo(quest_id) {
            await baseErrHandler(async () => {
                this.skipError();

                const {
                    quest,
                    quest_budget,
                    trailblazer,
                    trailblazer_budget,
                    quest_blog_challenge
                } = await questInfoGet(quest_id);

                await this.initQuestData(quest);
                await this.initQuestBudgetData(quest_budget);
                await this.initTrailblazerData(trailblazer);
                await this.initTrailblazerBudgetData(trailblazer_budget);
                await this.initQuestBlogChallengeData(quest_blog_challenge);
            });
        },
        async questCreate(is_edit, no_validate = false) {
            await baseErrHandler(async () => {
                this.skipError();

                if (!no_validate) {
                    const valid = this.dateValidate();
                    if (valid) {
                        errorMessage('Checked errors and try again!');
                        return;
                    }
                }

                const data = {
                    //quest
                    id: this.quest_id,
                    name: this.quest_name,
                    subtitle: this.quest_subtitle,
                    description: this.quest_description,
                    cover: this.image,
                    is_blog_challenge: this.is_quest_blog,
                    is_trailblazer: this.is_trailblazer,
                    start_time: this.quest_start_time,
                    start_timezone_id: this.quest_start_timezone?.id ? this.quest_start_timezone.id : null,
                    end_time: this.quest_end_time,
                    end_timezone_id: this.quest_end_timezone?.id ? this.quest_end_timezone.id : null,
                    categories: this.quest_categories.map((el) => el.id),
                    create_completed: this.create_completed,
                    location_type_id: this.location_type_id,
                    is_private: this.is_private,
                    private_link: this.private_link,

                    //quest budget
                    quest_budget_id: this.quest_budget_id,
                    access_price: this.quest_access_price,

                    //quest blog challenge
                    quest_blog_challenge_id: this.quest_blog_challenge_id,
                    users_offered: this.is_quest_blog ? this.users_offered : null,
                    coins_reward: this.is_quest_blog ? this.coins_reward : null,
                    character_number: this.is_quest_blog ? this.character_number : null,
                    number_photos: this.is_quest_blog ? this.number_photos : null,

                    //trailblazer time
                    trailblazer_id: this.trailblazer_id,
                    trailblazer_start_timezone_id: this.is_trailblazer && this.trailblazer_start_timezone?.id ? this.trailblazer_start_timezone.id : null,
                    trailblazer_start_time: this.is_trailblazer ? this.trailblazer_start_time : null,
                    trailblazer_end_timezone_id: this.is_trailblazer && this.trailblazer_end_timezone?.id ? this.trailblazer_end_timezone.id : null,
                    trailblazer_end_time: this.is_trailblazer ? this.trailblazer_end_time : null,

                    //trailblazer budget
                    trailblazer_budget_id: this.trailblazer_budget_id,
                    prize_amount: this.is_trailblazer && this.prize_amount ? this.prize_amount : null,
                    place_extra_prize_1: this.is_trailblazer ? this.place_extra_prize_1 : null,
                    place_extra_prize_2: this.is_trailblazer ? this.place_extra_prize_2 : null,
                    place_extra_prize_3: this.is_trailblazer ? this.place_extra_prize_3 : null,
                    place_1: this.is_trailblazer ? this.place_1 : null,
                    place_2: this.is_trailblazer ? this.place_2 : null,
                    place_3: this.is_trailblazer ? this.place_3 : null,
                };

                const {
                    quest,
                    quest_budget,
                    trailblazer,
                    trailblazer_budget,
                    quest_blog_challenge
                } = await createQuest(data);

                await this.initQuestData(quest);
                await this.initTrailblazerData(trailblazer);

                if (!is_edit && !no_validate) {
                    await this.$router.push({
                        path: `/admin/create-markers-list`,
                        query: {
                            quest_name: this.quest_name,
                            quest_id: this.quest_id,
                            trailblazer_id: this.trailblazer_id
                        }
                    });
                } else {
                    await this.initQuestBudgetData(quest_budget);
                    await this.initTrailblazerBudgetData(trailblazer_budget);
                    await this.initQuestBlogChallengeData(quest_blog_challenge);
                }
            });
        },
        async questCopied() {
            await this.pageInit();
        },
        async initQuestData(quest) {
            if (quest) {
                const pre_selected_ids = quest.categories ? quest.categories.map((el) => el.id) : [];
                this.quest_id = quest.id ? quest.id : null;
                this.quest_name = quest.name;
                this.quest_subtitle = quest.subtitle;
                this.quest_description = quest.description ? quest.description : '';
                this.is_quest_blog = quest.is_blog_challenge;
                this.is_trailblazer = quest.is_trailblazer;
                this.quest_start_time = quest.start_time ? quest.start_time : '';
                this.quest_end_time = quest.end_time ? quest.end_time : '';
                this.quest_start_timezone = quest.start_timezone_id ? this.setTimezoneById(quest.start_timezone_id) : this.timezone_pre_search;
                this.quest_end_timezone = quest.end_timezone_id ? this.setTimezoneById(quest.end_timezone_id) : this.timezone_pre_search;
                this.quest_categories = this.categories_quest.filter((el) => pre_selected_ids.includes(el.id));
                this.image = quest.cover;
                this.pre_selected_ids = pre_selected_ids;
                this.location_type_id = quest.location_type_id;
                if (this.location_type_id) {
                    this.type_location = this.getTypeLocationById(this.location_type_id);
                }
                this.is_private = quest.is_private;
                this.private_link = quest.private_link;
                this.create_completed = quest.create_completed;
            }
        },
        getTypeLocationById(id) {
            for (const item of this.types_by_locations) {
                if (item.id === id) {
                    return item;
                }
            }
        },
        async initQuestBudgetData(quest_budget) {
            if (quest_budget) {
                this.quest_budget_id = quest_budget.id ? quest_budget.id : null;
                this.quest_access_price = quest_budget.access_price;
            }
        },
        async initTrailblazerData(trailblazer) {
            if (trailblazer) {
                this.trailblazer_id = trailblazer.id ? trailblazer.id : null;
                this.trailblazer_start_timezone = trailblazer.start_timezone_id ? this.setTimezoneById(trailblazer.start_timezone_id) : this.timezone_pre_search;
                this.trailblazer_start_time = trailblazer.start_time;
                this.trailblazer_end_timezone = trailblazer.end_timezone_id ? this.setTimezoneById(trailblazer.end_timezone_id) : this.timezone_pre_search;
                this.trailblazer_end_time = trailblazer.end_time;
            }
        },
        async initTrailblazerBudgetData(trailblazer_budget) {
            if (trailblazer_budget) {
                this.trailblazer_budget_id = trailblazer_budget.id ? trailblazer_budget.id : null;
                this.prize_amount = trailblazer_budget?.prize_amount ? trailblazer_budget.prize_amount : '';
                this.place_extra_prize_1 = trailblazer_budget.place_extra_prize_1;
                this.place_extra_prize_2 = trailblazer_budget.place_extra_prize_2;
                this.place_extra_prize_3 = trailblazer_budget.place_extra_prize_3;
                this.place_1 = trailblazer_budget.place_1 ? trailblazer_budget.place_1 : 0;
                this.place_2 = trailblazer_budget.place_2 ? trailblazer_budget.place_2 : 0;
                this.place_3 = trailblazer_budget.place_3 ? trailblazer_budget.place_3 : 0;
            }
        },
        async initQuestBlogChallengeData(quest_blog_challenge) {
            if (quest_blog_challenge) {
                this.quest_blog_challenge_id = quest_blog_challenge.id ? quest_blog_challenge.id : null;
                this.users_offered = quest_blog_challenge.users_offered;
                this.coins_reward = quest_blog_challenge.coins_reward;
                this.character_number = quest_blog_challenge.character_number;
                this.number_photos = quest_blog_challenge.number_photos;
            }
        },
        setTimezoneById(id) {
            if (!this.timezones.length || !id) {
                return {};
            }
            for (const timezone of this.timezones) {
                if (timezone.id === id) {
                    return timezone;
                }
            }
        },
        async deleteCoverInBase() {
            if (this.quest_id) {
                await baseErrHandler(async () => {
                    await deleteQuestCover(this.quest_id);
                });
            }
        },
        async goBack() {
            await this.$router.push('/admin/active-quests');
        },
        addParamToUrl(key, param) {
            const url = new URL(window.location.href);
            url.searchParams.set(key, param);
            window.history.replaceState(null, null, url);
        },
        async getTypesByLocations() {
            await baseErrHandler(async () => {
                const { types } = await getQuestTypeByLocation();
                if (types) {
                    this.types_by_locations = types.sort(function (a, b) {
                        if (a.id > b.id) {
                            return 1;
                        }
                        if (a.id < b.id) {
                            return -1;
                        }
                        return 0;
                    });
                }
            });
        },
        async getDipLinc() {
            if (this.is_private) {
                await this.questCreate(false, true);
            } else {
                this.private_link = '';
            }
        }
    },
    computed: {
        trailblazer_budget_sum() {
            this.errors.trailblazer_budget = false;
            return Number(this.place_1) + Number(this.place_2) + Number(this.place_3);
        },
        is_trailblazer_budget_error() {
            let tr_sum = 0;
            tr_sum = this.trailblazer_budget_sum;
            return tr_sum > 100;
        },
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
        is_disabled_save() {
            return this.is_trailblazer_budget_error ||
                !this.location_type_id ||
                !this.image ||
                (!this.quest_name || !this.quest_name.length) ||
                (!this.quest_subtitle || !this.quest_subtitle.length) ||
                !this.quest_start_time ||
                (!this.quest_start_timezone || !this.quest_start_timezone?.id) ||
                (!this.quest_categories || !this.quest_categories.length) ||
                (this.is_trailblazer && this.trailblazer_budget_sum !== 100) ||
                (
                    this.is_quest_blog &&
                    (
                        !this.users_offered ||
                        !this.character_number ||
                        !this.number_photos
                    )
                ) || (
                    this.is_trailblazer &&
                    (
                        !this.trailblazer_start_time ||
                        !this.trailblazer_start_timezone ||
                        !this.trailblazer_end_time ||
                        !this.trailblazer_end_timezone
                    )
                );
        },
        id_this_quest() {
            return this.quest_id;
        },
        end_timezone_trailblazer() {
            if (this.trailblazer_end_timezone) {
                return this.trailblazer_end_timezone;
            }
            return this.timezone_pre_search;
        },
        end_timezone_quest() {
            if (this.quest_end_timezone) {
                return this.quest_end_timezone;
            }
            return this.timezone_pre_search;
        },
        start_timezone_trailblazer() {
            if (this.trailblazer_start_timezone) {
                return this.trailblazer_start_timezone;
            }
            return this.timezone_pre_search;
        },
        start_timezone_quest() {
            if (this.quest_start_timezone) {
                return this.quest_start_timezone;
            }
            return this.timezone_pre_search;
        }
    },
    watch: {
        trailblazer_id(val) {
            if (val) {
                this.addParamToUrl('trailblazer_id', val);
            }
        },
        ['type_location.id'](val) {
            if (val) {
                this.location_type_id = val;
            }
        },
        // async is_create(val, old) {
        //     console.log('!!!!!!!!!!!!!');
        //     console.log({val});
        //     console.log({old});
        //     if (val !== old && this.$route?.query?.quest_id) {
        //         console.log('RELOAD');
        //         // this.getTimezones();
        //         // this.getQuestInfo(this.$route.query.quest_id);
        //         // this.is_edit = true;
        //         await this.pageInit();
        //     }
        // }
        is_create(val, old) {
            console.log('!!!!!!!!!!!!!');
            console.log({val});
            console.log({old});
            if (val !== old && this.$route?.query?.quest_id) {
                console.log('RELOAD');
                this.getTypesByLocations();
                this.getTimezones();
                this.getCategoriesQuest();
                this.$nextTick(() => {
                    this.getQuestInfo(this.$route.query.quest_id);
                    this.is_edit = true;
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.centered_body {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.in_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card_body {
    width: 675px;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.card_title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: #214B41;
}

.item_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}

.item_in_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sub_item_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.sub_item {
    width: 327px;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.border-red-cast {
    border-color: #DD1760;;
}

.gp-20 {
    gap: 20px;
}

.checked_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    input[type='checkbox'] {
        outline: none !important;
        color: #61CC2C;
    }
}

.label_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}

.parent_range {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.range_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.range_label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.percent_view {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: right;

    color: #000000;
}

.w_range {
    width: 611px;
    display: flex;
    align-items: center;

    .range_line {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 3px;
        background: #CCEAFF;
        top: calc(50% - 3px/2);
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            background: #61CC2C;
        }
    }

    .error_line {
        background: #DD1760;
    }
}

.image_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_div {
    width: 300px;
    height: 300px;

    img {
        width: 100%;
        height: 100%;
    }
}

.default_image_div {
    width: 150px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
    }
}

.switch_qr_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.align-items-center {
    align-items: center;
}
</style>
