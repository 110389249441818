<template>
    <div class="modal_shadow" v-if="show">
        <div class="inner" ref="modalInner" :id="`challenge_reviewed_modal_layout__${layout_id}`">
            <div class="first_inf_row">
                <div class="modal_title" v-if="challenge?.name || is_quest_blog">
                    {{!is_quest_blog ? challenge.name : 'Quest post challenge'}}
                </div>

                <div class="category_status" v-if="challenge?.category">
                    {{challenge.category.title}} <span v-if="challenge.is_mandatory">/ <span class="status_span">Mandatory</span></span>
                </div>
            </div>

            <div class="description_row" v-if="challenge?.description">
                {{challenge.description}}
            </div>

            <slot></slot>

            <div class="review_inf" v-if="answer.is_reviewed">
                <div class="review_column">
                    <div class="sub_title_column">
                        Approval date
                    </div>
                    <div class="inf_in_column">
                        {{returnViewDate(answer.reviewed_time)}}
                    </div>
                </div>

                <div class="review_column">
                    <div class="sub_title_column">
                        Admin
                    </div>
                    <div class="inf_in_column">
                        {{answer.reviewer_email}}
                    </div>
                </div>
            </div>

            <div class="button_row">
                <button
                    class="white_green_btn btn_width"
                    @click="closeModal(answer.is_reviewed)">
                    Close
                </button>

                <button
                    v-if="!answer.is_reviewed"
                    class="white_red_btn btn_width"
                    @click="modalReject">
                    Reject
                </button>

                <button
                    v-if="!answer.is_reviewed"
                    class="white_green_btn btn_width"
                    @click="approve(true)">
                    Approve
                </button>

                <button
                    v-if="answer.is_reviewed && answer.is_good && is_blog_type"
                    class="white_green_btn btn_width"
                    @click="publish">
                    Publish
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {randomString} from "@/helpers/functions.helper";
import {getDateFormat} from "@/helpers/time.helper";

export default {
    name: 'ChallengeReviewedLayout',
    props: {
        answer: {
            type: Object,
            required: true,
        },
        challenge: {
            type: Object,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
        is_quest_blog: {
            type: Boolean,
            default: false,
        },
        is_blog_type: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        close_modal: null,
        reject_challenge: null,
        approve_challenge: null,
    },
    data() {
        return {};
    },
    methods: {
        async closeModal(confirm) {
            this.$emit('close_modal', confirm);
        },
        async approve(is_review) {
            this.$emit('approve_challenge', is_review);
        },
        async modalReject() {
            this.$emit('reject_challenge');
        },
        async publish() {
            //
        },
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
    },
    computed: {
        layout_id() {
            return randomString();
        },
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
    },
    watch: {
        show(val) {
            if (val) {
                this.$nextTick(() => {
                    document.getElementById(`challenge_reviewed_modal_layout__${this.layout_id}`)
                        .scrollIntoView(true);
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.modal_shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: #0C1F38;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: calc(100% - 60px);
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
    position: relative;
}

.first_inf_row {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.modal_title {
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    color: #214B41;
}

.category_status {
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;

    color: #364652;

    .status_span {
        color: #FF3D00;
    }
}

.description_row {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;

    color: #364652;
}

.review_inf {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .review_column {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.sub_title_column {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.inf_in_column {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    color: #364652;

}

.button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.btn_width {
    width: 205px;
}
</style>
