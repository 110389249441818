<template>
    <div class="set_new_body w-full">
        <div class="text_title w-full">
            Now you can set a new password
        </div>

        <div class="set_form w-full">
            <password-input
                :key="`password`"
                :modelValue="password"
                @update:modelValue="password = $event"
                :error="error.password"/>

            <password-input
                :key="`password_confirm`"
                :modelValue="confirm_password"
                @update:modelValue="confirm_password = $event"
                title="Confirm new password"
                :error="error.confirm_password"/>
        </div>

        <button
            class="btn btn_green"
            :disabled="disabled_button"
            @click="setNewPassword">
            Save password
        </button>
    </div>
</template>
<script>
import PasswordInput from "@/components/UI/inputs/PasswordInput";
import {baseErrHandler, setNewPassword} from "@/services/api";
export default {
    name: 'SetNewPassword',
    components: {PasswordInput},
    created() {
        this.email = this.$route?.query?.email;
        this.token = this.$route?.query?.token;
    },
    data() {
        return {
            email: null,
            token: null,
            password: '',
            confirm_password: '',
            error: {
                password: '',
                confirm_password: '',
            }
        }
    },
    methods: {
        resetError() {
            this.error.confirm_password = '';
            this.error.password = '';
        },
        async setNewPassword() {
            this.resetError();
            if (this.confirm_password !== this.password) {
                this.error.confirm_password = 'Passwords do not match';
                return;
            }

            const data = {
                email: this.email,
                token: this.token,
                password: this.password,
            }

            await baseErrHandler(async () => {
                const { success } = await setNewPassword(data);
                if (success) {
                    await this.$router.push('/mobile-emit/congratulation-set-password');
                }
            });
        },
    },
    watch: {
        password() {
            this.resetError();
        },
        confirm_password() {
            this.resetError();
        },
    },
    computed: {
        disabled_button() {
            return !(this.password.length >= 6 && this.confirm_password.length >= 6);
        }
    },
}
</script>
<style lang="scss" scoped>
.set_new_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 0 22px;
}

.text_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #214B41;
}

.set_form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border-radius: 16px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    transition: all 0.8s;

    color: #FFFFFF;
    background: #61CC2C;
    border: 1px solid #61CC2C;

    &:hover {
        color: #61CC2C;
        background: #FFFFFF;
        border: 1px solid #61CC2C;
    }

    &:disabled {
        color: #FFFFFF;
        background: #4a5568;
        border: 1px solid #4a5568;
    }
}
</style>
