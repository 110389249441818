<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-in-news :title="'Trailblazer prizes'" :key_view="false"/>

            <table-template
                v-if="table_view"
                v-model:search="requestData.search"
                v-model:perPage="requestData.perPage"
                v-model:page="requestData.page"
                v-model:sort="requestData.sort"
                :clicked_row="true"
                @clicked_row="openTrailblazer"
                :columns="columns"
                :data="trailblazers"
                :total_count="total_count"/>

            <empty-quests-data
                v-if="!trailblazers.length"
                :message="!table_view ? 'Nothing here. Let’s add your quest.' : 'Sorry, nothing found.'"/>
        </div>
    </admin-layouts>
</template>
<script>
import TableTemplate from "@/components/TableTemplate.vue";
import EmptyQuestsData from "@/components/admin/EmptyQuestsData.vue";
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import {differentNowTime, getPeriodFormat, tableGetDate} from "@/helpers/time.helper";
import {baseErrHandler, getTrailblazersList} from "@/services/api";
import FirstRowInNews from "@/components/admin/FirstRowInNews.vue";

export default {
    name: 'TrailblazersList',
    components: {
        FirstRowInNews,
        AdminLayouts,
        EmptyQuestsData,
        TableTemplate
    },
    props: {
        active_item: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            trailblazers: [],
            columns: [
                {
                    title: 'Name',
                    field: 'quest.name',
                    width: 'w-2/12',
                    sort: false,
                },
                {
                    title: 'Period',
                    field: 'end_time',
                    width: 'w-2/12',
                    sort: false,
                    is_html: true,
                    function: (val) => {
                        return this.getTrailblazerPeriod(val);
                    }
                },
                {
                    title: 'Trailblazer budget',
                    field: 'budget.prize_amount',
                    width: 'w-2/12',
                    sort: false,
                },
                {
                    title: '1st place prize',
                    field: 'budget.prize_amount',
                    width: 'w-2/12',
                    sort: false,
                    is_html: true,
                    function: (val) => {
                        return this.getPrize(val, 1);
                    }
                },
                {
                    title: '2nd place prize',
                    field: 'budget.prize_amount',
                    width: 'w-2/12',
                    sort: false,
                    is_html: true,
                    function: (val) => {
                        return this.getPrize(val, 2);
                    }
                },
                {
                    title: '3rd place prize',
                    field: 'budget.prize_amount',
                    width: 'w-2/12',
                    sort: false,
                    is_html: true,
                    function: (val) => {
                        return this.getPrize(val, 3);
                    }
                }
            ],
            requestData: {
                sort: {
                    type: 'desc',
                    field: 'created_at',
                },
                page: 1,
                perPage: 10,
                search: '',
                active: 1,
            },
            total_count: 0,
        };
    },
    methods: {
        async getTrailblazers() {
            await baseErrHandler(async () => {
                const { list, total_count } = await getTrailblazersList(this.requestData);

                this.trailblazers = list;
                this.total_count = total_count;
            });
        },
        async openTrailblazer(row) {
            await this.$router.push({
                path: `/admin/revision-trailblazer/${row.id}`,
            });
        },
        getTrailblazerPeriod(val) {
            const start = getPeriodFormat(val.start_time, this.timezoneOffset);
            const end = getPeriodFormat(val.end_time, this.timezoneOffset);
            const diff = differentNowTime(val.end_time, this.timezoneOffset);

            return '<span style="font-weight: 700;font-size: 18px;line-height: 26px;color: #214B41;">' + start + ' - ' + end + '<span/> <br/><span style="font-weight: 500;font-size: 14px;line-height: 18px;color: #000000;">' + diff + '</span>'
        },
        getPrize (val, place) {
            const xCoins = (val?.budget['place_' + place] * val?.budget.prize_amount) / 100;
            const extra_prize = val?.budget['place_extra_prize_' + place] ? val.budget['place_extra_prize_' + place] : '';

            return '<span style="font-weight: 700;font-size: 16px;line-height: 24px;color: #006CBA;">' + xCoins + ' Xcoins <span/> <span style="font-weight: 400;font-size: 16px;line-height: 24px;color: #000000;">for now</span> <br v-if="' + extra_prize + '"/> <span v-if="' + extra_prize + '" style="font-weight: 500;font-size: 12px;line-height: 18px;color: #364652;">include ' + extra_prize + ' from sponsor</span>';
        }
    },
    watch: {
        ['requestData.page']() {
            this.getTrailblazers();
        },
        ['requestData.perPage']() {
            this.requestData.page = 1;
            this.getTrailblazers();
        },
        ['requestData.sort']() {
            this.requestData.page = 1;
            this.getTrailblazers();
        },
        ['requestData.search']() {
            this.requestData.page = 1;
            this.getTrailblazers();
        }
    },
    computed: {
        table_view() {
            if (!this.trailblazers.length && !this.requestData.search) {
                return false;
            }
            return true;
        },
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
    },
    async beforeMount() {
        await this.getTrailblazers();
    }
}
</script>
<style lang="scss" scoped>
//.period {
//    font-style: normal;
//    font-weight: 700;
//    font-size: 18px;
//    line-height: 26px;
//    display: flex;
//    align-items: center;
//    text-align: center;
//    color: #214B41;
//}
//
//.diff {
//    font-style: normal;
//    font-weight: 500;
//    font-size: 14px;
//    line-height: 18px;
//    display: flex;
//    align-items: center;
//    text-align: center;
//    color: #000000;
//}
</style>
