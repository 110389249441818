<template>
    <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 5L0 10L1.23704e-07 0L5 5Z" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'ArrRightIcon'
}
</script>
