<template>
    <div class="relative w-full z-0">
        <input
            :disabled="is_disabled || set_disabled"
            type="text"
            :name="name"
            placeholder=" "
            class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
            :class="{is_disabled: is_disabled || set_disabled}"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <label
            :for="name"
            class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title"
            :class="{_opacity_null: is_disabled, gray_text: set_disabled}">
            {{ title }}
        </label>
    </div>
</template>
<script>
export default {
    name: 'InputTypeText',
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        is_disabled: {
            type: Boolean,
            default: false,
        },
        set_disabled: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'update:modelValue': null,
    }
}
</script>
<style lang="scss" scoped>
.border-blue-cast {
    border-color: #599FD2;;
}

.is_disabled {
    color: #9CA8B6;
    border-color: #9CA8B6;
}

._opacity_null {
    opacity: 0;
}

.gray_text {
    color: #9CA8B6;
}
</style>
