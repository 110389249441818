export const is_string = (val) => {
    if (typeof val === 'string' || val instanceof String) {
        return true;
    }
    return false;
};

export const randomString = () => {
    return Math.random()
        .toString(36)
        .replace(/[^a-z0-9]+/g, '')
        .substr(0, 10);
};

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
};
