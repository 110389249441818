<template>
    <div class="first_row">
        <div class="title_page" v-if="is_this_create">
            {{ title }}
        </div>

        <div class="title_page" v-else>
            <span @click="returnToRout(parent_rout_name ? parent_rout_name : 'AwaitingQuests')" class="btn_emit">
                {{ parent_rout_name ? ($options.PARENT_ROUTES[parent_rout_name] ? $options.PARENT_ROUTES[parent_rout_name] : 'Quests') : 'Quests' }}
            </span>
            <span>
                / <span @click="returnToRout(parent_rout_name ? parent_rout_name : select_tabs)" class="btn_emit">
                    {{ name }}
                </span>
            </span> / Edit quest
        </div>

        <input
            type="file"
            :id="`upload-csv-${key}`"
            accept="text/csv"
            name="file"
            ref="file"
            @change="onFileChange"
            :key="key"
            class="hidden">

        <div v-if="quest_id" class="active_button">
            <button class="white_green_btn" @click="questCopy">
                Copy quest
            </button>
        </div>
        <div v-if="key_view" class="active_button">
            <button class="white_green_btn" @click="routeGo('CreateQuest')">
                Create quest
            </button>

            <button class="white_green_btn" @click.stop="uploadCSV">
                Import quest
            </button>

            <button class="white_green_btn" @click.stop="downloadCSV">
                Download CSV example
            </button>
        </div>
    </div>
</template>
<script>
import {baseErrHandler, copyQuest, downloadCSV, uploadCSV} from "@/services/api";

export default {
    name: 'FirstRowInQuests',
    PARENT_ROUTES: {
        ActiveQuests: 'Active Quests',
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        key_view: {
            type: Boolean,
            default: true,
        },
        is_create: {
            type: [Boolean, Number, String],
            default: true,
        },
        name: {
            type: String,
            default: '',
        },
        quest_id: {
            type: Number,
            default: null,
        },
        trailblazer_id: {
            type: [Number, String],
            default: '',
        },
        select_tabs: {
            type: String,
            default: '',
        },
        parent_rout_name: {
            type: String,
            default: '',
        },
    },
    emits: {
        quest_copied: null,
    },
    data() {
        return {
            key: null,
            file: null,
        };
    },
    async beforeMount() {
        this.key = await this.generateRandomString();
    },
    methods: {
        async returnToRout(rout_name) {
            if (this.quest_id) {
                await this.$router.push({
                    name: rout_name,
                    query: {
                        quest_name: this.name,
                        quest_id: this.quest_id,
                        trailblazer_id: this.trailblazer_id,
                        is_create: this.is_create,
                        parent_rout_name: this.parent_rout_name,
                    }
                });
            }
        },
        async generateRandomString() {
            return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 10);
        },
        async routeGo(route) {
            await this.$router.push({name: route});
        },
        async downloadCSV() {
            const host = window.location.origin;
            await downloadCSV({
                url: `${host}/app/CSV/test5.csv`,
                label: 'preview.csv'
            });
        },
        async uploadCSV() {
            document.getElementById(`upload-csv-${this.key}`).click();
        },
        onFileChange() {
            this.file = this.$refs.file.files[0];
        },
        async submitFileToServer() {
            await baseErrHandler(async () => {
                const formData = new FormData();
                formData.append('file', this.file);

                const { success } = await uploadCSV(formData);

                if (success) {
                    this.$router.go(this.$router.currentRoute);
                }
            });
        },
        async questCopy() {
            await baseErrHandler(async () => {
                const {
                    quest,
                    trailblazer,
                } = await copyQuest(this.quest_id);

                if (quest) {
                    await this.$router.push({
                        name: 'CopiedQuest',
                        query: {
                            quest_name: quest.name,
                            quest_id: quest.id,
                            trailblazer_id: trailblazer?.id,
                            is_create: 0,
                        }
                    });
                    await this.$nextTick(() => {
                        this.$emit('quest_copied');
                    });
                }
            })
        }
    },
    watch: {
        file(val) {
            if (val) {
                this.submitFileToServer();
            }
        }
    },
    computed: {
        is_this_create() {
            if (this.is_create === 'true' || Number(this.is_create) > 0) {
                return true;
            }
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
.first_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.active_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.title_page {
    span {
        color: #61CC2C;
    }
}
</style>
