<template>
    <div class="upload_image_body">
        <input
            type="file"
            ref="inputUploadFile"
            :id="`upload-image-${key}`"
            accept="image/*"
            name="image"
            @change="onFileChange"
            :key="key"
            class="hidden">

        <div class="w-full" :class="{image_body: !is_little, is_little_body: is_little}">
            <div  v-if="image_view" :class="default_image ? 'image_div' : 'full_image'">
                <img :src="image_view" :id="`src-image-${key}`" alt="upload-image">
            </div>

            <div v-if="default_image && !image_view" class="default_image_div">
                <img :src="getImgUrl(default_image)" alt="default-image">
            </div>
        </div>

        <div class="in_center gp-20">
            <button v-if="!image_view" class="white_green_btn" @click="openUpload">
                Upload <span v-if="default_image">custom</span> image
            </button>

            <button v-if="image_view" class="white_green_btn" @click="openUpload">
                Change image
            </button>

            <button v-if="image_view && !no_deleted" class="white_green_btn" @click="removeImage(false)">
                Delete image
            </button>
        </div>

        <div class="cropper_parent" v-if="image?.src">
            <cropper-template
                :is_square="is_square"
                :id="`cropper_body_${key}`"
                :image="image"
                ref="cropperTemplate"
                :key="`cropper_template__${key}`" @image_cropped="imageCropped"/>

            <button class="white_green_btn" @click="loadImage">
                Load
            </button>
        </div>
    </div>

    <MessageModal
        ref="errorMessageModal"
        :is_error="true"
        :message="`Wrong image format. Please upload image in JPEG, PNG etc.`"/>
</template>
<script>
import CropperTemplate from "@/components/cropper/CropperTemplate.vue";
import MessageModal from "@/components/modals/MessageModal.vue";

export default {
    name: 'UploadImageCropper',
    components: {
        CropperTemplate,
        MessageModal
    },
    CORRECT_MIME: ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'],
    props: {
        default_image: {
            type: String,
            default: '',
        },
        pre_image: {
            type: String,
            default: '',
        },
        is_little: {
            type: Boolean,
            default: false,
        },
        no_deleted: {
            type: Boolean,
            default: false,
        },
        is_square: {
            type: Boolean,
            default: true,
        }
    },
    emits: {
        selected_image: null,
        delete_image_in_base: null,
    },
    data() {
        return {
            img: null,
            image: null,
            key: null,
        };
    },
    computed: {
        image_view() {
            if (this.pre_image) {
                return this.pre_image;
            }
            if (this.img) {
                this.$emit('selected_image', this.img);
                return this.img;
            }
            return null;
        }
    },
    watch: {
        ['image.src'](val) {
            if (val) {
                this.$nextTick(() => {
                    this.scrollCenter( `cropper_body_${this.key}`);
                });
            }
        }
    },
    methods: {
        getImgUrl(path) {
            return require(`@/assets/${path}`);
        },
        async generateRandomString() {
            return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 10);
        },
        async onFileChange(event) {
            const { files } = event.target;

            if (!this.$options.CORRECT_MIME.includes(files[0].type)) {
                this.$refs.errorMessageModal.openModal();
                return;
            }

            await this.removeImage(true);
            await this.createImages(files);
        },
        async createImages(files) {
            if (files && files[0]) {
                if (this.image?.src) {
                    URL.revokeObjectURL(this.image.src);
                }

                const blob = URL.createObjectURL(files[0]);
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.image = {
                        src: blob,
                        type: this.getMimeType(e.target.result, files[0].type),
                        name: files[0].name,
                    };
                };
                reader.readAsArrayBuffer(files[0]);
            }
        },
        getMimeType(file, fallback = null) {
            const byteArray = new Uint8Array(file).subarray(0, 4);
            let header = '';
            for (let i = 0; i < byteArray.length; i++) {
                header += byteArray[i].toString(16);
            }
            switch (header) {
                case '89504e47':
                    return 'image/png';
                case '47494638':
                    return 'image/gif';
                case 'ffd8ffe0':
                case 'ffd8ffe1':
                case 'ffd8ffe2':
                case 'ffd8ffe3':
                case 'ffd8ffe8':
                    return 'image/jpeg';
                default:
                    return fallback;
            }
        },
        openUpload() {
            this.$refs.inputUploadFile.click();
        },
        async removeImage(no_delete = false) {
            if (!no_delete) {
                await this.deleteImageInBase();
            }

            this.image = null;
            this.img = null;
            this.key = await this.generateRandomString();
            this.$emit('selected_image', null);
        },
        loadImage() {
            this.$refs.cropperTemplate.crop();
            this.$nextTick(() => {
                const body = document.querySelector("body");
                body.style.overflow = "auto";
            });
        },
        imageCropped(res) {
            this.img = res;

            this.image = null;
        },
        async deleteImageInBase() {
            if (this.pre_image) {
                this.$emit('delete_image_in_base', this.pre_image);
            }
        },
        scrollCenter(id) {
            const element = window.document.getElementById(id);
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            const middle = absoluteElementTop - (window.innerHeight / 2  - element.offsetHeight / 2);
            window.scrollTo(0, middle);

            const body = document.querySelector("body");
            body.style.overflow = "hidden";
        },
    },
    async beforeMount() {
        this.key = await this.generateRandomString();
    },
}
</script>
<style lang="scss" scoped>
.upload_image_body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.in_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.full_image {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
    }
}

.gp-20 {
    gap: 20px;
}

.image_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.is_little_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 300px;
    }
}

.image_div {
    width: 300px;
    height: 300px;

    img {
        width: 100%;
        height: 100%;
    }
}

.default_image_div {
    width: 150px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
    }
}

.cropper_parent {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    background: #0F2542;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 25px
}
</style>
