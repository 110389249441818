<template>
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 -3.2232e-07L10 5L-3.2929e-07 5L5 -3.2232e-07Z" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'TriangleUp',
}
</script>
