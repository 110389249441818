import moment from 'moment-timezone';

export const utsTimeISO = (time) => {
    return moment(time).tz('UTC').toISOString();
};

export const tableGetDate = (val, offset) => {
    return getDateFormat(val, offset) + ' GMT ' + offset;
};

export const getDateFormat = (val, offset) => {
    return moment(val).utcOffset(offset).format(dateTableFormat);
};

export const getPeriodFormat = (val, offset) => {
    return moment(val).utcOffset(offset).format(periodFormat);
};

export const differentNowTime = (val, offset) => {
    const current = moment().utcOffset(offset);
    const test = moment(val).utcOffset(offset);
    let str;
    if (current > test) {
        str = ' ago';
    } else {
        str = ' left';
    }
    return current.diff(test,"days") + ' days' + str;
};

export const dateTableFormat = 'MM-DD-YYYY H:mm A';
export const periodFormat = 'DD-MM-YYYY';
