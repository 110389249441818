export const typesArray = {
    receipt_challenge_answers: 'receipt',
    quest_blog_challenge_answers: 'quest post',
    marker_blog_challenge_answers: 'blog',
    photo_challenge_answers: 'photo',
    video_challenge_answers: 'video',
};

export const typeVideo = ['video_challenge_answers'];
export const typePhoto = ['photo_challenge_answers', 'receipt_challenge_answers'];
export const typeReceipt = ['receipt_challenge_answers'];
export const typeBlog = ['quest_blog_challenge_answers', 'marker_blog_challenge_answers'];
export const typeQuestBlog = ['quest_blog_challenge_answers'];

export const typeArchive = 'archive';

export const typByCategory = (category_title) => {
    if (category_title === 'Photo challenge') {
        return 'photo_challenge_answers';
    }
    if (category_title === 'Receipt challenge') {
        return 'receipt_challenge_answers';
    }
    if (category_title === 'Video challenge') {
        return 'video_challenge_answers';
    }
    if (category_title === 'Blog challenge') {
        return 'marker_blog_challenge_answers';
    }
    if (category_title === '') {
        return 'quest_blog_challenge_answers';
    }

    return '';
};
