<template>
    <div class="modal-shadow saved_popup min-h-screen flex flex-col">
        <div class="inner flex-col flex">
            <div class="content flex-col flex">
                <header-mobile/>

                <div class="body_content">
                    <router-view :key="$route.path"/>
                </div>

                <footer-mobile/>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderMobile from "@/components/mobile/HeaderMobile";
import FooterMobile from "@/components/mobile/FooterMobile";
export default {
    name: 'MobileEmit',
    components: {FooterMobile, HeaderMobile},
    comments: {
        HeaderMobile,
        FooterMobile
    },
}
</script>
<style lang="scss" scoped>
.modal-shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.39);
}
.saved_popup {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
}
.inner {
    width: 330px;
    min-height: 674px;
    margin: auto;
    background: #FAFBFA;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);

    @media screen and (max-width: 330px) {
        margin: auto 0;
    }
}

.inner .content {
    height: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
}

.body_content {
    width: 100%;
}
</style>
