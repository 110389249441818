<template>
    <div class="modal-shadow saved_popup min-h-screen flex flex-col flex flex-col">
        <div class="inner flex-col flex p-6">
            <div class="login_header">
                <div class="logo_div">
                    <img src="../assets/img/logo.png" alt="logo">
                </div>
            </div>
            <div class="content">
                <div class="tile">
                    Login
                </div>

                <div class="relative w-full z-0">
                    <input
                        @click="skipError"
                        type="email"
                        name="address-email"
                        placeholder=" "
                        class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                        :class="{border_error: email_error}"
                        v-model="email"
                    />
                    <label for="email" class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title">Email</label>
                    <span class="text-sm text-red-600" v-if="email_error" id="error_email">{{ email_error }}</span>
                </div>

                <div class="relative w-full z-0">
                    <input
                        @click="skipError"
                        type="password"
                        name="form-password"
                        autocomplete="new-password"
                        placeholder=" "
                        class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                        :class="{border_error: password_error}"
                        v-model="password"
                    />
                    <label for="form-password" class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title">Password</label>
                    <span class="text-sm text-red-600" v-if="password_error" id="error_password">{{ password_error }}</span>
                </div>

                <div class="w-full button_row">
                    <div class="sub_title btn_emit" @click="routeGo('ForgotPassword')">
                        Forget password?
                    </div>

                    <button
                        class="green_btn button-text"
                        :disabled="button_disable"
                        @click="dataSubmit">
                        Log in
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {isValidEmail} from "@/services/validators";
import {logInAdmin} from "@/services/api";
import {mapMutations} from "vuex";

export default {
    name: 'AdminLogIn',
    data() {
        return {
            password: null,
            email: null,
            password_error: null,
            email_error: null,
        };
    },
    computed: {
        button_disable() {
            return !this.password || this.password.length < 6 || !this.email || this.email_error || this.password_error;

        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'login',
        ]),
        async dataSubmit() {
            this.skipError();
            if (this.validationData()) {
                return;
            }

            await this.logIn();
        },
        validationData() {
            if (!isValidEmail(this.email)) {
                this.email_error = 'Wrong email or password. Please, double-check and try again.';
                return true;
            }

            return false;
        },
        skipError() {
            this.email_error = null;
            this.password_error = null;
        },
        clearForm() {
            this.email = null;
            this.password = null;
        },
        async logIn() {
            this.showLoader();

            const error_no_admin ='Wrong email or password. Please, double-check and try again.'

            try {
                const { token, user } = await logInAdmin({
                    email: this.email,
                    password: this.password,
                });

                if (!token || !user || !user?.role) {
                    this.email_error = error_no_admin;
                    this.password_error = error_no_admin;

                    return;
                }

                this.clearForm();

                this.login({
                    user,
                    token,
                });

                await this.$router.push({name: 'ActiveQuests'});

            } catch (e) {
                console.log(e);

                this.email_error = error_no_admin;
                this.password_error = error_no_admin;
            }

            this.hideLoader();
        },
        async routeGo(route) {
            await this.$router.push({name: route});
        }
    },
}
</script>
<style lang="scss" scoped>
.modal-shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: #0C1F38;
}
.saved_popup {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px;
    gap: 20px;
    width: 675px;
    background: #FFFFFF;
    border-radius: 24px 24px 20px 20px;
}

.inner .login_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    gap: 10px;
    border-radius: 20px 20px 0 0;

    width: 675px;
    height: 170px;

    background: #0F2542;
}

.inner .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    gap: 20px;

    width: 675px;
    height: 264px;
}

.inner .btn {
    width: 100px;
    height: 40px;
    margin: 0 20px;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.75;
}
.inner .btn:hover {
    opacity: 1;
}

.logo_div {
    width: 250px;
    height: 110px;
    img {
        width: 100%;
        height: 100%;
    }
}

.tile {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #214B41;
    text-align: left;
}

.button_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sub_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #61CC2C;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.label-title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
}

.button-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.border_error {
    border-color: #F44A3E;
}
</style>
