import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http_client from "./services/http_client.js";
import './assets/tailwind.css';
import {SetupCalendar} from 'v-calendar';

const app = createApp(App);

app.use(store).use(router).use(SetupCalendar, {});
app.config.globalProperties.$http = http_client;

app.mount("#app");
