<template>
    <div class="w-full item_in_card">
        <div class="item_title">
            QR-code
        </div>

        <div class="row_generate">
            <div class="relative w-full z-0">
                <input
                    :disabled="is_view"
                    type="text"
                    name="qr_generate"
                    placeholder=" "
                    class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                    :class="{is_disabled: is_view}"
                    :value="modelValue"
                    @input="$emit('update:modelValue', $event.target.value)"
                />
                <label
                    for="qr_generate"
                    class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title"
                    :class="{_opacity_null: show}">
                    Add Qr-code text
                </label>
            </div>

            <button
                v-if="!is_view"
                :disabled="!modelValue?.length"
                class="white_green_btn"
                @click="generateQR">
                Generate QR
            </button>
        </div>

        <div class="qr_code_view" v-if="is_view">
            <QRCodeTemplate
                :quest_name="quest_name"
                :marker_name="marker_name"
                :challenge_name="challenge_name"
                :value="modelValue"/>

            <div class="in_center gp-20" v-if="view_button">
                <button
                    @click="exportToPDF"
                    class="white_green_btn">
                    Download PDF
                </button>

                <button
                    @click="clearCode"
                    class="white_green_btn">
                    Clear code
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import QRCodeTemplate from "@/components/admin/QR/QRCodeTemplate.vue";
import html2pdf from "html2pdf.js";
import {mapMutations} from "vuex";
import {errorMessage} from "@/services/messages";

export default {
    name: 'QRCodeGenerate',
    components: {
        QRCodeTemplate
    },
    props: {
        modelValue: '',
        view_qr: {
            type: Boolean,
            default: false,
        },
        quest_name: {
            type: String,
            default: '',
        },
        marker_name: {
            type: String,
            default: '',
        },
        challenge_name: {
            type: String,
            default: '',
        },
        view_button: {
            type: Boolean,
            default: true,
        }
    },
    emits: {
        'update:modelValue': null,
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        generateQR() {
            this.show = true;
        },
        clearCode() {
            this.show = false;
            this.$emit('update:modelValue', '');
        },
        async generatePDF(content) {
            await html2pdf(content, {
                margin: 1,
                filename: `${this.makeName()}.pdf`,
            });
        },
        async exportToPDF() {
            this.showLoader();
            try {
                const image = window.document.getElementsByClassName('qr_template_body')[0];

                await this.generatePDF(image);
            } catch (e) {
                console.error(e);
                errorMessage();
            }
            this.hideLoader();
        },
        makeName(length = 16) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        },
    },
    computed: {
        is_view() {
            if (this.view_qr && this.modelValue?.length) {
                return true;
            }
            if (this.show) {
                return true;
            }
            return false;
        }
    },
}
</script>
<style lang="scss" scoped>
.item_in_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.item_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}

.row_generate {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.white_green_btn {
    min-width: 192px;
}

.qr_code_view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.gp-20 {
    gap: 20px;
}

.in_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.is_disabled {
    color: #9CA8B6;
    border-color: #9CA8B6;
}

._opacity_null {
    opacity: 0;
}
</style>
