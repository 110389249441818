<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.02746 4C6.27619 1.75003 8.18372 0 10.5 0C12.8163 0 14.7238 1.75003 14.9725 4H16.5C18.433 4 20 5.567 20 7.5V9.02746C22.25 9.27619 24 11.1837 24 13.5C24 15.8163 22.25 17.7238 20 17.9725V20.5C20 22.433 18.433 24 16.5 24H13.5C12.6716 24 12 23.3284 12 22.5V21.5C12 20.6716 11.3284 20 10.5 20C9.67157 20 9 20.6716 9 21.5V22.5C9 23.3284 8.32843 24 7.5 24H3.5C1.567 24 0 22.433 0 20.5V16.5C0 15.6716 0.671573 15 1.5 15H2.5C3.32843 15 4 14.3284 4 13.5C4 12.6716 3.32843 12 2.5 12H1.5C0.671573 12 0 11.3284 0 10.5V7.5C0 5.567 1.567 4 3.5 4H6.02746ZM10.5 3C9.67157 3 9 3.67157 9 4.5V5.5C9 6.32843 8.32843 7 7.5 7H3.5C3.22386 7 3 7.22386 3 7.5V9.02746C5.24998 9.27619 7 11.1837 7 13.5C7 15.8163 5.24998 17.7238 3 17.9725V20.5C3 20.7761 3.22386 21 3.5 21H6.02746C6.27619 18.75 8.18372 17 10.5 17C12.8163 17 14.7238 18.75 14.9725 21H16.5C16.7761 21 17 20.7761 17 20.5V16.5C17 15.6716 17.6716 15 18.5 15H19.5C20.3284 15 21 14.3284 21 13.5C21 12.6716 20.3284 12 19.5 12H18.5C17.6716 12 17 11.3284 17 10.5V7.5C17 7.22386 16.7761 7 16.5 7H13.5C12.6716 7 12 6.32843 12 5.5V4.5C12 3.67157 11.3284 3 10.5 3Z" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'ChallengesIcon'
}
</script>
