<template>
    <div id="__map" ref="googleMapTest"></div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";
import {baseErrHandler, getKey} from "@/services/api";

export default {
    name: 'GoogleMap',
    props: {
        center: {
            type: Object,
            default() {
                return {
                    lat: 0,
                    lng: 0,
                };
            }
        },
        modelValue: {
            type: Object,
            default() {
                return undefined;
            }
        }
    },
    emits: {
        'update:modelValue': null,
        searchResult: null,
    },
    data() {
        return {
            key: '',
            map: null,
            marker: null,
        };
    },
    methods: {
        async placeMarker(location) {
            await this.loader.load()
                .then(async (google) => {
                        if (this.marker === null) {
                            this.marker = await new google.maps.Marker({
                                position: location,
                                map: this.map
                            });
                        } else {
                            this.marker.setPosition(location);
                        }
                    })
                .catch(e => {
                    console.log({e});
                });
        },
        async initMap() {
            await this.loader.load()
                .then(async (google) => {
                    this.map = new google.maps.Map(document.getElementById("__map"), this.mapOptions);
                    google.maps.event.addListener(this.map, 'click', async (mapsMouseEvent) => {
                        let lat = await mapsMouseEvent.latLng.lat();
                        let lng = await mapsMouseEvent.latLng.lng();

                        this.$emit('update:modelValue', {lat, lng});
                    });
                })
                .catch(e => {
                    console.log({e});
                });
        },
        async getMapKey() {
            await baseErrHandler(async () => {
                const { constant } = await getKey({key: 'GOOGLE_MAP_API_KEY'});

                if (constant) {
                    this.key = constant;
                }
            });
        }
    },
    async mounted() {
        await this.getMapKey();
        await this.initMap();

        if (this.modelValue) {
            await this.$nextTick(async () => {
                await this.placeMarker(this.modelValue);
            });
        }
    },
    watch: {
        modelValue (val, old) {
            if (val !== old) {
                this.$nextTick(() => {
                    this.placeMarker(val);
                });
            }
        },
        center (val, old) {
            if (val !== old) {
                this.map.setCenter(val);
                this.$nextTick(() => {
                    this.placeMarker(val);
                });
            }
        }
    },
    computed: {
        loader() {
            return new Loader({
                apiKey: this.key,
                version: "weekly",
                libraries: []
            });
        },
        mapOptions() {
            return {
                center: this.center,
                zoom: 12
            };
        }
    },
}
</script>
<style>
#__map {
    width: 100%;
    height: 500px;
}
</style>
