<template>
    <draggable
        class="dragArea w-full"
        :list="modelValue"
        @change="setNewOrder">

        <markers-card
            v-for="element in modelValue"
            :default_marker="default_marker"
            :marker="element"
            @edit_element="editElement"
            @delete_element="deleteElement"
            @disable_element="disabledElement"
            :key="element.id"/>
    </draggable>
</template>
<script>
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import MarkersCard from "@/components/UI/markers/MarkersCard.vue";
export default defineComponent({
    components: {
        MarkersCard,
        draggable: VueDraggableNext,
    },
    props: {
        modelValue: null,
        default_marker: {
            type: String,
            default: '',
        },
    },
    emits: {
        'update:modelValue': null,
        edit_element: null,
        delete_element: null,
        disable_element: null,
    },
    data() {
        return {
            enabled: true,
            dragging: false,
        }
    },
    methods: {
        setNewOrder() {
            let count = 1;
            for (let [index, val] of this.modelValue.entries()) {
                if (!val.bonus) {
                    val.order = count;
                    count++;
                } else {
                    val.order = null;
                }
            }
        },
        editElement(val) {
            this.$emit('edit_element', val);
        },
        deleteElement(val) {
            this.$emit('delete_element', val);
        },
        disabledElement(val) {
            this.$emit('disable_element', val);
        }
    },
})
</script>
<style lang="scss" scoped>
.dragArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>
