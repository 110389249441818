<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4992 17.761C10.4992 18.5894 11.1708 19.261 11.9992 19.261C12.8276 19.261 13.4992 18.5894 13.4992 17.761V15.8223C14.4924 15.6717 15.438 15.3739 16.3074 14.9515L17.842 16.486C18.4278 17.0718 19.3775 17.0718 19.9633 16.486C20.5491 15.9002 20.5491 14.9505 19.9633 14.3647L18.798 13.1995C19.9966 12.0218 20.859 10.5267 21.2257 8.84081C21.4017 8.0313 20.8882 7.23235 20.0787 7.05629C19.2692 6.88023 18.4703 7.39374 18.2942 8.20324C17.7219 10.8348 15.1801 12.935 11.9992 12.935C8.8183 12.935 6.27656 10.8348 5.70422 8.20324C5.52816 7.39374 4.7292 6.88023 3.9197 7.05629C3.1102 7.23235 2.59669 8.0313 2.77275 8.84081C3.13942 10.5267 4.00184 12.0218 5.20038 13.1995L4.03514 14.3647C3.44935 14.9505 3.44935 15.9002 4.03514 16.486C4.62092 17.0718 5.57067 17.0718 6.15646 16.486L7.69097 14.9515C8.56047 15.3739 9.50604 15.6717 10.4992 15.8223V17.761Z" fill="#61CC2C"/>
    </svg>
</template>
<script>
export default {
    name: 'EyeCloseGreen'
}
</script>
