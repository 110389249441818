<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-reports :title="'Reports'"/>

            <table-template
                v-if="table_view"
                v-model:search="requestData.search"
                v-model:perPage="requestData.perPage"
                v-model:page="requestData.page"
                v-model:sort="requestData.sort"
                :clicked_row="true"
                @clicked_row="openIssue"
                :columns="columns"
                :data="reports"
                :total_count="total_count"/>

            <empty-reports-data
                v-if="!reports.length"
                :nothing_found="table_view"
                :message="!table_view ? 'No pending reports yet' : 'Nothing found, please try again'"/>
        </div>

        <issue-report-modal
            @close_modal="closeIssueModal"
            v-model:issue="issue"
            ref="issueModal"/>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import FirstRowReports from "@/components/admin/FirstRowReports.vue";
import EmptyReportsData from "@/components/admin/EmptyReportsData.vue";
import {baseErrHandler, getReportsIssue} from "@/services/api";
import {getDateFormat} from "@/helpers/time.helper";
import TableTemplate from "@/components/TableTemplate.vue";
import IssueReportModal from "@/components/modals/admin/IssueReportModal.vue";
export default {
    name: 'ReportsAdmin',
    props: {
        active_item: {
            type: String,
            default: '',
        }
    },
    components: {
        IssueReportModal,
        TableTemplate,
        AdminLayouts,
        FirstRowReports,
        EmptyReportsData,
    },
    data() {
        return {
            reports: [],
            issue: {},
            requestData: {
                sort: {
                    type: 'desc',
                    field: 'created_at',
                },
                page: 1,
                perPage: 10,
                search: '',
            },
            total_count: 0,
            columns: [
                {
                    title: 'Quest',
                    field: 'quest_name',
                    width: 'w-2/12',
                    sort: true,
                },
                {
                    title: 'Marker',
                    field: 'marker_name',
                    width: 'w-2/12',
                    sort: true,
                },
                {
                    title: 'Reason',
                    width: 'w-3/12',
                    field: 'reason',
                    sort: true,
                },
                {
                    title: 'Date',
                    width: 'w-3/12',
                    field: 'report_time',
                    sort: true,
                    function: (val) => {
                        if (val.report_time) {
                            return this.returnViewDate(val.report_time);
                        }
                        return '';
                    }
                },
                {
                    title: 'Author',
                    width: 'w-2/12',
                    field: 'author_full_name',
                    sort: true,
                },
            ],
        }
    },
    methods: {
        async getReports() {
            await baseErrHandler(async () => {
                const { list, total_count } = await getReportsIssue(this.requestData);
                this.reports = list;
                this.total_count = total_count;
            });
        },
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
        openIssue(val) {
            this.issue = val;
            this.$nextTick(() => {
                this.$refs.issueModal.openModal();
            });
        },
        async closeIssueModal() {
            this.$refs.issueModal.closeModal();
            await this.getReports();
            this.issue = {};
        },
    },
    computed: {
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
        table_view() {
            if (!this.reports.length && !this.requestData.search) {
                return false;
            }
            return true;
        }
    },
    watch: {
        ['requestData.page']() {
            this.getReports();
        },
        ['requestData.perPage']() {
            this.requestData.page = 1;
            this.getReports();
        },
        ['requestData.sort']() {
            this.requestData.page = 1;
            this.getReports();
        },
        ['requestData.search']() {
            this.requestData.page = 1;
            this.getReports();
        }
    },
    async beforeMount() {
        await this.getReports();
    }
}
</script>
<style lang="scss" scoped>

</style>
