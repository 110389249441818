<template>
    <create-quest-layouts
        :is_create="is_create"
        :quest_id="quest_id"
        :active_item="active_item"
        :trailblazer_id="trailblazer_id"
        :active_tab="active_tab">

        <div class="w-full centered_body">
            <div class="card_body">
                <div class="w-full card_title" v-if="trailblazer_id">
                    General badges
                </div>

                <div class="w-full item_in_card">
                    <div class="w-full checked_body">
                        <input
                            type="checkbox"
                            name="is_quest_blog"
                            v-model="completing_all_markers">

                        <div class="label_title">
                            Badge for completing all markers
                        </div>
                    </div>

                    <upload-image
                        v-if="completing_all_markers"
                        :pre_image="completing_all_markers_badge ? completing_all_markers_badge : ''"
                        @selected_image="selectedMarkerCompletingAllMarkers"
                        @delete_image_in_base="deleteQuestBadgeInBase('completing_all_markers')"
                        :default_image="'Badge/GreenCheckBadge.png'"/>

                    <div class="w-full checked_body">
                        <input
                            type="checkbox"
                            name="is_quest_blog"
                            v-model="completing_all_challenges">

                        <div class="label_title">
                            Badge for completing all markers and completing all challenges
                        </div>
                    </div>

                    <upload-image
                        v-if="completing_all_challenges"
                        :pre_image="completing_all_challenges_badge ? completing_all_challenges_badge : ''"
                        @selected_image="selectedMarkerCompletingAllChallenges"
                        @delete_image_in_base="deleteQuestBadgeInBase('completing_all_challenges')"
                        :default_image="'Badge/ChallengeBadge.png'"/>

                    <div class="w-full checked_body">
                        <input
                            type="checkbox"
                            name="is_quest_blog"
                            v-model="quest_participation">

                        <div class="label_title">
                            Badge for quest participation
                        </div>
                    </div>

                    <upload-image
                        v-if="quest_participation"
                        :pre_image="quest_participation_badge ? quest_participation_badge : ''"
                        @selected_image="selectedMarkerQuestParticipation"
                        @delete_image_in_base="deleteQuestBadgeInBase('quest_participation')"
                        :default_image="'Badge/ParticipationBadge.png'"/>
                </div>
            </div>

            <div class="card_body" v-if="trailblazer_id">
                <div class="w-full card_title">
                    Trailblazer badges:
                </div>

                <div class="item_title">
                    1st place badge
                </div>

                <div class="w-full item_in_card">
                    <upload-image
                        :pre_image="first_place ? first_place : ''"
                        @selected_image="selectedMarkerTrailblazerFirstPlace"
                        @delete_image_in_base="deleteTrailblazerBadge(1)"
                        :default_image="'Badge/FirstPlace.png'"/>
                </div>

                <div class="item_title">
                    2nd place badge
                </div>

                <div class="w-full item_in_card">
                    <upload-image
                        :pre_image="second_place ? second_place : ''"
                        @selected_image="selectedMarkerTrailblazerSecondPlace"
                        @delete_image_in_base="deleteTrailblazerBadge(2)"
                        :default_image="'Badge/SecondPlace.png'"/>
                </div>

                <div class="item_title">
                    3rd place badge
                </div>

                <div class="w-full item_in_card">
                    <upload-image
                        :pre_image="three_place ? three_place : ''"
                        @selected_image="selectedMarkerTrailblazerThreePlace"
                        @delete_image_in_base="deleteTrailblazerBadge(3)"
                        :default_image="'Badge/ThreePlace.png'"/>
                </div>
            </div>

            <div class="card_body">
                <div class="in_center gp-20">
                    <button
                        class="white_green_btn"
                        @click="$router.push(`/admin/create-markers-list?quest_id=${quest_id}`)">
                        Back
                    </button>

                    <button
                        class="white_green_btn"
                        :disabled="is_disabled_save"
                        @click="createBadges">
                        Save quest
                    </button>
                </div>
            </div>
        </div>
    </create-quest-layouts>
</template>
<script>
import CreateQuestLayouts from "@/components/layouts/create_quests/CreateQuestLayouts.vue";
import UploadImage from "@/components/UI/images/UploadImage.vue";
import {
    baseErrHandler,
    deleteQuestBadge,
    deleteTrailblazerBadge,
    getBadgesByQuestId,
    saveQuestAndTrailblazerBadges
} from "@/services/api";

export default {
    name: 'AddBadges.vue',
    components: {
        CreateQuestLayouts,
        UploadImage,
    },
    props: {
        active_item: {
            type: String,
            default: '',
        },
        active_tab: {
            type: String,
            default: 'Badges',
        },
        quest_id: {
            type: [Number, String],
            default: '',
        },
        trailblazer_id: {
            type: [Number, String],
            default: '',
        },
        is_create: {
            type: [Boolean, Number, String],
            default: true,
        },
    },
    data() {
        return {
            quest_badges_id: null,
            completing_all_markers: false,
            completing_all_markers_badge: null,
            completing_all_challenges: false,
            completing_all_challenges_badge: null,
            quest_participation: false,
            quest_participation_badge: null,

            trailblazer_badge_id: null,
            first_place: null,
            second_place: null,
            three_place: null,
        };
    },
    async beforeMount() {
        if (this.quest_id) {
            await this.getBadges();
        }
    },
    methods: {
        selectedMarkerCompletingAllMarkers(image) {
            this.completing_all_markers_badge = image;
        },
        selectedMarkerCompletingAllChallenges(image) {
            this.completing_all_challenges_badge = image;
        },
        selectedMarkerQuestParticipation(image) {
            this.quest_participation_badge = image;
        },
        selectedMarkerTrailblazerFirstPlace(image) {
            this.first_place = image;
        },
        selectedMarkerTrailblazerSecondPlace(image) {
            this.second_place = image;
        },
        selectedMarkerTrailblazerThreePlace(image) {
            this.three_place = image;
        },
        async deleteQuestBadgeInBase(item) {
            await baseErrHandler(async () => {
                await deleteQuestBadge(this.quest_id, item);
            });
        },
        async deleteTrailblazerBadge(num) {
            await baseErrHandler(async () => {
                if (this.trailblazer_badge_id) {
                    await deleteTrailblazerBadge(this.trailblazer_badge_id, num);
                }
            });
        },
        async initQuestBadgesData(quest_badges) {
            if (quest_badges) {
                this.quest_badges_id = quest_badges.id ? quest_badges.id : null;
                this.completing_all_markers = quest_badges.completing_all_markers;
                this.completing_all_challenges = quest_badges.completing_all_challenges;
                this.quest_participation = quest_badges.quest_participation;
                this.completing_all_markers_badge = quest_badges.completing_all_markers_badge ? quest_badges.completing_all_markers_badge : '';
                this.quest_participation_badge = quest_badges.quest_participation_badge ? quest_badges.quest_participation_badge : '';
                this.quest_participation_badge = quest_badges.completing_all_challenges_badge ? quest_badges.completing_all_challenges_badge : '';
            }
        },
        async initTrailblazerBadge(trailblazer_badge) {
            if (trailblazer_badge) {
                // this.trailblazer_id = trailblazer_badge?.trailblazer_id ? trailblazer_badge.trailblazer_id : null;
                this.trailblazer_badge_id = trailblazer_badge.id ? trailblazer_badge.id : null;
                this.first_place = trailblazer_badge.badge_place_1;
                this.second_place = trailblazer_badge.badge_place_2;
                this.three_place = trailblazer_badge.badge_place_3;
            }
        },
        async createBadges() {
            await baseErrHandler(async () => {
                const badges_data = {
                    quest_id: this.quest_id,
                    completing_all_markers: this.completing_all_markers,
                    quest_participation: this.quest_participation,
                    completing_all_challenges: this.completing_all_challenges,
                    completing_all_markers_badge: this.completing_all_markers ? this.completing_all_markers_badge : null,
                    quest_participation_badge: this.quest_participation ? this.quest_participation_badge : null,
                    completing_all_challenges_badge: this.completing_all_challenges ? this.quest_participation_badge : null,

                    trailblazer_id: this.trailblazer_id,
                    badge_place_1: this.first_place,
                    badge_place_2: this.second_place,
                    badge_place_3: this.three_place,
                };

                const { success, quest_badges, trailblazer_badges } = await saveQuestAndTrailblazerBadges(badges_data);

                if (quest_badges) {
                    await this.initQuestBadgesData(quest_badges);
                }
                if (trailblazer_badges) {
                    await this.initTrailblazerBadge(trailblazer_badges);
                }

                if ( success ) {
                    await this.$router.push({
                        path: '/admin/active-quests'
                    });
                }
            });
        },
        async getBadges() {
            await baseErrHandler(async () => {
                const { quest_badges, trailblazer_badges } = await getBadgesByQuestId(this.quest_id);

                if (quest_badges) {
                    await this.initQuestBadgesData(quest_badges);
                }

                if (trailblazer_badges) {
                    await this.initTrailblazerBadge(trailblazer_badges);
                }
            });
        },
        addParamToUrl(key, param) {
            const url = new URL(window.location.href);
            url.searchParams.set(key, param);
            window.history.replaceState(null, null, url);
        },
    },
    computed: {
        is_trailblazer() {
            return Boolean(this.trailblazer_id);
        },
        is_disabled_save() {
            return false;
        }
    },
    watch: {
        trailblazer_id(val) {
            if (val) {
                this.addParamToUrl('trailblazer_id', val);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.centered_body {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.in_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card_body {
    width: 675px;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.card_title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: #214B41;
}

.item_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}

.item_in_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sub_item_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.sub_item {
    width: 327px;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.border-red-cast {
    border-color: #DD1760;;
}

.gp-20 {
    gap: 20px;
}

.checked_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    input[type='checkbox'] {
        outline: none !important;
        color: #61CC2C;
    }
}

.label_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}

.parent_range {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.range_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.range_label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.percent_view {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: right;

    color: #000000;
}

.w_range {
    width: 611px;
    display: flex;
    align-items: center;

    .range_line {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 3px;
        background: #CCEAFF;
        top: calc(50% - 3px/2);
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            background: #61CC2C;
        }
    }

    .error_line {
        background: #DD1760;
    }
}

.image_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_div {
    width: 300px;
    height: 300px;

    img {
        width: 100%;
        height: 100%;
    }
}

.default_image_div {
    width: 150px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
    }
}

.blog_challenge_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .item_in_row {
        width: 327px;
    }
}
</style>
