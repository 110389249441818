<template>
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2.5L10 10.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
        <path d="M10 2.5L2 10.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>
<script>
export default {
    name: 'CloseIcon',
}
</script>
