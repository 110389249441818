<template>
    <div class="centered_in_row">
        <div class="w-full default_image_div">
            <img :src="default_marker ? default_marker : getImgUrl(default_image)" alt="marker_default">
        </div>
    </div>
</template>
<script>
export default {
    name: 'ImageUploadImit',
    props: {
        default_marker: {
            type: String,
            default: '',
        },
        default_image: {
            type: String,
            default: '',
        }
    },
    methods: {
        getImgUrl(path) {
            return require(`@/assets/${path}`);
        },
    }
}
</script>
<style lang="scss" scoped>
.image_div {
    width: 300px;
    height: 300px;

    img {
        width: 100%;
        height: 100%;
    }
}

.default_image_div {
    width: 150px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
    }
}

.centered_in_row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
</style>
