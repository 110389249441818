<template>
    <div class="first_row">
        <div class="title_page">
            {{ title }}
        </div>

        <div v-if="key_view" class="active_button">
            <button class="white_green_btn" @click="routeGo('AddNewsAdmin')">
                Add news
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FirstRowInNews',
    props: {
        title: {
            type: String,
            default: 'News',
        },
        key_view: {
            type: Boolean,
            default: true,
        }
    },
    methods: {
        async routeGo(route) {
            await this.$router.push({name: route});
        }
    },
}
</script>
<style lang="scss" scoped>
.first_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.active_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
</style>
