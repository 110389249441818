<template>
    <div class="blog_challenge_row">
<!--        <div class="item_in_row">-->
            <div class="relative w-full z-0">
                <input
                    :disabled="is_disabled"
                    type="number"
                    name="users_offered"
                    id="_users_offered"
                    class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                    :class="{is_disabled: is_disabled}"
                    @input="$emit('update:users_offered', numbersInputChange($event.target.value, 15))"
                    :value="users_offered"
                />
                <label
                    for="users_offered"
                    class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title"
                    :class="{gray_text: is_disabled}">
                    How many users will be offered
                </label>
            </div>
<!--        </div>-->

<!--        <div class="item_in_row">-->
<!--            <div class="relative w-full z-0">-->
<!--                <input-->
<!--                    :disabled="is_disabled"-->
<!--                    type="number"-->
<!--                    name="coins_reward"-->
<!--                    id="_coins_reward"-->
<!--                    class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"-->
<!--                    :class="{is_disabled: is_disabled}"-->
<!--                    @input="$emit('update:coins_reward', numbersInputChange($event.target.value, 20, 0))"-->
<!--                    :value="coins_reward"-->
<!--                />-->
<!--                <label-->
<!--                    for="coins_reward"-->
<!--                    class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title"-->
<!--                    :class="{gray_text: is_disabled}">-->
<!--                    Xcoins reward (max 15)-->
<!--                </label>-->
<!--            </div>-->
<!--        </div>-->
    </div>

    <div class="blog_challenge_row">
        <div class="item_in_row">
            <div class="relative w-full z-0">
                <input
                    :disabled="is_disabled"
                    type="number"
                    name="character_number"
                    id="_character_number"
                    :class="{is_disabled: is_disabled}"
                    class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                    @input="$emit('update:character_number', numbersInputChange($event.target.value, 1000))"
                    :value="character_number"
                />
                <label
                    for="character_number"
                    class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title"
                    :class="{gray_text: is_disabled}">
                    Min characters numbers
                </label>
            </div>
        </div>

        <div class="item_in_row">
            <div class="relative w-full z-0">
                <input
                    :disabled="is_disabled"
                    type="number"
                    name="number_photos"
                    id="_number_photos"
                    class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                    :class="{is_disabled: is_disabled}"
                    @input="$emit('update:number_photos', numbersInputChange($event.target.value, 15))"
                    :value="number_photos"
                />
                <label
                    for="number_photos"
                    class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title"
                    :class="{gray_text: is_disabled}">
                    Min numbers of photos
                </label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'QuestBlogInf',
    props: {
        users_offered: null,
        coins_reward: null,
        character_number: null,
        number_photos: null,
        is_disabled: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'update:users_offered': null,
        'update:coins_reward': null,
        'update:character_number': null,
        'update:number_photos': null,
    },
    methods: {
        numbersInputChange(val, max, min = 1) {
            const max_num = Number(max);
            const min_num = Number(min);
            const num_val = Number(val);

            if (num_val > max_num) {
                return max_num;
            }

            if (num_val < min_num) {
                return min_num;
            }

            return num_val;
        },
    }
}
</script>
<style lang="scss" scoped>
.blog_challenge_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .item_in_row {
        width: 327px;
    }
}

.is_disabled {
    color: #9CA8B6;
    border-color: #9CA8B6;
}

.gray_text {
    color: #9CA8B6;
}
</style>
