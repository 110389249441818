<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-in-news :title="'News'"/>

            <table-template
                v-if="table_view"
                v-model:search="requestData.search"
                v-model:perPage="requestData.perPage"
                v-model:page="requestData.page"
                v-model:sort="requestData.sort"
                :clicked_row="true"
                @clicked_row="openNews"
                :columns="columns"
                :data="news"
                :total_count="total_count"/>

            <empty-quests-data
                v-if="!news.length"
                :nothing_found="table_view"
                :message="!table_view ? 'No added news yet.' : 'Nothing found, please try again'"/>
        </div>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import EmptyQuestsData from "@/components/admin/EmptyQuestsData.vue";
import FirstRowInNews from "@/components/admin/FirstRowInNews.vue";
import {baseErrHandler, getNewsList} from "@/services/api";
import {getDateFormat, tableGetDate} from "@/helpers/time.helper";
import TableTemplate from "@/components/TableTemplate.vue";

export default {
    name: 'NewsAdmin',
    props: {
        active_item: {
            type: String,
            default: '',
        }
    },
    components: {
        TableTemplate,
        FirstRowInNews,
        EmptyQuestsData,
        AdminLayouts,
    },
    data() {
        return {
            news: [],
            requestData: {
                sort: {
                    type: 'desc',
                    field: 'create_time',
                },
                page: 1,
                perPage: 10,
                search: '',
                active: 1,
            },
            total_count: 0,
            columns: [
                {
                    title: 'Date',
                    field: 'create_time',
                    width: 'w-2/12',
                    sort: true,
                    function: (val) => {
                        if (val.create_time) {
                            return this.returnViewDate(val.create_time);
                        }
                        return '';
                    }
                },
                {
                    title: 'Title',
                    field: 'title',
                    width: 'w-2/12',
                    sort: true,
                },
                {
                    title: 'Start time',
                    width: 'w-3/12',
                    field: 'start_time',
                    sort: true,
                    function: (val) => {
                        if (val.start_time) {
                            return this.returnViewDate(val.start_time);
                        }
                        return '';
                    }
                },
                {
                    title: 'End time',
                    width: 'w-3/12',
                    field: 'end_time',
                    sort: true,
                    function: (val) => {
                        if (val.end_time) {
                            return this.returnViewDate(val.end_time);
                        }
                        return '';
                    }
                },
                {
                    title: 'Author',
                    width: 'w-2/12',
                    field: 'author_email',
                    sort: true,
                    style: 'word-break: break-all'
                },
            ],
        };
    },
    methods: {
        async openNews(val) {
            await this.$router.push(`/admin/revision-news/${val.id}`);
        },
        async newsList() {
            await baseErrHandler(async () => {
                const { list, total_count } = await getNewsList(this.requestData);

                this.news = list;
                this.total_count = total_count;
            });
        },
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
    },
    async beforeMount() {
        await this.newsList();
    },
    watch: {
        ['requestData.page']() {
            this.newsList();
        },
        ['requestData.perPage']() {
            this.requestData.page = 1;
            this.newsList();
        },
        ['requestData.sort']() {
            this.requestData.page = 1;
            this.newsList();
        },
        ['requestData.search']() {
            this.requestData.page = 1;
            this.newsList();
        }
    },
    computed: {
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
        table_view() {
            if (!this.news.length && !this.requestData.search) {
                return false;
            }
            return true;
        }
    },
}
</script>
