<template>
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 5L0 0L10 5.60818e-07L5 5Z" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'GreenTriangIcon',
}
</script>
