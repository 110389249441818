<template>
    <div class="w-full h-full empty_data">
        <div class="img_parent">
            <img src="../../../public/EmptyUsersData.png" alt="empty data">
        </div>
        <div class="empty_message">
            {{ message }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'EmptyUsersData',
    props: {
        message: {
            type: String,
            default: 'No users yet'
        }
    }
}
</script>
<style lang="scss" scoped>
.empty_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img_parent {
    width: 150px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
    }
}

.empty_message {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #599FD2;
}
</style>
