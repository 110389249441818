<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-users-detail
                v-if="user"
                @change_value="changeValue"
                @selected_avatar="setAvatar"
                :user="user" />

            <div class="table_name">
                Xcoins activity:
            </div>

            <table-template
                v-if="balance"
                :is_search="false"
                :indexed="false"
                :order_sorted="false"
                v-model:search="requestData.search"
                v-model:perPage="requestData.perPage"
                v-model:page="requestData.page"
                v-model:sort="requestData.sort"
                :clicked_row="false"
                :columns="columns"
                :data="balance"
                :total_count="total_count"/>

        </div>

        <change-user-balance
            :value="current_value"
            :type="selected_type"
            @set_change="setChange"
            @close_modal="closeModal"
            ref="changeUserBalance"/>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import FirstRowUsersDetail from "@/components/admin/FirstRowUsersDetail.vue";
import {
    adminChangeUserBalance,
    baseErrHandler, changeAvatarBase64,
    changeUserEmail, changeUserPassword,
    getUserGlobalStat,
    userBalanceHistory
} from "@/services/api";
import {getDateFormat} from "@/helpers/time.helper";
import TableTemplate from "@/components/TableTemplate.vue";
import ChangeUserBalance from "@/components/modals/admin/users/ChangeUsersBalance.vue";

export default {
    name: 'UsersDetailAdmin',
    components: {
        ChangeUserBalance,
        TableTemplate,
        FirstRowUsersDetail,
        AdminLayouts
    },
    props: {
        active_item: {
            type: String,
            default: '',
        },
        user_id: {
            type: [String, Number],
            default: '',
        }
    },
    data() {
        return {
            user: null,
            balance: null,
            current_value: 0,
            selected_type: '',
            requestData: {
                user_id: null,
                sort: {
                    type: 'desc',
                    field: 'order',
                },
                page: 1,
                perPage: 10,
                search: '',
                active: 1,
            },
            total_count: 0,
            columns: [
                {
                    title: 'Date',
                    field: 'time',
                    width: 'w-4/12',
                    sort: false,
                    function: (val) => {
                        if (val?.time) {
                            return this.returnViewDate(val.time);
                        }
                        return '';
                    }
                },
                {
                    title: 'Amount',
                    field: 'xcoins',
                    width: 'w-2/12',
                    sort: false,
                    function: (val) => {
                        if (val.xcoins > 0) {
                            return '+ ' + val.xcoins;
                        }
                        return '- ' + (val.xcoins * -1);
                    }
                },
                {
                    title: 'Balance',
                    width: 'w-2/12',
                    field: 'balance',
                    sort: false,
                },
                {
                    title: 'Description',
                    width: 'w-4/12',
                    field: 'description',
                    sort: false,
                }
            ],
        };
    },
    async beforeMount() {
        await this.getUserInf();
        await this.getUserBalanceList();
    },
    methods: {
        async getUserInf() {
            await baseErrHandler(async () => {
                const { user } = await getUserGlobalStat(this.user_id);

                if (user) {
                    this.user = user;
                }
            });
        },
        async getUserBalanceList() {
            await baseErrHandler(async () => {
                if (this.user?.id) {
                    this.requestData.user_id = this.user.id;
                    const { list, total_count } = await userBalanceHistory(this.requestData);

                    this.balance = list;
                    this.total_count = total_count;
                }
            });
        },
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
        closeModal() {
            this.selected_type = '';
            this.current_value = 0;
            this.$refs.changeUserBalance.show = false;

            const body = document.querySelector("body");
            body.style.overflow = "auto";
        },
        changeValue(type) {
            this.selected_type = type;
            this.current_value = this.user[type];

            console.log(this.selected_type);

            this.$refs.changeUserBalance.openModal();
        },
        async setChange(value) {
            if (this.selected_type !== 'email' && this.selected_type !== 'password') {
                await this.setChangeAmount(value);
            } else if (this.selected_type === 'password') {
                await this.setChangePassword(value);
            } else if (this.selected_type === 'email') {
                await this.setChangeEmail(value);
            }
        },
        async setChangeAmount(amount) {
            await baseErrHandler(async () => {
                const { success } = await adminChangeUserBalance({
                    type: this.selected_type,
                    amount,
                    user_id: this.user_id,
                });

                if (success) {
                    await this.changeCompleted();
                }
            });
        },
        async setChangePassword(password) {
            await baseErrHandler(async () => {
                const { success } = await changeUserPassword(this.user_id, password);

                if (success) {
                    await this.changeCompleted();
                }
            });
        },
        async setChangeEmail(email) {
            await baseErrHandler(async () => {
                const { success } = await changeUserEmail(this.user_id, email);

                if (success) {
                    await this.changeCompleted();
                }
            });
        },
        async changeCompleted() {
            await this.getUserInf();
            await this.getUserBalanceList();

            this.closeModal();
        },
        async setAvatar(image) {
            if (!image) {
                return;
            }
            await baseErrHandler(async () => {
                const { success } = await changeAvatarBase64(this.user_id, image);

                if (success) {
                    await this.changeCompleted();
                }
            });
        }
    },
    watch: {
        ['requestData.page']() {
            this.getUserBalanceList();
        },
        ['requestData.perPage']() {
            this.requestData.page = 1;
            this.getUserBalanceList();
        },
        ['requestData.sort']() {
            this.requestData.page = 1;
            this.getUserBalanceList();
        }
    },
    computed: {
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
    },
}
</script>
<style lang="scss" scoped>
.table_name {
    width: 100%;
    text-align: left;
    margin-top: 46px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}
</style>
