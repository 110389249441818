import http from "./http_client";
import { errorMessage } from "@/services/messages";
import storage from "@/store/index";

export const baseErrHandler = async (callback) => {
  try {
    storage.commit("showLoader");
    await callback();
  } catch (e) {
    console.log(e);
    errorMessage(e?.response?.data?.error?.message);
  } finally {
    storage.commit("hideLoader");
  }
};

export const passwordReset = async (data) => {
    const response = await http.post(`${http.apiUrl()}/password/reset`, data);
    const { success } = response?.data || {};
    return { success };
}
export const setNewPassword = async (data) => {
    const response = await http.post(`${http.apiUrl()}/password/set_new_password`, data);
    const { success } = response?.data || {};
    return { success };
};

export const logInAdmin = async (data) => {
    const response = await http.post(`${http.apiUrl()}/admin/log-in`, data);
    const { token, user } = response?.data?.data;
    return { token, user };
}

export const allTimezones = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/timezones/all`);
    const { timezones } = response?.data?.data;
    return { timezones };
};

export const allCategoriesQuest = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/categories/all`);
    const { categories } = response?.data?.data;
    return { categories };
};

export const createQuestCategory = async (name) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/categories/create`, {name});
    const { data } = response?.data;
    return { data };
};

export const createQuest = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/quests/create_quest`, data);
    const {
        quest,
        quest_budget,
        trailblazer,
        trailblazer_budget,
        quest_blog_challenge
        } = response?.data?.data;
    return {
        quest,
        quest_budget,
        trailblazer,
        trailblazer_budget,
        quest_blog_challenge
    };
};

export const deleteImageInBase = async (path) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/delete_image_in_base` ,{image_url: path});
    const { success } = response?.data || {};
    return { success };
};

export const questInfoGet = async (quest_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/quests/get_quest_inf?id=${quest_id}`);
    const {
        quest,
        quest_budget,
        trailblazer,
        trailblazer_budget,
        quest_blog_challenge
    } = response?.data?.data;
    return {
        quest,
        quest_budget,
        trailblazer,
        trailblazer_budget,
        quest_blog_challenge
    };
};

export const deleteQuestCover = async (quest_id) => {
    const response = await http.deleteAuth(`${http.apiUrl()}/quest/delete_cover/${quest_id}`);
    const { success } = response?.data || {};
    return { success };
}

export const deleteDefMarkerIconByQuestId = async (quest_id) => {
    const response = await http.deleteAuth(`${http.apiUrl()}/marker_list/delete_icon_by_quest_id/${quest_id}`);
    const { success } = response?.data || {};
    return { success };
};

export const createMarkersListAdmin = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/markers_list/create_markers_list`, data);
    const { markers_list } = response?.data?.data;
    return { markers_list };
};

export const getMarkersListInfo = async (quest_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/markers_list/get_markers_list_info?quest_id=${quest_id}`);
    const { markers_list } = response?.data?.data;
    return { markers_list };
};

export const getAddressOpenStreetMap = async (latLng) => {
    const response = await http.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latLng.lat}&lon=${latLng.lng}`);
    const { address } = response?.data;
    return { address };
};

export const saveCordInDB = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/locations/create`, data);
    const { location } = response?.data?.data;
    return { location };
};

export const getLocationById = async (location_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/locations/get?id=${location_id}`);
    const { location } = response?.data?.data;
    return { location };
};

export const createMarker = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/markers/create`, data);
    const { marker } = response?.data?.data;
    return { marker };
};

export const getMarkerById = async (marker_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/markers/get?id=${marker_id}`);
    const { marker } = response?.data?.data;
    return { marker };
};

export const getAllChallengesCategories = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/challenge/get_all_challenge_categories`);
    const { challenge_categories } = response?.data?.data;
    return { challenge_categories };
};

export const createOrUpdateChallenge = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/challenges/create`, data);
    const { challenge } = response?.data?.data;
    return { challenge };
};

export const getChallengesByMarkerId = async (marker_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/challenges/get_by_marker_id/${marker_id}`);
    const { challenges } = response?.data?.data;
    return { challenges };
};

export const deleteChallengeCover = async (path, challenge_id) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/challenges/delete_cover` ,{
        image_url: path,
        id: challenge_id,
    });
    const { success } = response?.data || {};
    return { success };
};

export const deleteMarkerIconById = async (marker_id) => {
    const response = await http.deleteAuth(`${http.apiUrl()}/marker/delete_icon_by_marker_id/${marker_id}`);
    const { success } = response?.data || {};
    return { success };
};

export const deleteMarkerBadgeById = async (marker_id) => {
    const response = await http.deleteAuth(`${http.apiUrl()}/marker/delete_badge_by_marker_id/${marker_id}`);
    const {success} = response?.data || {};
    return {success};
}
export const getMarkerListIconById = async (marker_list_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/marker_list/get_icon_by_id?id=${marker_list_id}`);
    const { path } = response?.data?.data;
    return { path };
};

export const getAllMarkersByListId = async (marker_list_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/markers_list/all_markers_in_list_by_id?id=${marker_list_id}`);
    const { markers } = response?.data?.data;
    return { markers };
};

export const deleteChallengeById = async (challenge_id) => {
    const response = await http.deleteAuth(`${http.apiUrl()}/admin/challenges/delete_by_id/${challenge_id}`);
    const {success} = response?.data || {};
    return {success};
};

export const saveQuestAndTrailblazerBadges = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/badges/create`, data);
    const {success} = response?.data || {};
    const { quest_badges, trailblazer_badges } = response?.data?.data;
    return { success, quest_badges, trailblazer_badges };
};

export const getBadgesByQuestId = async (quest_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/badges/select_badges_by_quest_id/${quest_id}`);
    const { quest_badges, trailblazer_badges } = response?.data?.data;
    return { quest_badges, trailblazer_badges };
};

export const deleteQuestBadge = async (quest_id, badge_field) => {
    const response = await http.postAuth(`${http.apiUrl()}/quest/delete_quest_badge`, {quest_id, badge_field});
    const { success } = response?.data || {};
    return { success };
};

export const deleteTrailblazerBadge = async (id, place_number) => {
    const response = await http.postAuth(`${http.apiUrl()}/quest/trailblazer_badge_delete`, {id, place_number});
    const { success } = response?.data || {};
    return { success };
};
export const getQuestNameByMarkerId = async (marker_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/markers/get_quest_name_by_marker_id/${marker_id}`);
    const { quest_name } = response?.data?.data;
    return { quest_name };
};

export const deleteMarkerById = async (marker_id) => {
    const response = await http.deleteAuth(`${http.apiUrl()}/marker/marker_delete_by_id/${marker_id}`);
    const { success } = response?.data || {};
    return { success };
};

export const getQuestsList = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/quests/get_quests_list`, data);
    const { list, total_count } = response?.data?.data;
    return { list, total_count };
};

export const getQuestsWithChallengesList = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/quests/get_quests_with_challenges_list`, data);
    const { list, total_count } = response?.data?.data;
    return { list, total_count };
};

export const getQuestWithCountAnswers = async (quest_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/quests/get_quest_with_count_answers/${quest_id}`);
    const { quest } = response?.data?.data;
    return { quest };
};

export const getChallengesReviewAdmin = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/challenges/get_challenges_answers_by_type`, data);
    const { list, total_count } = response?.data?.data;
    return { list, total_count };
};

export const findApprovedChallengesAdmin = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/challenges/find_archive_challenges`, data);
    const { list, total_count } = response?.data?.data;
    return { list, total_count };
};

export const getChallengeById = async (id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/challenges/get_challenge_data/${id}`);
    const { challenge } = response?.data?.data;
    return { challenge };
};

export const setReviewChallenge = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/challenges/set_review`, data);
    const { answer } = response?.data?.data;
    return { answer };
};

export const getUsersList = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/users/get_users_list`, data);
    const { list, total_count } = response?.data?.data;
    return { list, total_count };
};

export const getUserGlobalStat = async (user_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/users/get_user_global_stats/${user_id}`);
    const { user } = response?.data?.data;
    return { user };
};

export const userBalanceHistory = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/users/user_balance_history`, data);
    const { list, total_count } = response?.data?.data;
    return { list, total_count };
};

export const adminChangeUserBalance = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/users/change_user_balance_admin`, data);
    const { success } = response?.data || {};
    return { success };
};

export const downloadCSV = async (data) => {
    await http.downloadItem(data);
}

export const uploadCSV = async (data) => {
    const response = await http.postDownload(`${http.apiUrl()}/admin/csv/file_upload`, data);
    const { success } = response?.data || {};
    return { success };
}

export const getReportsIssue = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/issues/issue_list`, data);
    const { list, total_count } = response?.data?.data;
    return { list, total_count };
}

export const setResolved = async (id) => {
    const response = await http.putAuth(`${http.apiUrl()}/admin/issues/set_resolved/${id}`);
    const { issue } = response?.data?.data;
    return { issue };
};

export const saveNews = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/news/create_or_update`, data);
    const { news } = response?.data?.data;
    return { news };
}

export const getNewsById = async (id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/news/get_by_id/${id}`);
    const { news } = response?.data?.data;
    return { news };
}

export const deleteCoverNewsById = async (id) => {
    const response = await http.deleteAuth(`${http.apiUrl()}/admin/news/delete_cover/${id}`);
    const { news } = response?.data?.data;
    return { news };
}

export const getNewsList = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/news/news_list`, data);
    const { list, total_count } = response?.data?.data;
    return { list, total_count };
};

export const getXcoinsConstants = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/const/xcoins`);
    const { constants } = response?.data?.data;
    return { constants };
};

export const setXcoinsConstants = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/const/set_xcoins_const`, data);
    const { constants } = response?.data?.data;
    return { constants };
}

export const getPointsConstants = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/const/points`);
    const { constants } = response?.data?.data;
    return { constants };
};

export const setPointsConstants = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/const/set_points`, data);
    const { constants } = response?.data?.data;
    return { constants };
}

export const getAllBundles = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/const/bundles`);
    const { bundles } = response?.data?.data;
    return { bundles };
};

export const setBundlesBatch = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/const/set_bundles_batch`, data);
    const { bundles } = response?.data?.data;
    return { bundles };
};

export const setMaintenance = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/toggle_maintenance_mod`);
    const { success } = response?.data || {};
    return { success };
};

export const isMaintenance = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/is_maintenance_mod`);
    const { is_maintenance } = response?.data || {};
    return { is_maintenance };
};

export const getKey = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/get_const_by_key`, data);
    const { constant } = response?.data?.data;
    return { constant };
};

export const approveQuest = async (quest_id, data = {}) => {
    const response = await http.putAuth(`${http.apiUrl()}/admin/quests/approve_quest/${quest_id}`, data);
    const { success } = response?.data || {};
    return { success };
};

export const declineQuest = async (quest_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/quests/decline_quest/${quest_id}`);
    const { success } = response?.data || {};
    return { success };
};

export const getQuestTypeByLocation = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/quest/get_quests_locations_types`);
    const { types } = response?.data?.data;
    return { types };
};

export const setQuestTypeByLocation = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/quest_by_locations_type/set_price`, data);
    const { types } = response?.data?.data;
    return { types };
};
export const copyQuest = async (quest_id) => {
    const response = await http.putAuth(`${http.apiUrl()}/admin/quests/copy_quest/${quest_id}`);
    const {
        quest,
        quest_budget,
        blog_challenge,
        trailblazer,
        marker_list
    } = response?.data?.data;
    return {
        quest,
        quest_budget,
        blog_challenge,
        trailblazer,
        marker_list
    };
};

export const getAllDifficultyAndRewards = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/difficulty_rewards/get_all`);
    const { difficulty_rewards } = response?.data?.data;
    return { difficulty_rewards };
};

export const setDifficultyAndRewards = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/difficulty_rewards/set_difficulty_and_rewards`, data);
    const { difficulty_rewards } = response?.data?.data;
    return { difficulty_rewards };
};

export const getTrailblazersList = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/trailblazers/get_trailblazers_list`, data);
    const { list, total_count } = response?.data?.data;
    return { list, total_count };
};

export const getTrailblazerPlacesRewards = async (trailblazer_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/trailblazers/get_trailblazer_rewards_places/${trailblazer_id}`);
    const { rewards } = response?.data?.data;
    return { rewards };
};

export const getTrailblazerWithQuest = async (trailblazer_id) => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/trailblazers/get_trailblazer/${trailblazer_id}`);
    const { trailblazer } = response?.data?.data;
    return { trailblazer };
};

export const setAdminNotesInReward = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/trailblazers/set_admin_notes`, data);
    const { reward } = response?.data?.data;
    return { reward };
};

export const setValidOrNotMarker = async (marker_id) => {
    const response = await http.putAuth(`${http.apiUrl()}/admin/markers/set_valid/${marker_id}`);
    const { success } = response?.data || {};
    return { success };
};

export const getUserBonus = async () => {
    const response = await http.getAuth(`${http.apiUrl()}/admin/const/user_bonus`);
    const { bonus } = response?.data?.data;
    return { bonus };
};

export const setUserBonus = async (data) => {
    const response = await http.postAuth(`${http.apiUrl()}/admin/const/set_user_bonus`, data);
    const { bonus } = response?.data?.data;
    return { bonus };
};

export const changeUserEmail = async (user_id, email) => {
    const response = await http.putAuth(`${http.apiUrl()}/admin/users/change_user_email/${user_id}`, {email});
    const { success } = response?.data || {};
    return { success };
};

export const changeUserPassword = async (user_id, password) => {
    const response = await http.putAuth(`${http.apiUrl()}/admin/users/change_user_password/${user_id}`, {password});
    const { success } = response?.data || {};
    return { success };
};

export const changeAvatarBase64 = async (user_id, avatar) => {
    const response = await http.putAuth(`${http.apiUrl()}/admin/users/change_user_avatar/${user_id}`, {avatar});
    const { success } = response?.data || {};
    return { success };
};
