<template>
    <div class="first_row">
        <div class="title_page">
            {{ title }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'FirstRowReports',
    props: {
        title: {
            type: String,
            default: 'Reports',
        }
    }
}
</script>
<style lang="scss" scoped>
.first_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
</style>
