<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body add_news_body_template">
            <first-active-row
                :rout_button="`/admin/edit-news/${id}`"
                :name="title"
                :rout_name="'NewsAdmin'"
                :title="'News'"/>

            <div class="create_form">
                <div class="date_row" v-if="id">
                    <div class="post_info_col">
                        <div class="sub_title_item">
                            Date:
                        </div>
                        <div class="text_item" v-if="create_time">
                            {{returnViewDate(create_time)}}
                        </div>
                    </div>

                    <div class="post_info_col">
                        <div class="sub_title_item">
                            Added by:
                        </div>
                        <div class="text_item" v-if="author_email">
                            {{author_email}}
                        </div>
                    </div>
                </div>

                <div class="emit_view_input">
                    <div class="label_emit">
                        Title
                    </div>
                    <div class="text_emit">
                        {{title}}
                    </div>
                </div>

                <div class="date_row">
                    <div class="emit_view_input">
                        <div class="label_emit">
                            Start date and time
                        </div>
                        <div class="text_emit">
                            {{returnViewDate(start_time)}}
                        </div>
                    </div>

                    <div class="emit_view_input">
                        <div class="label_emit">
                            End date and time
                        </div>
                        <div class="text_emit">
                            {{returnViewDate(end_time)}}
                        </div>
                    </div>
                </div>

                <div class="emit_view_input">
                    <div class="label_emit">
                        Text
                    </div>

                    <textarea
                        class="text_emit area_emit"
                        disabled
                        ref="textAreaInput"
                        :style="`height: ${height}px; resize:none; overflow: hidden;`"
                        rows="auto" v-model="text">
                    </textarea>
                </div>

                <div class="emit_view_input">
                    <div class="label_emit">
                        Link to article
                    </div>
                    <div class="text_emit">
                        {{article_link}}
                    </div>
                </div>

                <div class="photo_title">
                    News cover photo:
                </div>

                <div class="is_little_body">
                    <img :src="cover" alt="cover">
                </div>

            </div>
        </div>
    </admin-layouts>
</template>
<script>
import DateAndTimePicker from "@/components/UI/datepickers/DateAndTimePicker.vue";
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import FirstActiveRow from "@/components/admin/FirstActiveRow.vue";
import {baseErrHandler, getNewsById} from "@/services/api";
import {getDateFormat} from "@/helpers/time.helper";

export default {
    name: 'RevisionNews',
    components: {
        DateAndTimePicker,
        AdminLayouts,
        FirstActiveRow,
    },
    props: {
        active_item: {
            type: String,
            default: '',
        },
        id: {
            type: [Number, String],
            default: '',
        }
    },
    data() {
        return {
            title: '',
            text: '',
            start_time: null,
            end_time: null,
            cover: null,
            create_time: null,
            author_email: null,
            article_link: '',
            selected_timezone: {
                gmt: null,
            },
            height: 44,
        };
    },
    methods: {
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
        initData(news) {
            this.title = news?.title ? news.title : '';
            this.text = news?.text ? news.text : '';
            this.cover = news?.cover ? news.cover : null;
            this.start_time = news?.start_time ? news.start_time : null;
            this.end_time = news?.end_time ? news.end_time : null;
            this.create_time = news?.create_time ? news.create_time : null;
            this.author_email = news?.author_email ? news.author_email : null;
            this.article_link = news?.article_link ? news.article_link : '';
        },
        async getNews() {
            await baseErrHandler(async () => {
                const { news } = await getNewsById(this.id);

                if (news) {
                    this.initData(news);
                }
            });
        },
        auto_grow() {
            const height = this.$refs.textAreaInput.scrollHeight;
            if (height > this.height) {
                this.height = height;
            }
        }
    },
    computed: {
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
    },
    async beforeMount() {
        this.selected_timezone.gmt = this.timezoneOffset;
        if (this.id) {
            await this.getNews();
        }
        this.auto_grow();
    }
}
</script>
<style lang="scss" scoped>
.add_news_body_template {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 44px;
}

.create_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.photo_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #214B41;
}

.button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.date_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.post_info_col {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.sub_title_item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #599FD2;
}

.text_item {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #364652;
}

.emit_view_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 12px;
    border-bottom: 2px solid #9CA8B6;

    .label_emit {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 23px;
        color: #9CA8B6;
    }

    .text_emit {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 23px;
        color: #9CA8B6;
        text-align: justify;
    }
}

.area_emit {
    background: none;
}

.is_little_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 300px;
    }
}
</style>
