<template>
    <div class="parent_range">
        <label class="form-label range_label capitalize"
               :class="{gray_text: is_disabled}">
            {{ name }}
        </label>

        <div class="range_body">
            <div class="relative w_range" :class="{class_disabled: is_disabled}">
                <div class="range_line"></div>
                <input
                    :disabled="is_disabled"
                    type="range"
                    class="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
                    :min="min"
                    :max="max"
                    :step="step"
                    @change="setSelect($event.target.value)"
                    :value="select_val"
                />
            </div>

            <div class="percent_view" :class="{gray_text: is_disabled}">
                {{ xcoins }}
            </div>
        </div>
    </div>
</template>
<script>
import {baseErrHandler, getAllDifficultyAndRewards} from "@/services/api";

export default {
    name: 'DifficultyTypeRangeImit',
    props: {
        modelValue: {
            type: [Number, String, null],
            required: true,
        },
        is_disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'update:modelValue': null,
    },
    methods: {
        async getDifficulty() {
            await baseErrHandler(async () => {
                const { difficulty_rewards } = await getAllDifficultyAndRewards();
                if (difficulty_rewards) {
                    this.difficulty_rewards = difficulty_rewards.sort(function (a, b) {
                        if (a.order > b.order) {
                            return 1;
                        }
                        if (a.order < b.order) {
                            return -1;
                        }
                        return 0;
                    });
                }
            });
        },
        setSelect(val) {
            if (this.difficulty_rewards.length) {
                for (const item of this.difficulty_rewards) {
                    if (item.order === Number(val)) {
                        this.select_val = item.order;
                        this.name = item.name;
                        this.xcoins = item.xcoins;
                        if (this.modelValue !== item.id) {
                            this.$emit('update:modelValue', item.id);
                        }
                    }
                }
            }
        },
    },
    async beforeMount() {
        await this.getDifficulty();
        if (!this.modelValue) {
            this.setSelect(1);
        }
    },
    data() {
        return {
            difficulty_rewards: [],
            select_item: {},
            min: 1,
            step: 1,
            select_val: 1,
            name: '',
            xcoins: 1,
        }
    },
    computed: {
        max() {
            if (this.difficulty_rewards.length) {
                return this.difficulty_rewards.length;
            }
            return 4;
        }
    },
    watch: {
        modelValue(val, old) {
            if (val && val !== old) {
                this.setSelect(val);
            } else if (!val) {
                this.setSelect(1);
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.parent_range {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.range_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.range_label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.percent_view {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: right;

    color: #000000;
}

.w_range {
    width: 571px;
    display: flex;
    align-items: center;
    z-index: 0;

    .range_line {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 3px;
        background: #CCEAFF;
        top: calc(50% - 3px/2);
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            z-index: 2;
            background-color: #61CC2C;
            width: 15px;
            -webkit-appearance: none;
            height: 15px;
            border-radius: 100%;
        }
    }

    .error_line {
        background: #DD1760;
    }
}

.class_disabled {
    color: #9CA8B6;

    .range_line {
        background: #9CA8B6;
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            background-color: #4a5568;
        }
    }
}

.gray_text {
    color: #9CA8B6;
}
</style>
