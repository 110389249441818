<template>
    <create-quest-layouts
        :key="`add_marker`"
        :title="'Add marker'"
        :is_menu="false"
        :quest_id="quest_id"
        :quest_name="quest_name"
        :active_item="active_item"
        :active_tab="active_tab">

        <div class="w-full centered_body">
            <div class="card_body">
                <div class="w-full item_in_card">
                    <div class="item_title">
                        Custom marker icon (optional):
                    </div>

                    <upload-image
                        :pre_image="default_marker ? default_marker : ''"
                        @selected_image="selectedMarkerIcon"
                        @delete_image_in_base="deleteMarkerIconInBase"
                        :default_image="'DefMarkerIcon.png'"/>

                    <div class="item_title">
                        Marker info:
                    </div>

                    <input-type-text
                        :name="'quest-name'"
                        :title="'Marker name'"
                        v-model="name"/>

                    <input-type-text
                        :name="'quest-subtitle'"
                        :title="'Marker subtitle'"
                        v-model="subtitle"/>

                    <input-type-text
                        :name="'quest-description'"
                        :title="'Marker description'"
                        v-model="description"/>

                    <div class="switch_qr_row">
                        <switch-elem v-model="is_qr"/>

                        <div class="item_title">
                            Enable QR check in
                        </div>
                    </div>

                    <q-r-code-generate
                        v-if="is_qr"
                        :quest_name="quest_name"
                        :marker_name="name"
                        :view_qr="view_qr"
                        :view_button="true"
                        v-model="qr_code"/>

                    <div class="item_title" v-if="!is_qr">
                        Check in range
                    </div>

                    <input-type-decimal
                        v-if="!is_qr"
                        v-model="range"
                        :title="'Range in miles'"/>

                    <div class="item_title">
                        Location
                    </div>

                    <div class="in_center gp-20">
                        <button
                            v-if="!location"
                            :disabled="is_disable_add_location"
                            class="white_green_btn"
                            @click="openAddLocation">
                            Add location
                        </button>

                        <div v-else class="location_info_body">
                            <div class="location_info">
                                <div class="location_info_address">
                                    {{location?.street ? location.street : ''}} {{location?.city ? location.city : ''}}
                                </div>
                                <div class="location_info_cord">
                                    {{location.lat}} {{location.lng}}
                                </div>
                            </div>

                            <button
                                @click="openAddLocation"
                                class="btn_edit_pencil">
                                <edit-icon/>
                            </button>
                        </div>
                    </div>

                    <div class="item_title">
                        Check in reward:
                    </div>

                    <difficulty-type-range-imit
                        v-model="difficulty_rewards_id"/>

                    <input-type-checkbox
                        :title="'Marker badge'"
                        :name="'marker_is_badge'"
                        v-model="is_badge"/>

                    <upload-image
                        v-if="is_badge"
                        :pre_image="badge ? badge : ''"
                        @selected_image="selectedMarkerBadge"
                        @delete_image_in_base="deleteMarkerBadge"
                        :default_image="'Badge/GrayStar.png'"/>

                    <input-type-text
                        v-if="is_badge"
                        :title="'Badge name (optional)'"
                        :name="badge_title"
                        v-model="badge_title"/>

                    <input-type-checkbox
                        :name="`bonus`"
                        :title="`Is bonus marker`"
                        v-model="bonus"/>

                    <input-type-number
                        v-if="bonus"
                        :id="'purchased_price'"
                        v-model="purchased_price"
                        :title="'Bonus marker price (Xcoins)'"/>

                    <div class="item_title">
                        Challenges:
                    </div>

                    <input-type-checkbox
                        :name="`in_sequence`"
                        :title="`All challenges only in sequence`"
                        v-model="in_sequence"/>

                    <div
                        v-if="challenges.length"
                        :id="`parent_drag_challenge`"
                        class="w-full marker_list">
                        <DraggableComponent
                            @edit_element="editChallenge"
                            @delete_element="openConfirmModal"
                            :challenge_categories="challenge_categories"
                            v-model="challenges"/>
                    </div>

                    <div class="in_center">
                        <select-type-challenge
                            :is_disabled="is_add_challenge_disabled"
                            :challenges_categories="challenge_categories"
                            @category_selected="categorySelected"
                            ref="selectTypeChallenge"/>
                    </div>
                </div>
            </div>

            <div class="card_body">
                <div class="in_center gp-20">
                    <button class="white_green_btn" @click="$router.push(`/admin/create-markers-list?quest_id=${quest_id}`)">
                        Cancel
                    </button>

                    <button
                        class="white_green_btn"
                        :disabled="is_disabled_save"
                        @click="saveAllChanges">
                        Save
                    </button>
                </div>
            </div>
        </div>

        <add-location-modal
            v-model="location"
            @confirm_actions="initLocation"
            ref="addLocationModal"/>

        <add-q-r-challenge-modal
            v-model="challenge"
            :marker_id="id"
            :quest_name="quest_name"
            :last_order="last_challenge_order"
            :category="selected_category"
            @close_modal="addChallengeClosed"
            ref="addQRChallengeModal"/>

        <add-photo-challenge-modal
            v-model="challenge"
            :marker_id="id"
            :last_order="last_challenge_order"
            :category="selected_category"
            @close_modal="addChallengeClosed"
            ref="addPhotoChallengeModal"/>

        <add-multi-choice-challenge-modal
            v-model="challenge"
            :marker_id="id"
            :last_order="last_challenge_order"
            :category="selected_category"
            @close_modal="addChallengeClosed"
            ref="addMultiChoiceChallengeModal"/>

        <add-question-challenge-modal
            v-model="challenge"
            :marker_id="id"
            :last_order="last_challenge_order"
            :category="selected_category"
            @close_modal="addChallengeClosed"
            ref="addQuestionChallengeModal"/>

        <add-review-challenge-modal
            v-model="challenge"
            :marker_id="id"
            :last_order="last_challenge_order"
            :category="selected_category"
            @close_modal="addChallengeClosed"
            ref="addReviewChallengeModal"/>

        <add-receipt-challenge-modal
            v-model="challenge"
            :marker_id="id"
            :last_order="last_challenge_order"
            :category="selected_category"
            @close_modal="addChallengeClosed"
            ref="addReceiptChallengeModal"/>

        <add-video-challenge-modal
            v-model="challenge"
            :marker_id="id"
            :last_order="last_challenge_order"
            :category="selected_category"
            @close_modal="addChallengeClosed"
            ref="addVideoChallengeModal"/>

        <add-social-media-challenge-modal
            v-model="challenge"
            :marker_id="id"
            :last_order="last_challenge_order"
            :category="selected_category"
            @close_modal="addChallengeClosed"
            ref="addSocialMediaChallengeModal"/>

        <add-blog-challenge-modal
            v-model="challenge"
            :marker_id="id"
            :last_order="last_challenge_order"
            :category="selected_category"
            @close_modal="addChallengeClosed"
            ref="addBlogChallengeModal"/>

        <confirm-modal
            ref="confirmDeleteModal"
            :param="confirm_modal_param"
            @confirm_actions="deleteChallenge"/>

    </create-quest-layouts>
</template>
<script>
import CreateQuestLayouts from "@/components/layouts/create_quests/CreateQuestLayouts.vue";
import UploadImage from "@/components/UI/images/UploadImage.vue";
import AddLocationModal from "@/components/modals/admin/AddLocationModal.vue";
import EditIcon from "@/components/svg/EditIcon.vue";
import AddChallengeModal from "@/components/modals/admin/AddChallengeModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import {
    baseErrHandler,
    createMarker, deleteChallengeById, deleteMarkerBadgeById, deleteMarkerIconById,
    getAllChallengesCategories, getAllDifficultyAndRewards,
    getChallengesByMarkerId,
    getLocationById,
    getMarkerById, getMarkerListIconById
} from "@/services/api";
import AddQRChallengeModal from "@/components/modals/admin/challenges/AddQRChallengeModal.vue";
import { challengeCategories } from "@/enums/ChallengeCategoriesEnum";
import SelectTypeChallenge from "@/components/admin/challenges/SelectTypeChallenge.vue";
import DraggableComponent from "@/components/UI/DraggableComponent.vue";
import AddPhotoChallengeModal from "@/components/modals/admin/challenges/AddPhotoChallengeModal.vue";
import AddMultiChoiceChallengeModal from "@/components/modals/admin/challenges/AddMultiChoiceChallengeModal.vue";
import AddQuestionChallengeModal from "@/components/modals/admin/challenges/AddQuestionChallengeModal.vue";
import AddReviewChallengeModal from "@/components/modals/admin/challenges/AddReviewChallengeModal.vue";
import AddReceiptChallengeModal from "@/components/modals/admin/challenges/AddReceiptChallengeModal.vue";
import AddVideoChallengeModal from "@/components/modals/admin/challenges/AddVideoChallengeModal.vue";
import AddSocialMediaChallengeModal from "@/components/modals/admin/challenges/AddSocialMediaChallengeModal.vue";
import InputTypeCheckbox from "@/components/UI/inputs/InputTypeCheckbox.vue";
import InputTypeText from "@/components/UI/inputs/InputTypeText.vue";
import InputTypeNumber from "@/components/UI/inputs/InputTypeNumber.vue";
import InputTypeRange from "@/components/UI/inputs/InputTypeRange.vue";
import SwitchElem from "@/components/UI/SwitchElem.vue";
import QRCodeGenerate from "@/components/admin/challenges/QRCodeGenerate.vue";
import DifficultyTypeRangeImit from "@/components/UI/imitations/DifficultyTypeRangeImit.vue";
import AddBlogChallengeModal from "@/components/modals/admin/challenges/AddBlogChallengeModal.vue";
import InputTypeDecimal from "@/components/UI/inputs/InputTypeDecimal.vue";
import QuestBlogInf from "@/components/admin/quest/QuestBlogInf.vue";
export default {
    name: 'AddMarker',
    components: {
        QuestBlogInf,
        InputTypeDecimal,
        AddBlogChallengeModal,
        DifficultyTypeRangeImit,
        QRCodeGenerate,
        SwitchElem,
        InputTypeRange,
        InputTypeNumber,
        InputTypeText,
        InputTypeCheckbox,
        AddSocialMediaChallengeModal,
        AddVideoChallengeModal,
        AddReceiptChallengeModal,
        AddReviewChallengeModal,
        AddQuestionChallengeModal,
        AddMultiChoiceChallengeModal,
        AddPhotoChallengeModal,
        SelectTypeChallenge,
        CreateQuestLayouts,
        UploadImage,
        AddLocationModal,
        EditIcon,
        AddChallengeModal,
        AddQRChallengeModal,
        DraggableComponent,
        ConfirmModal
    },
    props: {
        active_item: {
            type: String,
            default: '',
        },
        active_tab: {
            type: String,
            default: 'Info',
        },
        quest_id: {
            type: Number,
            default: null,
        },
        quest_name: {
            type: String,
            default: '',
        },
        last_order: {
            type: Number,
            default: 0,
        },
        trailblazer_id: {
            type: [String, Number],
            default: '',
        }
    },
    methods: {
        async openAddLocation() {
            await this.createMarker();

            if (!this.location_id) {
                this.location = null;
            }

            if (this.id) {
                await this.$refs.addLocationModal.openModal();
            }
        },
        async deleteMarkerIconInBase() {
            if (!this.id) {
                return;
            }
            await baseErrHandler(async () => {
                const { success } = await deleteMarkerIconById(this.id);

                if (!success) {
                    return;
                }

                if (this.marker_list_icon) {
                    this.default_marker = this.marker_list_icon;
                } else {
                    this.default_marker = null;
                }
            });
        },
        selectedMarkerIcon(image) {
            this.default_marker = image;
        },
        selectedMarkerBadge(image) {
            this.badge = image;
        },
        async deleteMarkerBadge() {
            if (!this.id) {
                return;
            }

            await baseErrHandler(async () => {
                const {success} = await deleteMarkerBadgeById(this.id);

                if (!success) {
                    return;
                }

                this.badge = null;
            });
        },
        async getMarkerInf(marker_id) {
            await baseErrHandler(async () => {
                const { marker } = await getMarkerById(marker_id);
                if (marker) {
                    await this.markerDataInit(marker);
                }
            });
        },
        async initLocation(location) {
            this.location = location;

            await this.createMarker();
        },
        async saveAllChanges() {
            await this.createMarker(true);
            await this.$router.push({
                path: `/admin/create-markers-list`,
                query: {
                    quest_name: this.quest_name,
                    quest_id: this.quest_id,
                    markers_list_id: this.markers_list_id,
                    trailblazer_id: this.trailblazer_id,
                }
            });
        },
        async createMarker(is_completed = false) {
            await baseErrHandler(async () => {
                const data = {
                    id: this.id ? this.id : null,
                    list_id: this.markers_list_id,
                    location_id: this.location_id,
                    order: this.bonus ? null : (this.order ? this.order : 1),
                    icon: this.default_marker,
                    name: this.name,
                    subtitle: this.subtitle,
                    description: this.description,
                    range: this.range > 0 && !this.is_qr ? this.range : 0,
                    reward: this.reward > 0 ? this.reward : null,
                    difficulty_rewards_id: this.difficulty_rewards_id,
                    is_badge: this.is_badge,
                    badge: this.is_badge ? this.badge : null,
                    badge_title: this.is_badge ? this.badge_title : null,
                    in_sequence: this.in_sequence,
                    is_default_icon: !this.default_marker,
                    create_completed: is_completed,
                    bonus: this.bonus,
                    purchased_price: this.purchased_price,
                    is_qr: this.is_qr,
                    qr_code: this.is_qr ? this.qr_code : null,
                    challenges: null,
                };

                if (this.challenges?.length) {
                    let count = 1;
                    for (let [index, val] of this.challenges.entries()) {
                        if (!val.bonus) {
                            val.order = count;
                            count++;
                        } else {
                            val.order = null;
                        }
                    }

                    data.challenges = this.challenges;
                }


                const { marker } = await createMarker(data);

                await this.markerDataInit(marker);
            });
        },
        async markerDataInit(marker) {
            if (marker) {
                this.id = marker.id ? marker.id : null;
                this.list_id = marker.list_id;
                this.order = marker.bonus ? null : (marker.order ? marker.order : this.last_order + 1);
                this.default_marker = marker.icon;
                this.name = marker.name;
                this.subtitle = marker.subtitle;
                this.description = marker.description;
                this.range = marker.range ? marker.range : 0;
                this.reward = marker.reward ? marker.reward : 0;
                this.points = marker.points ? marker.points : 0;
                this.difficulty = marker.difficulty ? marker.difficulty : '';
                this.difficulty_rewards_id = marker.difficulty_rewards_id;
                this.is_badge = marker.is_badge;
                this.badge = marker.badge;
                this.badge_title = marker.badge_title;
                this.in_sequence = marker.in_sequence;
                this.is_default_icon = marker.is_default_icon;
                this.create_completed = marker.create_completed;
                this.bonus = marker.bonus;
                this.purchased_price = marker.bonus ? marker.purchased_price : null;
                this.is_qr = marker.is_qr;
                this.qr_code = marker.qr_code;
                this.view_qr = Boolean(marker.is_qr && marker.qr_code);
            }

            if (marker?.location) {
                this.location = marker.location;
            }

            if (marker?.id) {
                this.addParamToUrl('marker_id', marker.id);
            }
        },
        async locationGetInf(location_id) {
            await baseErrHandler( async () => {
                const { location } = await getLocationById(location_id);
                this.location = location;
            });
        },
        addParamToUrl(key, param) {
            const url = new URL(window.location.href);
            url.searchParams.set(key, param);
            window.history.replaceState(null, null, url);
        },
        async getMarkerChallenges() {
            await baseErrHandler(async () => {
                const { challenges } = await getChallengesByMarkerId(this.id);
                if (challenges) {
                    this.challenges = challenges;
                }
            });
        },
        async getCategories() {
            await baseErrHandler(async () => {
                const { challenge_categories } = await getAllChallengesCategories();

                if (challenge_categories) {
                    this.challenge_categories = challenge_categories;
                } else {
                    this.challenge_categories = [];
                }
            });
        },
        async getMarkersListIcon(marker_list_id) {
            await baseErrHandler(async () => {
                const { path } = await getMarkerListIconById(marker_list_id);

                if (!path) {
                    return;
                }
                this.marker_list_icon = path;
            });
        },
        async categorySelected(category) {
            this.$refs.selectTypeChallenge.closeSelected();

            await this.createMarker();

            const ref = this.whotOpenCategoryRef(category);

            this.last_challenge_order = this.getLastOrder();

            if (ref) {
                this.$refs[ref].openModal();
            }
        },
        whotOpenCategoryRef(category) {
            let ref;
            for (const item of challengeCategories) {
                if (item.title === category.title) {
                    this.selected_category = category;
                    ref = item.refs;
                    break;
                }
            }
            return ref;
        },
        categoryById (category_id) {
            if (!this.challenge_categories.length) {
                return {};
            }

            for (const category of this.challenge_categories) {
                if (category.id === category_id) {
                    return category;
                }
            }
        },
        async addChallengeClosed(item) {
            this.selected_category = {};

            if (item) {
                await this.getMarkerChallenges();
            }
            this.challenge = null;
        },
        editChallenge(challenge) {
            const category = this.categoryById(challenge.challenge_category_id);
            this.selected_category = category;
            this.challenge = challenge;
            this.categorySelected(category);
        },
        openConfirmModal(param) {
            this.confirm_modal_param = param;
            this.$refs.confirmDeleteModal.openModal();
        },
        async deleteChallenge(challenge_id) {
            await baseErrHandler(async () => {
                const { success } = await deleteChallengeById(challenge_id);
                if (!success) {
                    return;
                }
            });
            this.challenges = this.challenges.filter((el) => el.id !== challenge_id);
        },
        getLastOrder() {
            let last_order = 0;
            if (this.challenges?.length) {
                for (const item of this.challenges) {
                    if (!item.bonus) {
                        last_order++;
                    }
                }
            }
            return last_order;
        },
    },
    data() {
        return {
            test: null,

            difficulty_rewards: [],
            last_challenge_order: 0,
            confirm_modal_param: null,
            id: null,
            default_marker: null,

            marker_list_icon: null,

            list_id: this.markers_list_id,
            order: this.last_order + 1,
            icon: null,
            name: '',
            subtitle: '',
            description: '',
            range: 0,
            reward: 0,
            points: 0,
            difficulty: '',
            difficulty_rewards_id: null,
            is_badge: false,
            badge: null,
            badge_title: '',
            in_sequence: false,
            is_default_icon: false,
            create_completed: false,
            bonus: false,
            purchased_price: null,
            is_qr: false,
            qr_code: '',
            view_qr: false,

            location: null,

            errors: {
                name: false,
                subtitle: false,
            },

            challenge_categories: [],
            selected_category: {},

            challenges: [],
            challenge: null,
        };
    },
    async beforeMount() {
        await this.getCategories();

        if (this.$route?.query?.is_default_icon) {
            this.is_default_icon = true;
            await this.getMarkersListIcon(this.markers_list_id);
        }
        if (this.$route?.query?.marker_id) {
            await this.getMarkerInf(this.$route.query.marker_id);
            await this.getMarkerChallenges();
        }
    },
    computed: {
        markers_list_id() {
            if (this.$route?.query?.markers_list_id) {
                return this.$route.query.markers_list_id;
            }
            return null;
        },
        is_disabled_save() {
            return !this.name ||
                !this.subtitle ||
                !this.description ||
                !this.location_id ||
                !this.range_test ||
                !this.reward ||
                !this.qr_test ||
                !this.difficulty_rewards_id ||
                !this.is_bonus;
        },
        location_id() {
            if (this.location) {
                return this.location.id;
            }
            return null;
        },
        is_disable_add_location() {
            return false;
        },
        is_add_challenge_disabled() {
            return false;
        },
        is_bonus() {
            if (!this.bonus && this.purchased_price) {
                return false;
            }
            if (this.bonus && !this.purchased_price) {
                return false;
            }
            return true;
        },
        qr_test() {
            if (this.is_qr && !this.qr_code) {
                return false;
            }
            return true;
        },
        range_test() {
            if (!this.is_qr && !this.range) {
                return false;
            }
            return true;
        }
    },
    watch: {
        trailblazer_id(val) {
            if (val) {
                this.addParamToUrl('trailblazer_id', val);
            }
        },
        is_qr(val) {
            if (val) {
                this.range = 0;
            } else {
                this.qr_code = '';
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.centered_body {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.in_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.marker_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.card_body {
    width: 675px;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.card_title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: #214B41;
}

.item_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}

.item_in_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sub_item_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.sub_item {
    width: 327px;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.border-red-cast {
    border-color: #DD1760;;
}

.gp-20 {
    gap: 20px;
}

.checked_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    input[type='checkbox'] {
        outline: none !important;
        color: #61CC2C;
    }
}

.label_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}

.parent_range {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.range_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.range_label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.percent_view {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: right;

    color: #000000;
}

.w_range {
    width: 611px;
    display: flex;
    align-items: center;

    .range_line {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 3px;
        background: #CCEAFF;
        top: calc(50% - 3px/2);
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            background: #61CC2C;
        }
    }

    .error_line {
        background: #DD1760;
    }
}

.image_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_div {
    width: 300px;
    height: 300px;

    img {
        width: 100%;
        height: 100%;
    }
}

.default_image_div {
    width: 150px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
    }
}

.blog_challenge_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .item_in_row {
        width: 327px;
    }
}

.location_info_body {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px 20px 20px;

    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
}

.location_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.location_info_address {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    text-align: left;

    color: #214B41;
}

.location_info_cord {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;

    text-align: left;

    color: #364652;

}

.switch_qr_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
</style>
