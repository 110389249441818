import {checkCurrentUser} from "@/helpers/auth.helper";
import { createRouter, createWebHistory } from 'vue-router';
import AdminLogIn from '@/pages/AdminLogIn.vue';
import Welcome from "@/pages/Welcome.vue";
import MobileEmit from '@/pages/MobileEmit';
import HashExpired from "@/components/mobile/password/HashExpired.vue";
import SetNewPassword from "@/components/mobile/password/SetNewPassword.vue";
import CongratulationSetPassword from "@/components/mobile/password/CongratulationSetPassword.vue";
import ForgotPassword from "@/pages/ForgotPassword.vue";
import ActiveQuests from "@/pages/admin/ActiveQuests.vue";
import AwaitingQuests from "@/pages/admin/AwaitingQuests.vue";
import EndedQuests from "@/pages/admin/EndedQuests.vue";
import ReportsAdmin from "@/pages/admin/ReportsAdmin.vue";
import CreateQuestInfo from "@/pages/admin/quests/CreateQuestInfo.vue";
import CreateMarkersList from "@/pages/admin/quests/CreateMarkersList.vue";
import AddBadges from "@/pages/admin/quests/AddBadges.vue";
import AddMarker from "@/pages/admin/markers/AddMarker.vue";
import ChallengesAdmin from "@/pages/admin/ChallengesAdmin.vue";
import ChallengesToReview from "@/pages/admin/challenges/ChallengesToReview.vue";
import UsersAdmin from "@/pages/admin/UsersAdmin.vue";
import VariablesAdmin from "@/pages/admin/VariablesAdmin.vue";
import NewsAdmin from "@/pages/admin/NewsAdmin.vue";
import UsersDetailAdmin from "@/pages/admin/users/UsersDetailAdmin.vue";
import AddNews from "@/pages/admin/news/AddNews.vue";
import RevisionNews from "@/pages/admin/news/RevisionNews.vue";
import MaintenanceMod from "@/pages/MaintenanceMod.vue";
import RevisionQuestInfo from "@/pages/admin/quests/RevisionQuestInfo.vue";
import RevisionMarkerList from "@/pages/admin/quests/RevisionMarkerList.vue";
import RevisionBadges from "@/pages/admin/quests/RevisionBadges.vue";
import RejectedQuestAdmin from "@/pages/admin/RejectedQuestAdmin.vue";
import TrailblazersList from "@/pages/admin/trailblazer/TrailblazersList.vue";
import RevisionTrailblazerPlaces from "@/pages/admin/trailblazer/RevisionTrailblazerPlaces.vue";

import {
    getId,
    getMarkersListId, getParentRout,
    getQuestId,
    getQuestName,
    getTrailblazerId,
    getUserId, isCreate, withCopy, withIsComplete,
} from "@/helpers/route.helper";
import {baseErrHandler, isMaintenance} from "@/services/api";

const routes = [
    {
        path: '/',
        name: 'Welcome',
        meta: {
            requiresAuth: false,
        },
        component: Welcome,
    },
    {
        path: '/1001',
        name: 'MaintenanceMod',
        meta: {
            requiresAuth: false,
        },
        component: MaintenanceMod,
    },
    {
        path: '/mobile-emit',
        name: 'MobileEmit',
        meta: {
            requiresAuth: false,
        },
        component: MobileEmit,
        children: [
            {
                path: 'link-has-expired',
                name: 'HashExpired',
                meta: {
                    requiresAuth: false,
                },
                component: HashExpired,
            },
            {
                path: 'set-new-password',
                name: 'SetNewPassword',
                meta: {
                    requiresAuth: false,
                },
                component: SetNewPassword,
            },
            {
                path: 'congratulation-set-password',
                name: 'CongratulationSetPassword',
                meta: {
                    requiresAuth: false,
                },
                component: CongratulationSetPassword,
            },
        ]
    },
    {
        path: '/admin',
        name: 'AdminLogIn',
        meta: {
            requiresAuth: false,
            roles: ['admin'],
        },
        component: AdminLogIn,
    },
    {
        path: '/admin/forgot-password',
        name: 'ForgotPassword',
        meta: {
            requiresAuth: false,
            roles: ['admin'],
        },
        component: ForgotPassword,
    },
    {
        path: '/admin/active-quests',
        name: 'ActiveQuests',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return {
                active_item: 'quests',
            };
        },
        component: ActiveQuests,
    },
    {
        path: '/admin/awaiting-quests',
        name: 'AwaitingQuests',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return { active_item: 'quests' };
        },
        component: AwaitingQuests,
    },
    {
        path: '/admin/rejected-quests',
        name: 'RejectedQuestAdmin',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return { active_item: 'quests' };
        },
        component: RejectedQuestAdmin,
    },
    {
        path: '/admin/ended-quests',
        name: 'EndedQuests',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return {
                active_item: 'quests',
            };
        },
        component: EndedQuests,
    },
    {
        path: '/admin/trailblazers',
        name: 'TrailblazersList',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return {
                active_item: 'trailblazers',
            };
        },
        component: TrailblazersList,
    },
    {
        path: '/admin/revision-trailblazer/:trailblazer_id',
        name: 'RevisionTrailblazerPlaces',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const trailblazer_id = getTrailblazerId(route);

            return {
                active_item: 'trailblazers',
                trailblazer_id
            };
        },
        component: RevisionTrailblazerPlaces,
    },
    {
        path: '/admin/users',
        name: 'UsersAdmin',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return { active_item: 'users' };
        },
        component: UsersAdmin,
    },
    {
        path: '/admin/users-detail-admin/:user_id',
        name: 'UsersDetailAdmin',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const user_id = getUserId(route);

            return {
                active_item: 'users',
                user_id
            };
        },
        component: UsersDetailAdmin,
    },
    {
        path: '/admin/challenges',
        name: 'ChallengesAdmin',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return { active_item: 'challenges' };
        },
        component: ChallengesAdmin,
    },
    {
        path: '/admin/reports',
        name: 'ReportsAdmin',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return { active_item: 'reports' };
        },
        component: ReportsAdmin,
    },
    {
        path: '/admin/variables',
        name: 'VariablesAdmin',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return { active_item: 'variables' };
        },
        component: VariablesAdmin,
    },
    {
        path: '/admin/news',
        name: 'NewsAdmin',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return { active_item: 'news' };
        },
        component: NewsAdmin,
    },
    {
        path: '/admin/add-news',
        name: 'AddNewsAdmin',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: () => {
            return { active_item: 'news' };
        },
        component: AddNews,
    },
    {
        path: '/admin/edit-news/:id',
        name: 'EditNewsAdmin',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const id = getId(route);
            return {
                active_item: 'news',
                id
            };
        },
        component: AddNews,
    },
    {
        path: '/admin/revision-news/:id',
        name: 'RevisionNews',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const id = getId(route);
            return {
                active_item: 'news',
                id
            };
        },
        component: RevisionNews,
    },
    {
        path: '/admin/quest-create',
        name: 'CreateQuest',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const quest_id = getQuestId(route);
            const is_create = isCreate(route);
            const parent_rout_name = getParentRout(route);

            return {
                active_item: 'quests',
                active_tab: 'Info',
                quest_id,
                is_create,
                parent_rout_name,
            };
        },
        component: CreateQuestInfo,
    },
    {
        path: '/admin/quest-copied',
        name: 'CopiedQuest',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const quest_id = getQuestId(route);
            const is_create = isCreate(route);
            const parent_rout_name = getParentRout(route);

            return {
                active_item: 'quests',
                active_tab: 'Info',
                quest_id,
                is_create,
                parent_rout_name,
            };
        },
        component: CreateQuestInfo,
    },
    {
        path: '/admin/quest-revision',
        name: 'RevisionQuestInfo',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const quest_id = getQuestId(route);
            const with_copy = withCopy(route);
            const is_complete = withIsComplete(route);
            const parent_rout_name = getParentRout(route);

            return {
                active_item: 'quests',
                active_tab: 'Info',
                quest_id,
                with_copy,
                is_complete,
                parent_rout_name,
            };
        },
        component: RevisionQuestInfo,
    },
    {
        path: '/admin/create-markers-list',
        name: 'CreateMarkersList',
        mata: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const quest_id = getQuestId(route);
            const quest_name = getQuestName(route);
            const trailblazer_id = getTrailblazerId(route);
            const is_create = isCreate(route);
            const parent_rout_name = getParentRout(route);

            return {
                active_item: 'quests',
                active_tab: 'Markers',
                quest_id,
                quest_name,
                trailblazer_id,
                is_create,
                parent_rout_name,
            };
        },
        component: CreateMarkersList,
    },
    {
        path: '/admin/revision-markers-list',
        name: 'RevisionMarkerList',
        mata: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const quest_id = getQuestId(route);
            const quest_name = getQuestName(route);
            const trailblazer_id = getTrailblazerId(route);
            const with_copy = withCopy(route);

            return {
                active_item: 'quests',
                active_tab: 'Markers',
                quest_id,
                quest_name,
                trailblazer_id,
                with_copy,
            };
        },
        component: RevisionMarkerList,
    },
    {
        path: '/admin/create-badges',
        name: 'AddBadges',
        mata: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const quest_id = getQuestId(route);
            const quest_name = getQuestName(route);
            const trailblazer_id = getTrailblazerId(route);
            const is_create = isCreate(route);
            const parent_rout_name = getParentRout(route);

            return {
                active_item: 'quests',
                active_tab: 'Badges',
                quest_id,
                trailblazer_id,
                quest_name,
                is_create,
                parent_rout_name,
            };
        },
        component: AddBadges,
    },
    {
        path: '/admin/revision-badges',
        name: 'RevisionBadges',
        mata: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const quest_id = getQuestId(route);
            const quest_name = getQuestName(route);
            const trailblazer_id = getTrailblazerId(route);
            const with_copy = withCopy(route);

            return {
                active_item: 'quests',
                active_tab: 'Badges',
                quest_id,
                quest_name,
                trailblazer_id,
                with_copy,
            };
        },
        component: RevisionBadges,
    },
    {
        path: '/admin/add_marker',
        name: 'AddMarker',
        mata: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const quest_id = getQuestId(route);
            const markers_lists_id = getMarkersListId(route);
            const quest_name = getQuestName(route);
            const trailblazer_id = getTrailblazerId(route);

            return {
                active_item: 'quests',
                active_tab: 'Markers',
                quest_id,
                markers_lists_id,
                quest_name,
                trailblazer_id,
            };
        },
        component: AddMarker,
    },
    {
        path: '/admin/challenges-to-review',
        name: 'ChallengesToReview',
        meta: {
            requiresAuth: true,
            roles: ['admin'],
        },
        props: (route) => {
            const quest_id = getQuestId(route);

            return {
                active_item: 'challenges',
                quest_id,
            };
        },
        component: ChallengesToReview,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active-link',
    routes,
});

const is_maintenance = async () => {
    await baseErrHandler(async () => {
        const { is_maintenance } = await isMaintenance();
        return is_maintenance;
    });
};

router.beforeEach(async (to, from, next) => {
    const user = checkCurrentUser();
    const { role } = user || {};
    const maintenance = await is_maintenance();

    if (
        maintenance &&
        to.matched.some(record => record.name !== 'AdminLogIn') &&
        to.matched.some(record => record.name !== 'MaintenanceMod') &&
        role !== 'admin'
    ) {
        let path = '/1001';

        return next({
            path,
            params: { nextUrl: to.fullPath },
        });
    }

    if (
        !maintenance &&
        to.matched.some(record => record.name === 'MaintenanceMod')
    ) {
        let path = '/';

        return next({
            path,
            params: { nextUrl: to.fullPath },
        });
    }

    if (
        !to.matched.some(record => record.meta.requiresAuth) &&
        to.matched.some(record => record.name !== 'AdminLogIn')
    ) {
        return next();
    }

    // const user = checkCurrentUser();
    // const { role } = user || {};

    const { meta } = to;
    const { roles } = meta || {};

    let path;

    if (to.matched.some(record => record.name === 'AdminLogIn')) {
        if (!user || user?.role !== 'admin') {
            return next();
        }

        path = '/admin/active-quests'

        return next({
            path,
            params: { nextUrl: to.fullPath },
        });
    }

    if (!roles.includes(role)) {
        path = '/admin';

        return next({
            path,
            params: { nextUrl: to.fullPath },
        });
    }

    next();
});

export default router;
