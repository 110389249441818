<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 4C3.67157 4 3 4.67157 3 5.5V18.5C3 19.3284 3.67157 20 4.5 20H6.75C7.57843 20 8.25 20.6716 8.25 21.5C8.25 22.3284 7.57843 23 6.75 23H4.5C2.01472 23 0 20.9853 0 18.5V5.5C0 3.01472 2.01472 1 4.5 1H6.75C7.57843 1 8.25 1.67157 8.25 2.5C8.25 3.32843 7.57843 4 6.75 4H4.5ZM15.75 2.5C15.75 1.67157 16.4216 1 17.25 1H19.5C21.9853 1 24 3.01472 24 5.5V18.5C24 20.9853 21.9853 23 19.5 23H17.25C16.4216 23 15.75 22.3284 15.75 21.5C15.75 20.6716 16.4216 20 17.25 20H19.5C20.3284 20 21 19.3284 21 18.5V5.5C21 4.67157 20.3284 4 19.5 4H17.25C16.4216 4 15.75 3.32843 15.75 2.5ZM7.43934 7.43934C8.02513 6.85355 8.97487 6.85355 9.56066 7.43934L12 9.87868L14.4393 7.43934C15.0251 6.85355 15.9749 6.85355 16.5607 7.43934C17.1464 8.02513 17.1464 8.97487 16.5607 9.56066L14.1213 12L16.5607 14.4393C17.1464 15.0251 17.1464 15.9749 16.5607 16.5607C15.9749 17.1464 15.0251 17.1464 14.4393 16.5607L12 14.1213L9.56066 16.5607C8.97487 17.1464 8.02513 17.1464 7.43934 16.5607C6.85355 15.9749 6.85355 15.0251 7.43934 14.4393L9.87868 12L7.43934 9.56066C6.85355 8.97487 6.85355 8.02513 7.43934 7.43934Z" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'VariablesIcon'
}
</script>
