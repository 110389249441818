<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-reports :title="'Users'"/>

            <table-template
                v-if="table_view"
                v-model:search="requestData.search"
                v-model:perPage="requestData.perPage"
                v-model:page="requestData.page"
                v-model:sort="requestData.sort"
                :clicked_row="true"
                @clicked_row="openUserInf"
                :columns="columns"
                :data="users"
                :total_count="total_count"/>

            <empty-users-data
                v-if="!users.length"
                :message="!table_view ? 'No users yet' : 'Sorry, nothing found.'"/>
        </div>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import EmptyUsersData from "@/components/admin/EmptyUsersData.vue";
import FirstRowReports from "@/components/admin/FirstRowReports.vue";
import {baseErrHandler, getUsersList} from "@/services/api";
import TableTemplate from "@/components/TableTemplate.vue";

export default {
    name: 'UsersAdmin',
    props: {
        active_item: {
            type: String,
            default: '',
        }
    },
    components: {
        TableTemplate,
        FirstRowReports,
        EmptyUsersData,
        AdminLayouts,
    },
    data() {
        return {
            users: [],
            columns: [
                {
                    title: 'Full name',
                    field: 'full_name',
                    width: 'w-4/12',
                    sort: true,
                },
                {
                    title: 'Email',
                    width: 'w-4/12',
                    field: 'email',
                    sort: true,
                },
                {
                    title: 'Xcoins',
                    width: 'w-2/12',
                    field: 'xcoins',
                    sort: true,
                },
                {
                    title: 'Points',
                    width: 'w-2/12',
                    field: 'points',
                    sort: true,
                },
            ],
            requestData: {
                with_admin: false,
                sort: {
                    type: 'desc',
                    field: 'registration_time',
                },
                page: 1,
                perPage: 10,
                search: '',
                active: 1,
            },
            total_count: 0,
        };
    },
    async beforeMount() {
        await  this.getUsers();
    },
    methods: {
        async getUsers() {
            await baseErrHandler(async () => {
                const { list, total_count } = await getUsersList(this.requestData);

                this.total_count = total_count;
                this.users = list;
            });
        },
        async openUserInf(row) {
            await this.$router.push({
                name: 'UsersDetailAdmin',
                params: {
                    user_id: row.id,
                }
            });
        },
    },
    watch: {
        ['requestData.page']() {
            this.getUsers();
        },
        ['requestData.perPage']() {
            this.requestData.page = 1;
            this.getUsers();
        },
        ['requestData.sort']() {
            this.requestData.page = 1;
            this.getUsers();
        },
        ['requestData.search']() {
            this.requestData.page = 1;
            this.getUsers();
        }
    },
    computed: {
        table_view() {
            if (!this.users.length && !this.requestData.search) {
                return false;
            }
            return true;
        }
    },
}
</script>
