<template>
    <div class="w-full min-h-screen relative">
        <admin-header/>
        <div class="admin_body_content">
            <admin-sidebar :active_item="active_item"/>

            <slot></slot>
        </div>
    </div>
</template>
<script>
import AdminHeader from "@/components/admin/AdminHeader.vue";
import AdminSidebar from "@/components/admin/AdminSidebar.vue";
export default {
    name: 'AdminLayouts',
    props: {
        active_item: {
            type: String,
            default: '',
        }
    },
    components: {
        AdminHeader,
        AdminSidebar,
    },
}
</script>
<style lang="scss" scoped>
.admin_body_content {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 75px);
}
</style>
