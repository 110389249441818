<template>
    <div class="relative w-full">
        <div class="relative w-full z-0">
            <input
                @click="clickSearch"
                type="text"
                name="quest-name"
                placeholder=" "
                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast dropdown_item"
                :value="search ? search : (timezone?.description ? `(GMT${timezone.gmt}) ${timezone.description}` : null)"
                @keyup="searchInZones($event.target.value)"
                v-bind="timezone"
            />
            <label for="quest-name" class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title">{{title}}</label>
        </div>

        <div class="icon_triang">
            <green-triang-icon/>
        </div>

        <div class="dropdown_body" v-if="is_open_dropdown">
            <div
                v-for="zone in view_zones"
                :key="zone.id"
                @click.stop="selectedItem(zone)"
                class="dropdown_item dropdown_item_hovered" :class="{selected: zone.id === selected_id}">
                (GMT{{zone.gmt}}) {{zone.description}}
            </div>
        </div>
    </div>
</template>
<script>
import GreenTriangIcon from "@/components/svg/GreenTriangIcon.vue";
import {allTimezones, baseErrHandler} from "@/services/api";
export default {
    name: 'TimezoneSelect',
    components: {
        GreenTriangIcon,
    },
    props: {
        timezones: {
            type: Array,
            default() {
                return [];
            },
        },
        title: {
            type: String,
            default: '',
        },
        timezone_pre_search: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: {
        selected_zone: null,
    },
    data() {
        return {
            value_view: null,
            search: '',
            is_open_dropdown: false,
            selected_id: null,
        };
    },
    unmounted() {
        window.removeEventListener('click', this.outsideClick);
    },
    mounted() {
        window.addEventListener('click', this.outsideClick);
    },

    methods: {
        clickSearch() {
            this.search = '';
            this.is_open_dropdown = true;
        },
        searchInZones(value) {
            console.log(value);
            this.search = value;
        },
        selectedItem(zone) {
            this.search = '';
            this.is_open_dropdown = false;
            this.$emit('selected_zone', zone);
        },
        outsideClick(e) {
            if (!this.$el.contains(e.target)) {
                this.search = '';
                this.is_open_dropdown = false;
            }
        },
        preSearchTimezone() {
            if (this.timezones && this.timezones.length) {
                for (const elem of this.timezones) {
                    if (elem.gmt === this.timezoneOffset) {
                        return elem;
                    }
                }
            }
        },
    },
    computed: {
        view_zones() {
            if (!this.timezones) {
                return [];
            }
            if (this.search.length) {
                return this.timezones.filter((el) => {
                    if (el.gmt.includes(this.search) || el.description.includes(this.search)) {
                        return true;
                    }
                });
            }
            return this.timezones;
        },
        timezone() {
            if (this.timezone_pre_search) {
                return this.timezone_pre_search;
            }

            return this.preSearchTimezone();
        }
    },
}
</script>
<style lang="scss" scoped>
.border-blue-cast {
    border-color: #599FD2;;
}

.icon_triang {
    background: #FFFFFF;
    width: 12px;
    height: 12px;
    color: #61CC2C;
    position: absolute;
    right: 0;
    bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown_body {
    position: absolute;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 6px;

    max-height: 250px;
    overflow-y: scroll;

    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 4px;
}

.dropdown_item {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}

.dropdown_item_hovered {

    &:hover {
        background: #73a5ff;
    }
}

.dropdown_item .selected {
    background: #006CBA;
}
</style>
