<template>
    <div
        class="switch__body"
        @click="setValue"
        :class="{
            no_active: !modelValue,
            active: modelValue,
            is_disabled: is_disabled
        }">
        <div
            class="toggles"
            :class="{
                no_active: !modelValue,
                active: modelValue
            }"></div>
    </div>
</template>
<script>
export default {
    name: 'SwitchElem',
    props: {
        title: {
            type: String,
            default: '',
        },
        modelValue: {
            type: Boolean,
            required: true,
        },
        is_disabled: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'update:modelValue': null,
        'change_value': null,
    },
    methods: {
        setValue() {
            if (!this.is_disabled) {
                this.$emit('update:modelValue', !this.modelValue)
                this.$emit('change_value')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.switch__body {
    min-width: 42px;
    max-width: 42px;
    height: 24px;
    padding: 0 2px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 17px;
    cursor: pointer;
}

.active {
    justify-content: flex-end;
    background: #61CC2C;
}

.no_active {
    justify-content: flex-start;
    background: #CCEAFF;
}

.toggles {
    background: #FFFFFF;
    width: 18px;
    height: 18px;
    border-radius: 100%;
}

.is_disabled {
    background: #4a5568;
}
</style>
