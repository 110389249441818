<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 6.00001H6.33682C6.89856 7.18248 8.10381 8 9.5 8H14.5C15.8962 8 17.1014 7.18248 17.6632 6.00001H18.5C18.7761 6.00001 19 6.22387 19 6.50001V20.5C19 20.7761 18.7761 21 18.5 21H5.5C5.22386 21 5 20.7761 5 20.5V6.50001C5 6.22387 5.22386 6.00001 5.5 6.00001ZM18.5 3.00001H17.9646C17.7219 1.30386 16.2632 0 14.5 0H9.5C7.73676 0 6.27805 1.30386 6.03544 3.00001H5.5C3.567 3.00001 2 4.56702 2 6.50001V20.5C2 22.433 3.567 24 5.5 24H18.5C20.433 24 22 22.433 22 20.5V6.50001C22 4.56701 20.433 3.00001 18.5 3.00001ZM9.5 3C9.22386 3 9 3.22386 9 3.5V4.5C9 4.77614 9.22386 5 9.5 5H14.5C14.7761 5 15 4.77614 15 4.5V3.5C15 3.22386 14.7761 3 14.5 3H9.5ZM16.5607 13.5607C17.1464 12.9749 17.1464 12.0251 16.5607 11.4393C15.9749 10.8536 15.0251 10.8536 14.4393 11.4393L11 14.8787L9.56066 13.4393C8.97487 12.8536 8.02513 12.8536 7.43934 13.4393C6.85355 14.0251 6.85355 14.9749 7.43934 15.5607L9.23223 17.3536C10.2085 18.3299 11.7915 18.3299 12.7678 17.3536L16.5607 13.5607Z" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'QuestsMenuIcon'
}
</script>
