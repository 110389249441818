<template>
    <div class="admin_header">
        <div class="logo_parent">
            <img src="../../assets/img/logo.png" alt="logo">
        </div>

        <div class="left_button_group">
            <button
                class="maintenance_toggle"
                :class="{in_mode: is_maintenance_mod}"
                @click="showModal(false)">
                <power-button-icon/>
            </button>
            <button class="log_aut" @click="showModal(true)">
                <log-out-icon/>
            </button>
        </div>

        <message-modal
            ref="confirmModal"
            :is_cancel_button="true"
            :title="is_logout ? 'Log out' : (is_maintenance_mod ? 'Turn off Maintenance' : 'Set to Maintenance')"
            :message="message"
            @confirm_actions="confirmModal"/>
    </div>
</template>
<script>
import MessageModal from "@/components/modals/MessageModal.vue";
import LogOutIcon from "@/components/svg/LogOutIcon.vue";
import {mapMutations} from "vuex";
import PowerButtonIcon from "@/components/svg/PowerButtonIcon.vue";
import {baseErrHandler, isMaintenance, setMaintenance} from "@/services/api";
export default {
    name: 'AdminHeader',
    components: {
        PowerButtonIcon,
        LogOutIcon,
        MessageModal,
    },
    data() {
        return {
            message: '',
            is_logout: true,
            is_maintenance_mod: false,
        };
    },
    methods: {
        ...mapMutations([
            'login',
        ]),
        async logOut() {
            this.login({});

            await this.$router.push({name: 'AdminLogIn'});
        },
        async toggleMaintenance() {
            await baseErrHandler(async () => {
                const { success } = await setMaintenance();
                if (success) {
                    this.is_maintenance_mod = !this.is_maintenance_mod;
                }
            });
        },
        async confirmModal() {
            if (this.is_logout) {
                await this.logOut();
            } else {
                await this.toggleMaintenance();
            }
        },
        showModal(is_logout) {
            if (is_logout) {
                this.is_logout = true;
                this.message = 'Are you sure you want to log out?';
            } else {
                this.is_logout = false;
                this.message = `Are you sure you want to ${this.is_maintenance_mod ? 'turn off' : 'turn'} on Maintenance mode?`;
            }
            this.$refs.confirmModal.openModal();
        },
        async checkIsMaintenance() {
            await baseErrHandler(async () => {
                const { is_maintenance } = await isMaintenance();
                this.is_maintenance_mod = is_maintenance;
            });
        }
    },
    created() {
        this.checkIsMaintenance();
    }
}
</script>
<style lang="scss" scoped>
.admin_header {
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #0F2542;
}

.logo_parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 10px;

    width: 235px;
    height: 75px;

    background: #0C1F38;

    img {
        width: 125px;
        height: 55px;
    }
}

.left_button_group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 0 15px;
}

.log_aut {
    padding: 10px;
    background: none;
    border: none;
    color: #61CC2C;
    transition: all 0.8s;

    &:hover {
        color: white;
    }
}

.maintenance_toggle {
    padding: 10px;
    background: none;
    border: none;
    color: #61CC2C;
    transition: all 0.8s;

    &:hover {
        color: #DD1760;
    }
}

.in_mode {
    color: #DD1760;
    transition: all 0.8s;

    &:hover {
        color: #61CC2C;
    }
}
</style>
