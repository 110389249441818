<template>
    <div class="modal_shadow">
        <div class="inner" ref="modalInner" :id="`challenge_modal_layout__${layout_id}`">
            <div class="modal_title">
                {{ title }}
            </div>

            <slot></slot>

            <div class="button_row">
                <button
                    class="white_green_btn btn_width"
                    @click="closeModal(false)">
                    Cancel
                </button>

                <button
                    class="white_green_btn btn_width"
                    :disabled="is_disabled_save_in_layout"
                    @click="closeModal(true)">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ChallengeModalLayout',
    data() {
        return {
            show: false,
        };
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        is_disabled_save_in_layout: {
            type: Boolean,
            default: true,
        }
    },
    emits: {
        close_modal: null,
    },
    methods: {
        async closeModal(is_confirm) {
            this.$emit('close_modal', is_confirm);
        },
    },
    computed: {
        layout_id() {
            return Math.random()
                .toString(36)
                .replace(/[^a-z0-9]+/g, '')
                .substr(0, 10);
        }
    },
    mounted() {
        document.getElementById(`challenge_modal_layout__${this.layout_id}`)
            .scrollIntoView(true);
    }
}
</script>
<style lang="scss" scoped>
.modal_shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: #0C1F38;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.justify_center {
    justify-content: center;
}

.justify_start {
    justify-content: flex-start;
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 675px;
    background: #FAFBFA;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
    position: relative;
}

.modal_title {
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    color: #214B41;
}

.button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.btn_width {
    width: calc(50% - 10px);
}
</style>
