<template>
    <div class="text_area_body">
        <textarea
            ref="textAreaCustomInput"
            type="text"
            :name="name"
            placeholder=" "
            :rows="1"
            class="pt-3 pb-2 block w-full px-0 mt-0 border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
            :style="`height: ${height}px; resize:none; overflow: hidden;`"
            :value="modelValue"
            @input="parseInput($event.target.value)"
        ></textarea>

        <label
            :for="name"
            class="absolute duration-300 origin-0 text-blue-cast label-title">
            {{ title }}
        </label>
    </div>
</template>
<script>
export default {
    name: 'InputTextAreaNews',
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: 'textarea_input',
        },
    },
    emits: {
        'update:modelValue': null,
    },
    methods: {
        parseInput(val) {
            this.$emit('update:modelValue', val);
        },
        auto_grow() {
            this.height = this.$refs.textAreaCustomInput.scrollHeight;
        }
    },
    data() {
        return {
            height: 44,
        };
    },
    watch: {
        modelValue (val, old) {
            if (val !== old) {
                this.$nextTick(() => {
                    this.auto_grow();
                });
            }
        }
    },
}
</script>
<style lang="scss" scoped>
label {
    top: 0.75rem;
    left: 0;
}

textarea {
    background: none;
    z-index: 2;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.text_area_body {
    width: 100%;
    position: relative;
}

textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value='']):valid ~ label {
    --tw-translate-x: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
    --tw-translate-y: -1.5rem;
}
</style>
