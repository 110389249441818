<template>
    <div class="w-full tabs_parent">
        <div
            @click="routeGo(tab)"
            class="tab"
            :class="{
                'active-link': active_tab === tab.title,
                clicked: tab.title === 'Info' || quest_id
            }"
            v-for="tab in tabs"
            :key="tab.id">

            {{tab.title}}
        </div>
    </div>
</template>
<script>
export default {
    name: 'TabsRowMenu',
    props: {
        active_tab: {
            type: String,
            default: '',
        },
        quest_name: {
            type: String,
            default: '',
        },
        quest_id: {
            type: Number,
            default: null,
        },
        trailblazer_id: {
            type: [Number, String],
            default: '',
        },
        tabs: {
            type: Array,
            default() {
                return [];
            }
        },
        is_create: {
            type: [Boolean, Number, String],
            default: true,
        },
        with_copy: {
            type: [Number, String],
            default: '',
        },
        parent_rout_name: {
            type: String,
            default: '',
        },
    },
    emits: {
        selected_tab: null,
    },
    methods: {
        async routeGo(route) {
            if (this.quest_id) {
                this.$emit('selected_tab', route.id);
                await this.$router.push({
                    path: route.to,
                    query: {
                        quest_name: this.quest_name,
                        quest_id: this.quest_id,
                        trailblazer_id: this.trailblazer_id,
                        is_create: this.is_create,
                        with_copy: this.with_copy,
                        parent_rout_name: this.parent_rout_name,
                    },
                });
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.tabs_parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 2px solid #599FD2;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 124px;

    padding: 12px;
    gap: 8px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #364652;
}

.active-link {
    color: #4CA12F;
    border-bottom: 2px solid #4CA12F;
    padding: 12px 12px 11px 12px;
}

.clicked {
    cursor: pointer;
}
</style>
