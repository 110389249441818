<template>
    <div class="selected_quest_inf">
        <first-active-row
            :name="quest.name"
            :rout_name="'ChallengesAdmin'"
            :title="'Challenges'"/>

        <div class="info_row">
            <div class="quest_inf">
                <div class="quest_inf_item w-1/3">
                    <div class="inf_title">
                        Quest start:
                    </div>
                    <div class="inf_description" v-if="quest?.start_time && quest?.start_timezone">
                        <div>
                            {{dateFormat(quest.start_time, quest.start_timezone.gmt)}}
                        </div>
                        <div>
                            (GMT{{quest.start_timezone.gmt}}) {{quest.start_timezone.title}} ({{quest.start_timezone.description}})
                        </div>
                    </div>
                </div>

                <div class="quest_inf_item w-1/3">
                    <div class="inf_title">
                        Quest end:
                    </div>
                    <div class="inf_description" v-if="quest?.end_time && quest?.end_timezone">
                        <div>
                            {{dateFormat(quest.end_time, quest.end_timezone.gmt)}}
                        </div>
                        <div>
                            (GMT{{quest.end_timezone.gmt}}) {{quest.end_timezone.title}} ({{quest.end_timezone.description}})
                        </div>
                    </div>
                </div>

                <div class="quest_inf_item w-1/3">
                    <div class="inf_title">
                        Created by:
                    </div>
                    <div class="inf_description">
                        {{quest.author_full_name}}
                    </div>
                </div>
            </div>

            <div class="quest_inf">
                <div
                    @click="selectItem('quest_blog_challenge_answers')"
                    class="count_inf_item"
                    :class="{selected: select_item === 'quest_blog_challenge_answers'}">
                    <div class="num">
                        {{quest.quest_blog_challenge_answers_count}}
                    </div>
                    <div class="text">
                        Quest post challenge
                    </div>
                </div>
                <div
                    @click="selectItem('marker_blog_challenge_answers')"
                    class="count_inf_item"
                    :class="{selected: select_item === 'marker_blog_challenge_answers'}">
                    <div class="num">
                        {{quest.marker_blog_challenge_answers_count}}
                    </div>
                    <div class="text">
                        Blog challenge
                    </div>
                </div>
                <div
                    @click="selectItem('photo_challenge_answers')"
                    class="count_inf_item"
                    :class="{selected: select_item === 'photo_challenge_answers'}">
                    <div class="num">
                        {{quest.photo_challenge_answers_count}}
                    </div>
                    <div class="text">
                        Photo challenge
                    </div>
                </div>
                <div
                    @click="selectItem('video_challenge_answers')"
                    class="count_inf_item"
                    :class="{selected: select_item === 'video_challenge_answers'}">
                    <div class="num">
                        {{quest.video_challenge_answers_count}}
                    </div>
                    <div class="text">
                        Video challenge
                    </div>
                </div>
                <div
                    @click="selectItem('receipt_challenge_answers')"
                    class="count_inf_item"
                    :class="{selected: select_item === 'receipt_challenge_answers'}">
                    <div class="num">
                        {{quest.receipt_challenge_answers_count}}
                    </div>
                    <div class="text">
                        Receipt challenge
                    </div>
                </div>
                <div
                    @click="selectItem('archive')"
                    class="count_inf_item"
                    :class="{selected: select_item === 'archive'}">
                    <div class="num">
                        {{quest.archive}}
                    </div>
                    <div class="text">
                        Archive
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import {getDateFormat} from "@/helpers/time.helper";
import FirstActiveRow from "@/components/admin/FirstActiveRow.vue";

export default {
    name: 'FirstRowChallenges',
    components: {FirstActiveRow},
    props: {
        quest: {
            type: Object,
            required: true,
        },
    },
    emits: {
        select_item: null,
    },
    data() {
        return {
            select_item: '',
        };
    },
    methods: {
        dateFormat(val, offset) {
            return getDateFormat(val, offset);
        },
        selectItem(val) {
            this.select_item = val;
            this.$emit('select_item', val);
        },
    },
    beforeMount() {
        this.selectItem('quest_blog_challenge_answers');
    },
}
</script>
<style lang="scss" scoped>
.selected_quest_inf {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 44px;
}

.first_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
        color: #61CC2C;
    }
}

.info_row {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.quest_inf {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.quest_inf_item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inf_title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #599FD2;
}

.inf_description {
    display: flex;
    flex-direction: column;

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #364652;
}

.count_inf_item {
    cursor: pointer;
    width: calc((100% - 100px) / 6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);

    background: #FFFFFF;
    color: #364652;

    .num {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
    }
}

.selected {
    background: #EFFAE9;
    color: #4CA12F;
}
</style>
