<template>
    <cropper
        class="cropper"
        :style="{
            width: pix,
            height: pix
        }"
        :src="image.src"
        ref="cropper"
        :stencil-props="{
            resizable: false,
            aspectRatio: is_square ? 10/10 : 18/10
        }"
    />
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
export default {
    name: 'CropperTemplate',
    components: {
        Cropper,
    },
    props: {
        image: {
            required: true,
        },
        is_square: {
            type: Boolean,
            default: true,
        }
    },
    emits: {
        image_cropped: null,
    },
    methods: {
        crop() {
            const { canvas } = this.$refs.cropper.getResult();
            const res = canvas.toDataURL();
            this.$emit('image_cropped', res);
        },
    },
    data() {
        return {
            pix: null,
        };
    },
    beforeMount() {
        const max_height = window.innerHeight * 0.75;
        if (max_height > 600) {
            this.pix = '600px';
        } else {
            this.pix = `${max_height}px`;
        }
    }
}
</script>
<style lang="scss" scoped>
.cropper {
    background: #DDD;
}
</style>
