<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-in-quests :title="'Active quests'"/>

            <table-template
                v-if="table_view"
                v-model:search="requestData.search"
                v-model:perPage="requestData.perPage"
                v-model:page="requestData.page"
                v-model:sort="requestData.sort"
                :clicked_row="true"
                @clicked_row="openEditQuest"
                :columns="columns"
                :data="quests"
                :total_count="total_count"/>

            <empty-quests-data
                v-if="!quests.length"
                :message="!table_view ? 'Nothing here. Let’s add your quest.' : 'Sorry, nothing found.'"/>
        </div>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import EmptyQuestsData from "@/components/admin/EmptyQuestsData.vue";
import FirstRowInQuests from "@/components/admin/FirstRowInQuests.vue";
import TableTemplate from "@/components/TableTemplate.vue";
import {baseErrHandler, getQuestsList} from "@/services/api";
import {tableGetDate} from "@/helpers/time.helper";

export default {
    name: 'ActiveQuests',
    props: {
        active_item: {
            type: String,
            default: '',
        },
    },
    components: {
        AdminLayouts,
        EmptyQuestsData,
        FirstRowInQuests,
        TableTemplate,
    },
    data() {
        return {
            quests: [],
            columns: [
                {
                    title: 'Name',
                    field: 'name',
                    width: 'w-2/12',
                    sort: true,
                },
                {
                    title: 'Markers',
                    field: 'markers_count',
                    width: 'w-2/12',
                    sort: true,
                },
                {
                    title: 'Start date',
                    width: 'w-3/12',
                    field: 'start_time',
                    sort: true,
                    function: (val) => {
                        if (val.start_time && val?.start_timezone?.gmt) {
                            return tableGetDate(val.start_time, val.start_timezone.gmt);
                        }
                        return '';
                    }
                },
                {
                    title: 'End date',
                    width: 'w-3/12',
                    field: 'end_time',
                    sort: true,
                    function: (val) => {
                        if (val.end_time && val?.end_timezone?.gmt) {
                            return tableGetDate(val.end_time, val.end_timezone.gmt);
                        }
                        return '';
                    }
                },
                {
                    title: 'Author',
                    width: 'w-2/12',
                    field: 'author_full_name',
                    sort: true,
                },
            ],
            requestData: {
                sort: {
                    type: 'desc',
                    field: 'created_at',
                },
                page: 1,
                perPage: 10,
                search: '',
                active: 1,
            },
            total_count: 0,
        };
    },
    methods: {
        async getQuests() {
            await baseErrHandler(async () => {
                const { list, total_count } = await getQuestsList(this.requestData);
                this.quests = list;
                this.total_count = total_count;
            });
        },
        async openEditQuest(row) {
            await this.$router.push({
                path: '/admin/quest-create',
                query: {
                    quest_name: row.name,
                    quest_id: row.id,
                    is_create: 0,
                    parent_rout_name: 'ActiveQuests',
                }
            });
        },
    },
    watch: {
        ['requestData.page']() {
            this.getQuests();
        },
        ['requestData.perPage']() {
            this.requestData.page = 1;
            this.getQuests();
        },
        ['requestData.sort']() {
            this.requestData.page = 1;
            this.getQuests();
        },
        ['requestData.search']() {
            this.requestData.page = 1;
            this.getQuests();
        }
    },
    computed: {
        table_view() {
            if (!this.quests.length && !this.requestData.search) {
                return false;
            }
            return true;
        }
    },
    async beforeMount() {
        await this.getQuests();
    }
}
</script>
<style lang="scss" scoped>

</style>
