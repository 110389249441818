<template>
    <svg width="121" height="121" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="65.5" cy="65.5" r="55.5" fill="#EAF7FF"/>
        <rect width="9.75284" height="13.1329" transform="matrix(0.708387 -0.705825 0.708387 0.705825 74.582 81.3867)" fill="url(#paint0_linear_3026_92431)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M81.6892 46.5C81.6892 27.0655 65.9345 11.3108 46.5 11.3108C27.0655 11.3108 11.3108 27.0655 11.3108 46.5C11.3108 65.9344 27.0655 81.6892 46.5 81.6892C65.9345 81.6892 81.6892 65.9344 81.6892 46.5ZM93 46.5C93 20.8188 72.1812 -3.15514e-06 46.5 -2.03258e-06C20.8188 -9.10017e-07 -3.15514e-06 20.8188 -2.03258e-06 46.5C-9.10017e-07 72.1812 20.8188 93 46.5 93C72.1812 93 93 72.1812 93 46.5Z" fill="url(#paint1_radial_3026_92431)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M81.6892 46.5C81.6892 27.0655 65.9345 11.3108 46.5 11.3108C27.0655 11.3108 11.3108 27.0655 11.3108 46.5C11.3108 65.9344 27.0655 81.6892 46.5 81.6892C65.9345 81.6892 81.6892 65.9344 81.6892 46.5ZM93 46.5C93 20.8188 72.1812 -3.15514e-06 46.5 -2.03258e-06C20.8188 -9.10017e-07 -3.15514e-06 20.8188 -2.03258e-06 46.5C-9.10017e-07 72.1812 20.8188 93 46.5 93C72.1812 93 93 72.1812 93 46.5Z" fill="url(#paint2_radial_3026_92431)" fill-opacity="0.5"/>
        <circle opacity="0.2" cx="46" cy="47" r="41" fill="#70BEFF"/>
        <path d="M80.0466 88.1027C79.4937 87.4252 79.5445 86.4408 80.1642 85.8233L85.9567 80.0517C86.5766 79.434 87.565 79.3835 88.2451 79.9347L116.738 103.027C121.157 106.609 121.497 113.213 117.469 117.227C113.44 121.241 106.811 120.902 103.217 116.497L80.0466 88.1027Z" fill="url(#paint3_linear_3026_92431)"/>
        <path d="M80.0466 88.1027C79.4937 87.4252 79.5445 86.4408 80.1642 85.8233L85.9567 80.0517C86.5766 79.434 87.565 79.3835 88.2451 79.9347L116.738 103.027C121.157 106.609 121.497 113.213 117.469 117.227C113.44 121.241 106.811 120.902 103.217 116.497L80.0466 88.1027Z" fill="url(#paint4_radial_3026_92431)"/>
        <path d="M80.0466 88.1027C79.4937 87.4252 79.5445 86.4408 80.1642 85.8233L85.9567 80.0517C86.5766 79.434 87.565 79.3835 88.2451 79.9347L116.738 103.027C121.157 106.609 121.497 113.213 117.469 117.227C113.44 121.241 106.811 120.902 103.217 116.497L80.0466 88.1027Z" fill="url(#paint5_radial_3026_92431)" fill-opacity="0.5"/>
        <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M101.989 114.994L98.0156 110.124L110.341 97.8438L115.227 101.804L101.989 114.994Z" fill="url(#paint6_radial_3026_92431)"/>
        <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M73.7253 33.9989C73.9943 35.7422 74.1338 37.5283 74.1338 39.347C74.1338 58.562 58.5569 74.1389 39.3417 74.1389C37.5254 74.1389 35.7416 73.9997 34.0004 73.7314C37.5627 75.1937 41.4637 76 45.5531 76C62.3663 76 75.9961 62.3702 75.9961 45.557C75.9961 41.4656 75.189 37.5627 73.7253 33.9989Z" fill="url(#paint7_linear_3026_92431)"/>
        <defs>
            <linearGradient id="paint0_linear_3026_92431" x1="5.15173" y1="7.4374" x2="3.58003" y2="2.26082" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3A57BB"/>
                <stop offset="1" stop-color="#213479"/>
            </linearGradient>
            <radialGradient id="paint1_radial_3026_92431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(108.5 112.375) rotate(-134.421) scale(135.639)">
                <stop offset="0.328125" stop-color="#3244DC"/>
                <stop offset="0.71875" stop-color="#5383FF"/>
                <stop offset="1" stop-color="#72BBFF"/>
            </radialGradient>
            <radialGradient id="paint2_radial_3026_92431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.6818 12.6818) rotate(45) scale(47.8261)">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint3_linear_3026_92431" x1="22.6366" y1="7.73039" x2="124.629" y2="110.093" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FA604A"/>
                <stop offset="1" stop-color="#9235A1"/>
            </linearGradient>
            <radialGradient id="paint4_radial_3026_92431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(118 117.499) rotate(-135) scale(54.4472 48.9254)">
                <stop offset="0.328125" stop-color="#3244DC"/>
                <stop offset="0.71875" stop-color="#5383FF"/>
                <stop offset="1" stop-color="#72BBFF"/>
            </radialGradient>
            <radialGradient id="paint5_radial_3026_92431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(76 74.9993) rotate(48.6522) scale(16.6508 16.9)">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
            </radialGradient>
            <radialGradient id="paint6_radial_3026_92431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(100.499 101.501) rotate(45) scale(26.163 26.1539)">
                <stop offset="0.0101029" stop-color="white"/>
                <stop offset="0.889824" stop-color="white" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint7_linear_3026_92431" x1="67.3001" y1="67.9241" x2="56.7394" y2="57.3647" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0.5"/>
            </linearGradient>
        </defs>
    </svg>
</template>
<script>
export default {
    name: 'SearchBlue',
}
</script>
