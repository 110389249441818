<template>
    <div class="modal-shadow saved_popup min-h-screen flex flex-col">
        <div class="inner flex-col flex p-6">
            <div class="content flex-col flex pt-6 px-2">
                <div class="image_content">
                    <img src="../assets/img/Ellipse_1617.png" alt="ellipse">
                    <div class="logo_div">
                        <img src="../assets/img/logo.png" alt="logo">
                    </div>
                </div>

                <div class="py-4">
                    <div class="success_set text-center welcome text-pink-700">
                        Site set to maintenance mod!
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MaintenanceMod",
    components: {},
    computed: {},
}
</script>
<style lang="scss" scoped>
.modal-shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.39);
}
.saved_popup {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    @media screen and (max-width: 800px) {
        .inner {
            min-width: 330px;
        }
    }
}
.inner {
    max-width: 546px;
    width: 50vw;
    margin: auto;
    background: #ffffff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.inner .content {
    align-items: center;
    gap: 20px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
}

.inner .btn {
    width: 100px;
    height: 40px;
    margin: 0 20px;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.75;
}
.inner .btn:hover {
    opacity: 1;
}
.image_content {
    position: relative;
    max-width: 327px;

    img {
        width: 100%;
    }

    .logo_div {
        position: absolute;
        top: 35%;
        left: 11%;
        width: 250px;
        height: 110px;

        img {
            width: 100%;
        }
    }
}
.welcome {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
}
</style>
