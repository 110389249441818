<template>
    <div
        class="relative z-0"
        :class="{
            input_width: input_width,
            'w-full': !input_width
        }">

        <input
            :disabled="is_disabled"
            type="number"
            id="__id_decimal_input"
            name="__id_decimal_input"
            class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
            :class="{is_disabled: is_disabled}"
            @input="numbersInputChange($event.target.value)"
            :value="modelValue"
        />

        <label
            for="__id_decimal_input"
            class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title"
            :class="{gray_text: is_disabled}">
            {{ title }}
        </label>
    </div>
</template>
<script>
export default {
    name: 'InputTypeDecimal',
    props: {
        title: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [Number, String, null],
            default: null,
        },
        input_width: {
            type: Boolean,
            default: false,
        },
        is_disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: [String, Number],
            default: 0,
        },
        max: {
            type: [String, Number],
            default: 15,
        }
    },
    emits: {
        'update:modelValue': null,
    },
    methods: {
        numbersInputChange(val) {
            const max_num = Number(this.max);
            const min_num = Number(this.min);

            if (Number(val) > max_num) {
                this.$emit('update:modelValue', max_num);
            } else if (Number(val) < min_num) {
                this.$emit('update:modelValue', min_num);
            } else {
                this.$emit('update:modelValue', val);
            }
        }
    },
    computed: {
        view_num() {
            return this.modelValue;
        }
    },
    watch: {
        modelValue(val, old) {
            if (val !== old) {
                if ((Number(val) > 10 && val.length > 6) || (Number(val) < 10 && val.length > 5)) {
                    this.$emit('update:modelValue', old);
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.input_width {
    gap: 10px;
    width: calc(100% / 4);
    max-width: 212px;
}

.is_disabled {
    color: #9CA8B6;
    border-color: #9CA8B6;
}

.gray_text {
    color: #9CA8B6;
}
</style>
