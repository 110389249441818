<template>
    <admin-layouts :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-in-quests
                @quest_copied="questCopied"
                :title="title"
                :name="quest_name"
                :trailblazer_id="trailblazer_id"
                :quest_id="quest_id"
                :is_create="is_create"
                :parent_rout_name="parent_rout_name"
                :select_tabs="select_tabs"
                :key_view="false"/>

            <tabs-row-menu
                v-if="is_menu"
                :key="`${quest_id}_create_quest`"
                :quest_id="quest_id"
                :quest_name="quest_name"
                :trailblazer_id="trailblazer_id"
                :tabs="tabs"
                :is_create="is_create"
                :parent_rout_name="parent_rout_name"
                :active_tab="active_tab"/>

            <slot></slot>
        </div>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import FirstRowInQuests from "@/components/admin/FirstRowInQuests.vue";
import TabsRowMenu from "@/components/UI/menus/TabsRowMenu.vue";
export default {
    name: 'CreateQuestLayouts',
    props: {
        title: {
            type: String,
            default: 'Create quest',
        },
        is_menu: {
            type: Boolean,
            default: true,
        },
        active_item: {
            type: String,
            default: '',
        },
        active_tab: {
            type: String,
            default: '',
        },
        quest_id: {
            type: [Number, String],
            default: '',
        },
        trailblazer_id: {
            type: [Number, String],
            default: '',
        },
        quest_name: {
            type: String,
            default: '',
        },
        is_create: {
            type: [Boolean, Number, String],
            default: true,
        },
        parent_rout_name: {
            type: String,
            default: '',
        },
    },
    emits: {
        quest_copied: null,
    },
    components: {
        AdminLayouts,
        FirstRowInQuests,
        TabsRowMenu
    },
    data() {
        return {
            tabs: [
                {
                    id: 1,
                    title: 'Info',
                    rout_name: 'CreateQuest',
                    to: '/admin/quest-create',
                },
                {
                    id: 2,
                    title: 'Markers',
                    rout_name: 'CreateMarkersList',
                    to: '/admin/create-markers-list',
                },
                {
                    id: 3,
                    title: 'Badges',
                    rout_name: 'AddBadges',
                    to: '/admin/create-badges',
                }
            ],
            tabs_review: [
                {
                    id: 1,
                    title: 'Info',
                    rout_name: 'RevisionQuestInfo',
                    to: '/admin/quest-revision',
                },
                {
                    id: 2,
                    title: 'Markers',
                    rout_name: 'RevisionMarkerList',
                    to: '/admin/revision-markers-list',
                },
                {
                    id: 3,
                    title: 'Badges',
                    rout_name: 'RevisionBadges',
                    to: '/admin/revision-badges',
                }
            ],
        };
    },
    methods: {
        questCopied() {
            this.$emit('quest_copied');
        },
        getSelectTabs() {
            let selected = 'RevisionQuestInfo';
            for (const tab of this.tabs_review) {
                if (tab.title === this.active_tab) {
                    selected = tab.rout_name;
                }
            }
            return selected;
        }
    },
    computed: {
        select_tabs() {
            if (this.active_tab) {
                return this.getSelectTabs();
            }
            return 'RevisionQuestInfo';
        }
    }
}
</script>
<style lang="scss" scoped>

</style>
