<template>
    <div class="w-full parent_table_body">
        <div class="search_row" v-if="is_search">
            <div class="search_icon">
                <search-icon/>
            </div>

            <div class="relative w-7/12 z-2">
                <input-type-text :title="'Search'" v-model="search_text"/>
            </div>
        </div>

        <div class="table_body">
            <div class="table_row table_title_row"
                 :class="{not_border_top: !is_search}">
                <div class="table_column table_title_column" :class="{in_center: order_sorted}">
                    <button
                        @click="setSort('order', sort.type)"
                        v-if="!indexed && order_sorted"
                        class="sort_button_table">
                        <triangle-up :class="{active_sort: sort.type === 'desc' && sort.field === 'order'}"/>

                        <triangle-down :class="{active_sort: sort.type === 'asc' && sort.field === 'order'}"/>
                    </button>
                </div>

                <div class="table_column title_column"
                     :class="title_col?.width ? title_col.width : 'w-1/5'"
                     v-for="(title_col, index) in columns"
                     :key="`title_col_${index}`">
                    <span>{{title_col.title}}</span>

                    <button
                        @click="setSort(title_col.field, sort.type)"
                        v-if="title_col.sort"
                        class="sort_button_table">
                        <triangle-up :class="{active_sort: sort.type === 'desc' && sort.field === title_col.field}"/>

                        <triangle-down :class="{active_sort: sort.type === 'asc' && sort.field === title_col.field}"/>
                    </button>
                </div>
            </div>

            <div
                class="table_row"
                :class="{is_clicked: clicked_row}"
                @click.stop="clickedRow(row)"
                v-for="(row, index_row) in data"
                :key="`row_${row.id}_${index_row}`">
                <div
                    class="table_column data_column">
                    {{!indexed && row?.order ? row.order : index_row + first_row}}
                </div>

                <div
                    class="table_column data_column"
                    :class="col?.width ? col.width : 'w-1/5'"
                    :style="col?.style ? col.style : ''"
                    v-for="col in columns"
                    :key="col.field">
                    <span v-if="col?.is_html" v-html="viewItem(col, row)"></span>
                    <span v-else>{{ viewItem(col, row) }}</span>
                </div>

            </div>
        </div>

        <div class="row_paginate" v-if="total_count > 0">
            <div class="per_page">
                <div class="text_per_page">
                    Rows per page:
                </div>

                <div class="select_per_page">
                    <div class="view_per_page" @click.stop="openSelect">
                        <div class="num_per_page">
                            {{perPage}}
                        </div>

                        <arr-right-icon :class="{
                            up: !select_open,
                            down: select_open}"/>
                    </div>

                    <div id="body_select_num_in_table" class="body_select_num" v-if="select_open">
                        <div class="select_num_item num_per_page"
                             @click.stop="selectedNum(item)"
                             v-for="item in per_pages_array"
                             :key="`body_select_num_${item}`">
                            {{item}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="active_paginate">
                <div class="pages">
                    {{ item_in_page() }} of {{ total_count }}
                </div>

                <button
                    class="button_paginate"
                    :disabled="previous_disable"
                    @click="backPage">
                    <arr-left-icon/>
                    Previous
                </button>

                <button class="button_paginate"
                        :disabled="next_disabled"
                        @click="nextPage">
                    Next
                    <arr-right-icon/>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import SearchIcon from "@/components/svg/SearchIcon.vue";
import {is_string} from "@/helpers/functions.helper";
import ArrLeftIcon from "@/components/svg/ArrLeftIcon.vue";
import ArrRightIcon from "@/components/svg/ArrRightIcon.vue";
import TriangleUp from "@/components/svg/TriangleUp.vue";
import TriangleDown from "@/components/svg/TriangleDown.vue";
import InputTypeText from "@/components/UI/inputs/InputTypeText.vue";

export default {
    name: 'TableTemplate',
    components: {
        InputTypeText,
        TriangleDown,
        TriangleUp,
        ArrRightIcon,
        ArrLeftIcon,
        SearchIcon,
    },
    data() {
        return {
            select_open: false,
            first_row: 1,
            search_text: '',
        };
    },
    props: {
        order_sorted: {
            type: Boolean,
            default: false,
        },
        indexed: {
            type: Boolean,
            default: true,
        },
        is_search: {
            type: Boolean,
            default: true,
        },
        clicked_row: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        search: {
            type: [String, Number],
            required: true,
        },
        total_count: {
            type: Number,
            required: true,
        },
        perPage: {
            type: Number,
            required: true,
        },
        page: {
            type: Number,
            required: true,
        },
        sort: {
            type: Object,
            default() {
                return {
                    type: null,
                    field: null,
                };
            },
        }
    },
    emits: {
        'update:data': null,
        'update:search': null,
        'update:perPage': null,
        'update:page': null,
        'update:sort': null,
        clicked_row: null,
    },
    methods: {
        viewItem(col, row) {
            if (col?.field && !col?.function) {
                if (is_string(col.field)) {
                    return this.itemString(col.field, row);
                } else {
                    return this.itemArray(col.field, row);
                }
            }

            return col.function(row);
        },
        itemString(col, row) {
            const index_arr = col.split('.');

            if (index_arr.length < 2) {
                return row[col];
            } else {
                let res;
                for (const i of index_arr) {
                    if (res) {
                        res = res[i];
                    } else {
                        res = row[i];
                    }
                }
                return res;
            }
        },
        itemArray(col, row) {
            let res = '';
            for (const item of col) {
                if (res) {
                    res = res + ' ' + this.itemString(item, row);
                } else {
                    res = this.itemString(item, row);
                }
            }
            return res;
        },
        selectedNum(item) {
            this.select_open = false;
            this.$emit('update:perPage', item);
        },
        openSelect() {
            this.select_open = true;
        },
        outsideClick(e) {
            if (this.select_open) {
                const el = window.document.getElementById('body_select_num_in_table');

                if (!el.contains(e.target)) {
                    this.select_open = false;
                }
            }
        },
        backPage() {
            this.$emit('update:page', this.page - 1);
        },
        nextPage() {
            this.$emit('update:page', +this.page + 1);
        },
        item_in_page() {
            this.first_row = (this.page - 1) * this.perPage + 1;
            const last_row = +this.first_row + this.rows_in_page - 1;

            return `${this.first_row} - ${last_row}`;
        },
        clickedRow(row) {
            if (this.clicked_row) {
                this.$emit('clicked_row', row);
            }
        },
        setSort(field, sort_type) {
            const type = sort_type === 'desc' ? 'asc' : 'desc';
            this.$emit('update:sort', {field, type});
        }
    },
    computed: {
        rows_in_page() {
            return this.data.length;
        },
        per_pages_array() {
            return [10, 20, 50];
        },
        previous_disable() {
            return this.page < 2;
        },
        pages() {
            return Math.ceil(this.total_count / this.perPage);
        },
        next_disabled() {
            if (this.page >= this.pages) {
                return true;
            }
            return false;
        }
    },
    watch: {
        search_text(val, old) {
            if (val !== old) {
                this.$emit('update:search', val);
            }
        }
    },
    unmounted() {
        window.removeEventListener('click', this.outsideClick);
    },
    mounted() {
        window.addEventListener('click', this.outsideClick);
    },
}
</script>
<style lang="scss" scoped>
.parent_table_body {
    margin-top: 31px;
    border: 2px solid #CFD8E2;
}

.search_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;

    height: 67px;
    background: #F7F9FC;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.search_icon {
    margin-top: 10px;
    color: #61CC2C;
}

.table_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;

    background: #FFFFFF;
}

.table_row {
    width: 100%;
    min-height: 54px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0;

    border-bottom: 2px solid #CFD8E2;
}

.table_title_row {
    border-top: 2px solid #CFD8E2;
    background: #F7F9FC;
}

.not_border_top {
    border-top: none;
}

.table_column {
    min-width: 52px;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title_column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 15px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #364652;

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 75%;
    }
}

.data_column {
    padding: 33px 15px;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;

    color: #364652;
}

.row_paginate {
    width: 100%;
    min-height: 54px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    padding: 15px;
    background: #F7F9FC;
}

.per_page {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.text_per_page {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #599FD2;
}

.active_paginate {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
}

.pages {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;

    color: #599FD2;
}

.button_paginate {
    border: none;
    height: 100%;
    padding: 15px 0;
    background: none;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #599FD2;
    transition: all 0.5s;

    &:hover {
        transition: all 0.5s;
        color: #61CC2C;
    }

    &:disabled {
        transition: all 0.5s;
        cursor: default;
        color: #6b7280;
    }
}

.view_per_page {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    cursor: pointer;

    .up {
        transform: rotate(90deg);
    }

    .down {
        transform: rotate(270deg);
    }

    svg {
        color: #61CC2C;
    }
}

.num_per_page {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    color: #000000;
}

.select_per_page {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .body_select_num {
        position: absolute;
        min-width: 38px;

        background: #FFFFFF;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
        border-radius: 4px;

        z-index: 99;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px 10px;
        gap: 10px;
        bottom: 27px;
        left: -4px;
    }
}

.sort_button_table {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: #364652;
}

.active_sort {
    color: #61CC2C;
}

.is_clicked {
    cursor: pointer;
}

.in_center {
    align-items: center;
    justify-content: center;
    text-align: center;
}

.period {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #214B41;
}

.diff {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}
</style>
