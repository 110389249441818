<template>
    <div class="relative w-full">
        <div class="relative z-1 pt-3 pb-2 bg-transparent border-0 border-b-2 border-blue-cast select_view_body"
             :class="{
            'border-green-cast': view_selected_items.length,
             'border-red-cast': error,
             'border-gray-cast': is_disabled
        }">
            <div class="selected_items" v-if="selected_items.length">
                <div class="selected_item" :class="{gray_background: is_disabled}"
                     v-for="(selected_item, index) in view_selected_items"
                     :key="selected_item.id">
                    <span>
                        {{selected_item.name}}
                    </span>

                    <span
                        class="close_icon"
                        @click="deselectItem(index)">
                        <close-icon/>
                    </span>
                </div>
            </div>

            <div class="relative pt-3 pb-2" v-if="!is_disabled">
                <input
                    @click="openDropdown"
                    @keyup.enter="enterSearch"
                    type="text"
                    name="quest-categories"
                    placeholder=" "
                    class="pt-0 pb-0 w-full block px-0 mt-0 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0"
                    v-model="search"
                />
                <label
                    for="quest-categories"
                    class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title">
                    {{title}}
                </label>

                <div class="dropdown_body" v-if="is_open_dropdown">
                    <div
                        v-for="item in view_items"
                        :key="item.id"
                        @click.stop="selectedItem(item)"
                        class="dropdown_item dropdown_item_hovered w-full">
                        {{item[view]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CloseIcon from "@/components/svg/CloseIcon.vue";
export default {
    name: 'MultiSelect',
    components: {
        CloseIcon
    },
    emits: {
        select_items: null,
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        view: {
            type: String,
            default: 'name',
        },
        title: {
            type: String,
            default: 'Start typing...',
        },
        error: {
            type: Boolean,
            default: false,
        },
        pre_selected_ids: {
            type: Array,
            default() {
                return [];
            }
        },
        is_disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            is_open_dropdown: false,
            selected_items: [],
            selected_ids: [],
            search: null,
        }
    },
    computed: {
        view_selected_items() {
            if (this.pre_selected_ids.length && this.items && this.items.length) {
                this.selected_ids = this.pre_selected_ids;
                this.selected_items = this.items.filter((el) => this.pre_selected_ids.includes(el.id));
            }
            return this.selected_items;
        },
        view_items() {
            if (!this.items) {
                return [];
            }
            let result = this.items;
            if (!this.selected_ids.length && this.pre_selected_ids.length) {
                this.selected_ids = this.pre_selected_ids;
            }

            if (this.selected_ids.length) {
                result = this.items.filter((el) => {
                    return !this.selected_ids.includes(el.id);
                });
            }

            if (this.search && this.search.length) {
                result = result.filter((el) => {
                    let test = el.name.toLowerCase();
                    let needle = this.search.toLowerCase();
                    return test.includes(needle);
                });
            }
            return result;
        },
    },
    unmounted() {
        window.removeEventListener('click', this.outsideClick);
    },
    mounted() {
        window.addEventListener('click', this.outsideClick);
    },
    methods: {
        selectedItem(item) {
            this.selected_ids.push(item.id);
            this.selected_items.push(item);
            this.is_open_dropdown = false;
            this.search = null;

            this.$emit('select_items', this.selected_items);
        },
        openDropdown() {
            this.is_open_dropdown = true;
        },
        deselectItem(index) {
            if (this.is_disabled) {
                return;
            }
            this.selected_ids.splice(index, 1);
            this.selected_items.splice(index, 1);

            this.$emit('select_items', this.selected_items);
        },
        outsideClick(e) {
            if (!this.$el.contains(e.target)) {
                this.search = null;
                this.is_open_dropdown = false;
            }
        },
        enterSearch() {
            if (this.view_items.length !== 1) {
                return;
            }

            this.selectedItem(this.view_items[0]);
        }
    },
}
</script>
<style lang="scss" scoped>
.select_view_body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 9px;
}

.selected_items {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.selected_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: #FFFFFF;

    span {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
    }

    .close_icon {
        cursor: pointer;
        transition: all 0.8s;

        &:hover {
            transform: rotate(360deg);
        }
    }
}

.dropdown_body {
    position: absolute;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    gap: 16px;
    max-height: 250px;
    overflow-y: scroll;

    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 4px;
}

.border-blue-cast {
    border-color: #599FD2;
}

.selected_item {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #FFFFFF;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px 0px 12px;
    gap: 8px;
    width: fit-content;
    height: 29px;
    background: #006CBA;
    border-radius: 20px;
}

.dropdown_item {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: #0C1F38;
}

.border-green-cast {
    border-color: #61CC2C;
}

.border-red-cast {
    border-color: #DD1760;
}

.border-gray-cast {
    border-color: #9CA8B6;
}

.gray_background {
    background: #9CA8B6;
}

input:focus ~ label,
select:focus ~ label {
    --tw-text-opacity: 0 !important;
}
</style>
