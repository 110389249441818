<template>
    <div class="selected_challenge_type_body">
        <div class="inner_selected" v-if="show">
            <div v-if="challenges_categories?.length" class="category_list">
                <div
                    @click.stop="categorySelected(category)"
                    class="category_item"
                    v-for="category in challenges_categories"
                    :key="category.id">
                    {{ category.title }}
                </div>
            </div>
        </div>

        <button
            :disabled="is_disabled"
            class="white_green_btn"
            @click.stop="openSelected">
            Add challenge
        </button>
    </div>
</template>
<script>
export default {
    name: 'SelectTypeChallenge',
    props: {
        challenges_categories: {
            type: Array,
            default() {
                return [];
            },
        },
        is_disabled: {
            type: Boolean,
            default: true,
        }
    },
    emits: {
        category_selected: null,
        close_selected: null,
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        openSelected() {
            this.show = true;
        },
        closeSelected() {
            this.show = false;
        },
        categorySelected(item) {
            this.$emit('category_selected', item);
        },
        outsideClick(e) {
            if (this.show && !this.$el.contains(e.target)) {
                this.closeSelected();
                this.$emit('close_selected');
            }
        },
    },
    unmounted() {
        window.removeEventListener('click', this.outsideClick);
    },
    mounted() {
        window.addEventListener('click', this.outsideClick);
    },
}
</script>
<style lang="scss" scoped>
.selected_challenge_type_body {
    position: relative;
}

.inner_selected {
    min-width: 200px;
    padding: 15px;
    gap: 16px;
    background: #FAFBFA;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 4px;
    position: absolute;
    bottom: 47px;
    left: -18px;
}

.category_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.category_item {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: #0C1F38;
    border-radius: 4px;

    cursor: pointer;

    transition: all 0.5s;

    &:hover {
        transition: all 0.5s;
        background-color: #73a5ff;
        padding-left: 5px;
    }
}
</style>
