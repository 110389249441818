<template>
    <div class="first_row">
        <div class="title_page">
            <span @click="returnToRout" class="btn_emit">{{ title }}</span> / {{ name }}
        </div>

        <div v-if="key_view" class="active_button">
            <button v-if="with_copy" class="white_green_btn" @click="questCopy">
                Copy quest
            </button>

            <button v-if="rout_button && !is_complete" class="white_green_btn" @click="declineQuestById">
                Decline
            </button>

            <button v-if="rout_button && !is_complete" class="white_green_btn" @click="approveQuestById">
                Approve
            </button>

            <button v-if="rout_button && !is_complete" class="white_green_btn" @click="routeGo">
                Edit quest
            </button>
        </div>
    </div>
</template>
<script>

import {approveQuest, baseErrHandler, copyQuest, declineQuest} from "@/services/api";

export default {
    name: 'FirstRowInRevisionQuest',
    props: {
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        rout_name: {
            type:String,
            default: '',
        },
        rout_button: {
            type: String,
            default: '',
        },
        key_view: {
            type: Boolean,
            default: true,
        },
        route_selected: {
            type: Object,
            default() {
                return {};
            },
        },
        quest_id: {
            type: [String, Number],
            default: '',
        },
        trailblazer_id: {
            type: [String, Number],
            default: '',
        },
        with_copy: {
            type: [Number, String],
            default: '',
        },
        is_complete: {
            type: [Number, String, Boolean],
            default: false,
        },
    },
    methods: {
        async routeGo() {
            if (this.quest_id) {
                await this.$router.push({
                    path: this.route_selected.to,
                    query: {
                        quest_name: this.name,
                        quest_id: this.quest_id,
                        trailblazer_id: this.trailblazer_id,
                        is_create: 0,
                    }
                });
            }
        },
        async returnToRout() {
            if (this.rout_name) {
                await this.$router.push({name: this.rout_name});
            }
        },
        async approveQuestById() {
            await baseErrHandler(async () => {
                const { success } = await approveQuest(this.quest_id);
                if (success) {
                    await this.returnToRout();
                }
            });
        },
        async declineQuestById() {
            await baseErrHandler(async () => {
                const { success } = await declineQuest(this.quest_id);
                if (success) {
                    await this.returnToRout();
                }
            });
        },
        async questCopy() {
            await baseErrHandler(async () => {
                const {
                    quest,
                    trailblazer,
                } = await copyQuest(this.quest_id);

                if (quest) {
                    await this.$router.push({
                        path: '/admin/quest-create',
                        query: {
                            quest_name: quest.name,
                            quest_id: quest.id,
                            trailblazer_id: trailblazer?.id,
                            is_create: 0,
                        }
                    });
                }
            })
        }
    },

}
</script>
<style lang="scss" scoped>
.first_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title_page {
    span {
        color: #61CC2C;
    }
}

.active_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
</style>
