<template>
    <div class="modal-shadow saved_popup min-h-screen flex flex-col flex flex-col">
        <div class="inner flex-col flex p-6">
            <div class="login_header">
                <div class="logo_div">
                    <img src="../assets/img/logo.png" alt="logo">
                </div>
            </div>
            <div class="content">
                <div class="w-full">
                    <div
                        class="tile"
                        :class="{in_center: forgot_success}">
                        Forgot password
                    </div>

                    <div
                        class="message"
                        v-if="!forgot_success">
                        {{ message }}
                    </div>
                </div>

                <div
                    class="message in_center"
                    v-if="forgot_success">
                    <span v-html="message"></span>
                </div>

                <div
                    class="relative w-full z-0"
                    v-if="!forgot_success">
                    <input
                        @click="skipError"
                        type="email"
                        name="address-email"
                        placeholder=" "
                        class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                        :class="{border_error: email_error}"
                        v-model="email"
                    />
                    <label for="email" class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title">Email</label>
                    <span class="text-sm text-red-600" v-if="email_error" id="error_email">{{ email_error }}</span>
                </div>

                <div
                    class="w-full button_row"
                    :class="{in_center: forgot_success}">
                    <div
                        class="sub_title btn_emit"
                        v-if="!forgot_success"
                        @click="routeGo('AdminLogIn')">
                        Back to login
                    </div>

                    <button
                        v-if="!forgot_success"
                        class="green_btn button-text"
                        :disabled="button_disable"
                        @click="dataSubmit">
                        Reset password
                    </button>

                    <button
                        v-if="forgot_success"
                        class="green_btn button-text"
                        @click="routeGo('AdminLogIn')">
                        Back to login
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {passwordReset} from "@/services/api";
import {isValidEmail} from "@/services/validators";
import {mapMutations} from "vuex";

export default {
    name: 'ForgotPassword',
    ERROR_TEXT: 'Please check email you entered',
    data() {
        return {
            message: 'To proceed please enter your account email',
            forgot_success: false,
            email: null,
            email_error: null,
        };
    },
    computed: {
        button_disable() {
            return !this.email || this.email_error;

        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async routeGo(route) {
            await this.$router.push({name: route});
        },
        async dataSubmit() {
            this.skipError();
            if (this.validationData()) {
                return;
            }

            await this.forgotPassword();
        },
        validationData() {
            if (!isValidEmail(this.email)) {
                this.email_error = this.$options.ERROR_TEXT;
                return true;
            }

            return false;
        },
        skipError() {
            this.email_error = null;
        },
        clearForm() {
            this.email = null;
        },
        async forgotPassword() {
            this.showLoader();

            const error = this.$options.ERROR_TEXT;

            try {
                const { success } = await passwordReset({
                    email: this.email,
                    is_admin: true,
                });

                if (!success) {
                    this.email_error = error;
                    this.hideLoader();
                }

                this.message = `If  an account for ${'<b>' + this.email + '</b>'} exists, an e-mail will be sent with further instructions.`;
                this.forgot_success = true;

            } catch (e) {
                console.log(e);

                this.email_error = error;
            }

            this.hideLoader();
        }
    },
}
</script>
<style lang="scss" scoped>
.modal-shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: #0C1F38;;
}
.saved_popup {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px;
    gap: 20px;
    width: 675px;
    background: #FFFFFF;
    border-radius: 24px 24px 20px 20px;
}

.inner .login_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    gap: 10px;
    border-radius: 20px 20px 0 0;

    width: 675px;
    height: 170px;

    background: #0F2542;
}

.inner .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    gap: 20px;

    width: 675px;
    //height: 213px;
}

.inner .btn {
    width: 100px;
    height: 40px;
    margin: 0 20px;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.75;
}
.inner .btn:hover {
    opacity: 1;
}

.logo_div {
    width: 250px;
    height: 110px;
    img {
        width: 100%;
        height: 100%;
    }
}

.tile {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #214B41;
    text-align: left;
}

.message {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    span {
        text-align: center;
    }
}

.button_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sub_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #61CC2C;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.label-title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
}

.button-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.border_error {
    border-color: #F44A3E;
}

.in_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
</style>
