<template>
    <div class="modal_shadow" v-if="show">
        <div class="inner" ref="modalInner" :id="`issue_modal__${layout_id}`">
            <div class="full_row">
                <div class="modal_title">
                    {{ title }}
                </div>

                <button
                    class="white_green_btn btn_width"
                    v-if="!issue?.is_corrected"
                    @click="setResolvedById">
                    Resolved
                </button>
            </div>

            <div class="row_inf_duble_col">
                <div class="row_inf half">
                    <div class="sub_title_row">
                        Quest
                    </div>
                    <div class="value_row">
                        {{issue.quest_name}}
                    </div>
                </div>

                <div class="row_inf half">
                    <div class="sub_title_row">
                        Marker
                    </div>
                    <div class="value_row">
                        {{issue.marker_name}}
                    </div>
                </div>
            </div>

            <div class="row_inf_duble_col">
                <div class="row_inf half">
                    <div class="sub_title_row">
                        Date
                    </div>
                    <div class="value_row">
                        {{returnViewDate(issue.report_time)}}
                    </div>
                </div>

                <div class="row_inf half">
                    <div class="sub_title_row">
                        Author
                    </div>
                    <div class="value_row">
                        {{issue.author_full_name}}
                    </div>
                </div>
            </div>

            <div class="row_inf">
                <div class="sub_title_row">
                    {{issue.reason}}
                </div>
                <div class="value_row">
                    {{issue.report}}
                </div>
            </div>

            <div class="button_row">
                <button
                    class="white_green_btn btn_width"
                    @click="closeModalEmit">
                    Ok
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {getDateFormat} from "@/helpers/time.helper";
import {baseErrHandler, setResolved} from "@/services/api";

export default {
    name: 'IssueReportModal',
    data() {
        return {
            show: false,
        };
    },
    props: {
        title: {
            type: String,
            default: 'Report details',
        },
        issue: {
            type: Object,
            default() {
                return {};
            },
        }
    },
    emits: {
        close_modal: null,
        'update:issue': null,
    },
    computed: {
        layout_id() {
            return Math.random()
                .toString(36)
                .replace(/[^a-z0-9]+/g, '')
                .substr(0, 10);
        },
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
    },
    methods: {
        openModal() {
            this.show = true;
            this.$nextTick(() => {
                this.scrollCenter();
            });
        },
        closeModal() {
            const body = document.querySelector("body");
            body.style.overflow = "auto";

            this.show = false;
        },
        closeModalEmit() {
            this.$emit('close_modal');
        },
        scrollCenter() {
            const element = window.document.getElementById(`issue_modal__${this.layout_id}`);
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            const middle = absoluteElementTop - (window.innerHeight / 2  - element.offsetHeight / 2);
            window.scrollTo(0, middle);

            const body = document.querySelector("body");
            body.style.overflow = "hidden";
        },
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
        async setResolvedById() {
            await baseErrHandler(async () => {
                const { issue } = await setResolved(this.issue.id);

                if (issue) {
                    this.$emit('update:issue', issue);
                    this.closeModalEmit();
                }
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.modal_shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: #0C1F38;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 675px;
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
    position: relative;
}

.modal_title {
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    color: #214B41;
}

.row_inf_duble_col {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.row_inf {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.half {
    width: calc((100% - 20px) / 2);
}

.sub_title_row {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #599FD2;
}

.value_row {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #364652;
}

.btn_width {
    width: 205px;
}

.full_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
</style>
