<template>
    <div class="modal-shadow saved_popup min-h-screen flex flex-col flex flex-col" v-if="show">
        <div class="inner">
            <div class="modal_title">
                Location
            </div>

            <search-location
                @closeSearch="closeSearch"
                v-model="search"/>

            <div class="map_body">
                <google-map
                    v-if="lat_lng"
                    v-model="marker_lat_lng"
                    :center="lat_lng"/>
            </div>

            <div class="marker_info" v-if="returnViewString">
                <div class="marker_info_address">
                    {{ returnViewString }}
                </div>
                <div class="marker_info_cord" v-if="address && address?.latlng?.lat && address?.latlng?.lat">
                    {{ `${address.latlng.lat}, ${address.latlng.lat}` }}
                </div>
            </div>

            <div class="button_row">
                <button
                    class="white_green_btn btn_width"
                    @click="closeModal(false)">
                    Cancel
                </button>

                <button
                    class="white_green_btn btn_width"
                    :disabled="is_disabled_save"
                    @click="closeModal(true)">
                    {{ modelValue?.id ? 'Edit' : 'Save' }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {baseErrHandler, getAddressOpenStreetMap, saveCordInDB} from "@/services/api";
import SearchLocation from "@/components/UI/inputs/SearchLocation.vue";
import GoogleMap from "@/components/admin/map/GoogleMap.vue";
export default {
    name: 'AddLocationModal',
    components: {
        GoogleMap,
        SearchLocation
    },
    props: {
        modelValue: null,
    },
    emits: {
        'update:modelValue': null,
        confirm_actions: null,
        no_confirm_actions: null,
    },
    data() {
        return {
            show: false,
            search: '',
            lat_lng: null,
            marker_lat_lng: null,
            location: null,
            address: null,
            is_first: true,
        }
    },
    methods: {
        async openModal() {
            await this.initCord();

            await this.$nextTick(async () => {
                const body = document.querySelector("body");
                body.style.overflow = "hidden";

                this.show = true;
            });
        },
        async closeModal(is_confirm = false) {
            if (is_confirm) {
                await this.createLocationInBase();

                if (!this.modelValue) {
                    return;
                }

                this.$emit('confirm_actions', this.modelValue);
            } else {
                this.$emit('no_confirm_actions');
            }

            this.skipData();

            const body = document.querySelector("body");
            body.style.overflow = "auto";
            this.show = false;
        },
        skipData() {
            this.search = '';
            this.lat_lng = null;
            this.marker_lat_lng = undefined;
            this.location = null;
            this.address = null;
            this.is_first = true;
        },
        getGeoLocation() {
            navigator.geolocation.getCurrentPosition(
                this.setCoords,
                this.getLocError
            );
        },
        setCoords(pos) {
            this.lat_lng = {lat: pos.coords.latitude, lng: pos.coords.longitude};
        },
        getLocError(err) {
            console.log(err);
        },
        async createLocationInBase() {
            await baseErrHandler(async () => {
                const { location } = await saveCordInDB(this.location);
                this.$emit('update:modelValue', location);
            });
        },
        async getCordData(latLng) {
            if (latLng) {
                await baseErrHandler(async () => {
                    const { address } = await getAddressOpenStreetMap(latLng);
                    if (address) {
                        if (!this.is_first) {
                            this.address = address;
                            this.address.latlng = latLng;
                        }

                        this.location = {
                            lat: latLng.lat,
                            lng: latLng.lng,
                            geohash: null,
                            data: address,
                            city: this.getCityAddress(address),
                            street: this.getStreet(address),
                        }

                        this.is_first = false;
                    }
                });
            }
        },
        getCityAddress(address) {
            if (address?.city) {
                return `${address.city}, ${address.country}`;
            } else if (address?.county && address?.village) {
                return `${address.village}, ${address.county}, ${address.country}`;
            } else if (address?.village) {
                return `${address.village}, ${address.country}`;
            }
            return null;
        },
        getStreet(address) {
            if (address?.road) {
                return `${address.road}${address?.house_number ? ', ' + address.house_number : ''}`;
            }
            return null;
        },
        async initCord() {
            if (!this.modelValue) {
                await this.getGeoLocation();
            } else {
                this.location = this.modelValue;
                const latLng = {
                    lat: this.location.lat,
                    lng: this.location.lng,
                };

                if (this.location?.data) {
                    this.address = this.location.data;
                } else {
                    await baseErrHandler(async () => {
                        const { address } = await getAddressOpenStreetMap(latLng);
                        this.address = address;
                        this.address.latlng = latLng;
                    });
                }

                this.is_first = false;
                this.lat_lng = latLng;

                await this.$nextTick(async () => {
                    this.marker_lat_lng = latLng;
                });
            }
        },
        initSearchResult(val) {
            this.search_result = val;
        },
        closeSearch(val) {
            if (!val) {
                this.search = '';
                return;
            }

            this.search = val.label;

            this.lat_lng = {
                lat: val.y,
                lng: val.x,
            };

            this.marker_lat_lng = this.lat_lng;
        },
    },
    computed: {
        is_disabled_save() {
            return !this.address;
        },
        returnViewString() {
            let str = `${this.address?.house_number ? this.address.house_number + ' ' : ''}`;
            str += `${this.address?.road ? this.address.road + ', ' : ''}`;
            str += `${this.address?.city ? this.address.city + ', ' : ''}`;
            if (!this.address?.city && this.address?.village) {
                str += this.address.village + ', ';
                str += `${this.address?.district ? this.address?.district + ', ' : ''}`;
                str += `${this.address?.state ? this.address?.state + ', ' : ''}`;
            }
            str += `${this.address?.country ? this.address.country : ''}`;

            return str;
        }
    },
    watch: {
        marker_lat_lng(val) {
            if (val) {
                this.is_first = false;
                this.getCordData(val);
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.modal-shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: #0C1F38;
}

.saved_popup {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 675px;
    background: #FAFBFA;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
    position: relative;

    max-height: 100vh;
}

.button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.btn_width {
    width: calc(50% - 10px);
}

.modal_title {
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    color: #214B41;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.map_body {
    height:519px;
    width:635px;
    overflow: hidden;
    z-index: 1;
}

.marker_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;

    position: absolute;
    z-index: 4;
    width: 605px;
    left: 35px;
    bottom: 100px;

    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
}

.marker_info_address {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    text-align: left;

    color: #214B41;
}

.marker_info_cord {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;

    text-align: left;

    color: #364652;

}
</style>
