<template>
    <div class="w-full footer_mobile">
        © {{ year }} XploreGEO ® - All Rights Reserved
    </div>
</template>
<script>
export default {
    name: 'FooterMobile',
    computed: {
        year() {
            return new Date().getFullYear();
        }
    },
}
</script>
<style lang="scss" scoped>
.footer_mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 10px;
    height: 58px;
    background: #0C1F38;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #006CBA;
}
</style>
