<template>
    <div class="blog_challenge_row">
        <div class="item_in_row">
            <input-type-text
                :set_disabled="true"
                :title="'Date and time'"
                :name="'emit_start_time'"
                v-model="time"/>
        </div>

        <div class="item_in_row">
            <div class="relative w-full">
                <div class="relative w-full z-0">
                    <input
                        disabled
                        type="text"
                        name="quest-name"
                        class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 dropdown_item is_disabled"
                        :value="timezone?.description ? `(GMT${timezone.gmt}) ${timezone.description}` : null"
                    />
                    <label
                        for="quest-name"
                        class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title gray_text">
                        Timezone
                    </label>
                </div>

                <div class="icon_triang">
                    <green-triang-icon/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InputTypeText from "@/components/UI/inputs/InputTypeText.vue";
import moment from 'moment-timezone';
import GreenTriangIcon from "@/components/svg/GreenTriangIcon.vue";

export default {
    name: 'DateAndTimeRowImit',
    components: {GreenTriangIcon, InputTypeText},
    props: {
        date: {
            type: String,
            default: '',
        },
        timezone: {
            type: Object,
            default() {
                return {
                    gmt: 'UTC',
                };
            }
        }
    },
    computed: {
        time () {
            return moment(this.date).utcOffset('UTC').format(this.time_format);
        },
        time_format() {
            return 'DD-MM-YYYY hh:mm A';
        }
    }
}
</script>
<style lang="scss" scoped>
.blog_challenge_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .item_in_row {
        width: 327px;
    }
}

.is_disabled {
    color: #9CA8B6;
    border-color: #9CA8B6;
}

.gray_text {
    color: #9CA8B6;
}

.icon_triang {
    background: #FFFFFF;
    width: 12px;
    height: 12px;
    color: #9CA8B6;
    position: absolute;
    right: 0;
    bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
