<template>
    <div class="modal_shadow" v-if="show">
        <div class="inner">
            <div class="modal_title">
                Reject challenge
            </div>

            <input-text-area
                :title="'Reject reason'"
                :name="'reject_textarea'"
                v-model="reject_message"/>

            <div class="button_row">
                <button
                    class="white_green_btn btn_width"
                    @click="closeModal">
                    Close
                </button>

                <button
                    :disabled="is_disabled"
                    class="white_red_btn btn_width"
                    @click="reject">
                    Reject
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import InputTextArea from "@/components/UI/inputs/InputTextArea.vue";

export default {
    name: 'ChallengeRejectModal',
    components: {InputTextArea},
    props: {
        message: {
            type: String,
            required: true,
        }
    },
    emits: {
        'update:message': null,
        'reject_challenge': null,
    },
    watch: {
        reject_message(val, old) {
            if (val !== old) {
                this.$emit('update:message', val);
            }
        }
    },
    methods: {
        openModal() {
            this.show = true;
        },
        closeModal() {
            this.reject_message = '';
            this.show = false;
        },
        reject() {
            this.$emit('reject_challenge');
        },
    },
    data() {
        return {
            show: false,
            reject_message: '',
        };
    },
    computed: {
        is_disabled() {
            return this.message.length < 1;
        }
    },
}
</script>
<style lang="scss" scoped>
.modal_shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: #0C1F38;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 675px;
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
    position: relative;
}

.modal_title {
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    color: #214B41;
}

.button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.btn_width {
    width: 310px;
}
</style>
