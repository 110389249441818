<template>
    <div class="draggable_emit">
        <markers-card
            :is_disabled="true"
            v-for="element in modelValue"
            :default_marker="default_marker"
            :marker="element"
            :key="element.id"/>
    </div>
</template>
<script>
import MarkersCard from "@/components/UI/markers/MarkersCard.vue";

export default {
    name: 'DraggableEmit',
    components: {MarkersCard},
    props: {
        modelValue: null,
        default_marker: {
            type: String,
            default: '',
        },
    },
    emits: {
        'update:modelValue': null,
    },
}
</script>
<style lang="scss" scoped>
.draggable_emit {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>
