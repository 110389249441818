export const challengeCategories = [
    {
        title: 'QR-code challenge',
        refs: 'addQRChallengeModal',
    },
    {
        title: 'Photo challenge',
        refs: 'addPhotoChallengeModal',
    },
    {
        title: 'Multi-choice challenge',
        refs: 'addMultiChoiceChallengeModal',
    },
    {
        title: 'Question challenge',
        refs: 'addQuestionChallengeModal',
    },
    {
        title: 'Review challenge',
        refs: 'addReviewChallengeModal',
    },
    {
        title: 'Receipt challenge',
        refs: 'addReceiptChallengeModal',
    },
    {
        title: 'Video challenge',
        refs: 'addVideoChallengeModal',
    },
    {
        title: 'Social media challenge',
        refs: 'addSocialMediaChallengeModal',
    },
    {
        title: 'Blog challenge',
        refs: 'addBlogChallengeModal',
    }
];
