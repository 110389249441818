<template>
    <challenge-modal-layout
        v-if="show"
        :title="modal_title"
        :is_disabled_save_in_layout="is_disabled_save"
        @close_modal="closeModal"
        ref="challengeModalLayout" >

        <input-type-text
            :name="`name`"
            :title="`Challenge name`"
            v-model="name"/>

        <input-type-text
            :name="`description`"
            :title="`Challenge description (optional)`"
            v-model="description"/>

<!--        <input-type-range-->
<!--            :title="`Xcoins for score`"-->
<!--            :max="15"-->
<!--            v-model="coins_score"/>-->

<!--        <input-type-range-->
<!--            :title="`Xcoins for written review`"-->
<!--            :max="15"-->
<!--            v-model="coins_written_review"/>-->

        <input-type-range
            :title="`Min character number`"
            :min="0"
            :max="1000"
            v-model="character_number"/>

        <input-type-checkbox
            :name="`enable_hint`"
            :title="`Enable hint`"
            v-model="is_hint"/>

        <input-type-range
            v-if="is_hint"
            :title="`Xcoins hint price`"
            :max="15"
            v-model="coins"/>

        <input-type-text
            v-if="is_hint"
            :name="`hint_text`"
            :title="`Hint text`"
            v-model="hint_text"/>

        <upload-image
            v-if="is_hint"
            :pre_image="cover ? cover : ''"
            @delete_image_in_base="deleteCoverInBase"
            :key="`qr_code_image_cover`"
            @selected_image="selectedCover" />

        <input-type-checkbox
            :disabled="bonus"
            :name="`mandatory_challenge`"
            :title="`Mandatory challenge`"
            v-model="is_mandatory"/>

        <input-type-checkbox
            :disabled="is_mandatory"
            :name="`bonus`"
            :title="`Is bonus challenge`"
            v-model="bonus"/>

        <div class="w-full item_in_card" v-if="bonus">
            <div class="relative w-full z-0">
                <input
                    type="number"
                    name="purchased_price"
                    placeholder=" "
                    class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                    v-model.number="purchased_price"
                />
                <label for="purchased_price" class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title">
                    Bonus challenge price (Xcoins)
                </label>
            </div>
        </div>

    </challenge-modal-layout>
</template>
<script>
import ChallengeModalLayout from "@/components/layouts/modals/ChallengeModalLayout.vue";
import InputTypeText from "@/components/UI/inputs/InputTypeText.vue";
import InputTypeRange from "@/components/UI/inputs/InputTypeRange.vue";
import InputTypeCheckbox from "@/components/UI/inputs/InputTypeCheckbox.vue";
import UploadImage from "@/components/UI/images/UploadImage.vue";
import {baseErrHandler, createOrUpdateChallenge, deleteChallengeCover} from "@/services/api";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import SwitchElem from "@/components/UI/SwitchElem.vue";
export default {
    name: 'AddReviewChallengeModal',
    components: {
        SwitchElem,
        InputTypeCheckbox,
        ChallengeModalLayout,
        InputTypeText,
        InputTypeRange,
        UploadImage,
        DeleteIcon
    },
    props: {
        category: {
            type: Object,
            default() {
                return {};
            },
        },
        marker_id: {
            type: Number,
            default: null,
        },
        last_order: {
            type: Number,
            default: 0,
        },
        modelValue: null,
    },
    emits: {
        close_modal: null,
        'update:modelValue': null,
    },
    data() {
        return {
            coins_score: 0,
            coins_written_review: 0,
            character_number: 0,
            show: false,
            name: '',
            description: '',
            is_hint: false,
            is_mandatory: false,
            coins: 0,
            hint_text: '',
            cover: null,
            id: null,
            order: this.last_order + 1,
            bonus: false,
            purchased_price: null,
        };
    },
    computed: {
        modal_title() {
            return this.category?.title ? this.category.title : '';
        },
        is_disabled_save() {
            return !this.name.length ||
                (
                    this.is_hint && (
                        !(this.coins > 0) ||
                        !this.hint_text.length
                    )
                ) || (
                    this.bonus && !this.purchased_price
                );
        },
    },
    watch: {
        modelValue(val) {
            if (val && val?.id) {
                this.coins_score = val.coins_score ? val.coins_score : 0;
                this.coins_written_review = val.coins_written_review ? val.coins_written_review : 0;
                this.character_number = val.character_number ? val.character_number : 0;
                this.name = val.name;
                this.description = val?.description ? val.description : '';
                this.is_hint = val?.enable_hint ? val.enable_hint : false;
                this.is_mandatory = val?.is_mandatory ? val.is_mandatory : false;
                this.coins = val?.coins ? val.coins : 0;
                this.hint_text = val?.hint_text ? val.hint_text : '';
                this.cover = val?.cover ? val.cover : null;
                this.id = val?.id ? val.id : null;
                this.order = val?.bonus ? null : (val?.order ? val.order : this.last_order + 1);
                this.bonus = val?.bonus ? val.bonus : false;
                this.purchased_price = val?.bonus ? val.purchased_price : null;
            } else {
                this.skipData();
            }
        },
        is_mandatory(val) {
            if (val) {
                this.bonus = false;
                this.purchased_price = null;
            }
        },
        bonus(val) {
            if (val) {
                this.is_mandatory = false;
            }
        },
    },
    methods: {
        skipData() {
            this.coins_score = 0;
            this.coins_written_review = 0;
            this.character_number = 0;
            this.name = '';
            this.description = '';
            this.is_hint = false;
            this.is_mandatory = false;
            this.coins = 0;
            this.hint_text = '';
            this.cover = null;
            this.id = null;
            this.order = this.last_order + 1;
            this.bonus = false;
            this.purchased_price = null;
        },
        async closeModal(is_confirm) {
            if (!is_confirm) {
                this.$emit('close_modal', false);
            } else {
                const res = await this.saveChallenge();

                if (!res) {
                    return;
                }
            }

            this.$emit('update:modelValue', null);
            this.skipData();

            this.show = false;
        },
        openModal() {
            this.show = true;
        },
        selectedCover(image) {
            this.cover = image;
        },
        async deleteCoverInBase() {
            if (this.id && this.cover) {
                await baseErrHandler(async () => {
                    await deleteChallengeCover(this.cover, this.id);
                    this.cover = null;
                });
            }
        },
        async saveChallenge() {
            let result = false;
            await baseErrHandler(async () => {
                const data = {
                    id: this.id,
                    challenge_category_id: this.category.id,
                    marker_id: this.marker_id,
                    name: this.name,
                    description: this.description,
                    enable_hint: this.is_hint,
                    coins: this.is_hint ? this.coins : null,
                    hint_text: this.is_hint ? this.hint_text : null,
                    cover: this.cover ? this.cover : null,
                    is_mandatory: !this.bonus ? this.is_mandatory : false,
                    character_number: this.character_number,
                    coins_score: this.coins_score,
                    coins_written_review: this.coins_written_review,
                    order: this.bonus ? null : this.order,
                    bonus: !this.is_mandatory ? this.bonus : false,
                    purchased_price: this.bonus ? this.purchased_price : null,
                };

                const { challenge } = await createOrUpdateChallenge(data);

                if (challenge) {
                    this.$emit('close_modal', challenge);
                    result = true;
                }
            });

            return result;
        },
    },
}
</script>
<style lang="scss" scoped>
.checked_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    input[type='checkbox'] {
        outline: none !important;
        color: #61CC2C;
    }
}

.label_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}

.item_in_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.item_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}
</style>
