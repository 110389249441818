<template>
    <admin-layouts :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-in-revision-quest
                :title="'Quests'"
                :name="quest_name"
                :rout_button="selected.title"
                :route_selected="selected"
                :quest_id="quest_id"
                :trailblazer_id="trailblazer_id"
                :rout_name="'AwaitingQuests'"
                :with_copy="with_copy"
                :is_complete="is_complete"
                :key_view="true"/>

            <tabs-row-menu
                v-if="is_menu"
                :key="`${quest_id}_revision`"
                :quest_id="quest_id"
                :quest_name="quest_name"
                :trailblazer_id="trailblazer_id"
                :tabs="tabs"
                :parent_rout_name="parent_rout_name"
                :with_copy="with_copy"
                :active_tab="active_tab"/>

            <slot></slot>
        </div>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import TabsRowMenu from "@/components/UI/menus/TabsRowMenu.vue";
import FirstRowInRevisionQuest from "@/components/admin/FirstRowInRevisionQuest.vue";
export default {
    name: 'RevisionQuestLayouts',
    props: {
        title: {
            type: String,
            default: 'Create quest',
        },
        is_menu: {
            type: Boolean,
            default: true,
        },
        active_item: {
            type: String,
            default: '',
        },
        active_tab: {
            type: String,
            default: '',
        },
        quest_id: {
            type: Number,
            default: null,
        },
        trailblazer_id: {
            type: [Number, String],
            default: '',
        },
        quest_name: {
            type: String,
            default: '',
        },
        with_copy: {
            type: [Number, String],
            default: '',
        },
        is_complete: {
            type: [Number, String, Boolean],
            default: '',
        },
        parent_rout_name: {
            type: String,
            default: '',
        }
    },
    components: {
        FirstRowInRevisionQuest,
        AdminLayouts,
        TabsRowMenu
    },
    data() {
        return {
            selected: {
                id: 1,
                title: 'Info',
                rout_name: 'CreateQuest',
                to: '/admin/quest-create',
            },
            tabs: [
                {
                    id: 1,
                    title: 'Info',
                    rout_name: 'RevisionQuestInfo',
                    to: '/admin/quest-revision',
                },
                {
                    id: 2,
                    title: 'Markers',
                    rout_name: 'RevisionMarkerList',
                    to: '/admin/revision-markers-list',
                },
                {
                    id: 3,
                    title: 'Badges',
                    rout_name: 'RevisionBadges',
                    to: '/admin/revision-badges',
                }
            ],
            tabs_edit: [
                {
                    id: 1,
                    title: 'Info',
                    rout_name: 'CreateQuest',
                    to: '/admin/quest-create',
                },
                {
                    id: 2,
                    title: 'Markers',
                    rout_name: 'CreateMarkersList',
                    to: '/admin/create-markers-list',
                },
                {
                    id: 3,
                    title: 'Badges',
                    rout_name: 'AddBadges',
                    to: '/admin/create-badges',
                }
            ],
        };
    },
    methods: {
        setEditRout() {
            for (const item of this.tabs_edit) {
                if (item.title === this.active_tab) {
                    this.selected = item;
                }
            }
        }
    },
    mounted() {
        this.setEditRout();
    },
}
</script>
<style lang="scss" scoped>

</style>
