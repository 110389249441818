<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-challenges
                v-if="quest"
                :quest="quest"
                @select_item="selectedItem"/>

            <empty-challenges-data v-if="!data?.length" :message="message" />

            <table-template
                v-else
                v-model:search="requestData.search"
                v-model:perPage="requestData.perPage"
                v-model:page="requestData.page"
                v-model:sort="requestData.sort"
                :clicked_row="true"
                @clicked_row="openReview"
                :columns="is_archive ? archive_columns : columns"
                :data="data"
                :total_count="total_count"/>
        </div>

        <challenge-review-modal
            ref="challengeReviewedModal"
            @close_modal="closeModal"
            @open_reject_modal="openRejectModal"
            :selected_type="name_selected"
            :conditions="conditions"
            :challenge="challenge"
            v-model:answer="answer"/>

        <challenge-reject-modal
            @reject_challenge="challengeReject"
            v-model:message="reject_message"
            ref="challengeRejectModal"/>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import FirstRowChallenges from "@/components/admin/FirstRowChallenges.vue";
import {
    baseErrHandler,
    findApprovedChallengesAdmin,
    getChallengeById,
    getChallengesReviewAdmin,
    getQuestWithCountAnswers
} from "@/services/api";
import TableTemplate from "@/components/TableTemplate.vue";
import EmptyChallengesData from "@/components/admin/EmptyChallengesData.vue";
import {getDateFormat} from "@/helpers/time.helper";
import ChallengeReviewModal from "@/components/modals/admin/challenges/review/ChallengeReviewModal.vue";
import {typesArray} from "@/enums/ReviewedChallengeType";
import ChallengeRejectModal from "@/components/modals/admin/challenges/review/ChallengeRejectModal.vue";
export default {
    name: 'ChallengesToReview',
    props: {
        active_item: {
            type: String,
            default: '',
        },
        quest_id: {
            type: [String, Number],
            default: '',
        }
    },
    components: {
        ChallengeRejectModal,
        EmptyChallengesData,
        TableTemplate,
        FirstRowChallenges,
        AdminLayouts,
        ChallengeReviewModal,
    },
    data() {
        return {
            reject_message: '',
            answer: {},
            challenge: {},
            quest: null,
            name_selected: '',
            data: [],
            requestData: {
                sort: {
                    type: 'desc',
                    field: 'answer_time',
                },
                page: 1,
                perPage: 10,
                search: '',
                type: '',
                is_reviewed: false,
                quest_id: null,
            },
            total_count: 0,
            columns: [
                {
                    title: 'Challenge name',
                    field: 'challenge_name',
                    width: 'w-4/12',
                    sort: true,
                    function: (val) => {
                        if (val?.challenge_name) {
                            return val.challenge_name;
                        }
                        return val?.challenge_type ? val.challenge_type : 'Quest blog challenge';
                    }
                },
                {
                    title: 'Pending date',
                    field: 'answer_time',
                    width: 'w-4/12',
                    sort: true,
                    function: (val) => {
                        if (val?.answer_time) {
                            return this.returnViewDate(val.answer_time);
                        }
                        return '';
                    }
                },
                {
                    title: 'User',
                    width: 'w-4/12',
                    field: 'author_full_name',
                    sort: true,
                },
            ],
            archive_columns: [
                {
                    title: 'Challenge type',
                    field: 'challenge_type',
                    width: 'w-2/12',
                    sort: true,
                },
                {
                    title: 'Challenge name',
                    field: 'challenge_name',
                    width: 'w-2/12',
                    sort: true,
                    function: (val) => {
                        if (val?.challenge_name) {
                            return val.challenge_name;
                        }
                        return val?.challenge_type ? val.challenge_type : 'Quest blog challenge';
                    }
                },
                {
                    title: 'Pending date',
                    field: 'answer_time',
                    width: 'w-2/12',
                    sort: true,
                    function: (val) => {
                        if (val?.answer_time) {
                            return this.returnViewDate(val.answer_time);
                        }
                        return '';
                    }
                },
                {
                    title: 'User',
                    width: 'w-2/12',
                    field: 'author_full_name',
                    sort: true,
                },
                {
                    title: 'Approval date',
                    field: 'reviewed_time',
                    width: 'w-2/12',
                    sort: true,
                    function: (val) => {
                        if (val?.reviewed_time) {
                            return this.returnViewDate(val.reviewed_time);
                        }
                        return '';
                    }
                },
                {
                    title: 'Admin',
                    width: 'w-2/12',
                    field: 'reviewer_email',
                    sort: true,
                    style: 'overflow-wrap: anywhere;',
                },
            ],
        };
    },
    async beforeMount() {
        this.requestData.quest_id = this.quest_id;
        await this.getQuestWithCounts();
    },
    methods: {
        async getQuestWithCounts() {
            await baseErrHandler(async () => {
                const { quest } = await getQuestWithCountAnswers(this.quest_id);
                this.quest = quest;
            });
        },
        async getQuestChallengesByType() {
            if (this.name_selected !== 'archive') {
                await this.questChallengesByType();
            } else {
                await this.questApprovedChallenges();
            }
        },
        async questApprovedChallenges() {
            await baseErrHandler(async () => {
                const { list, total_count } = await findApprovedChallengesAdmin(this.requestData);

                this.data = list;
                this.total_count = total_count;
            });
        },
        async questChallengesByType() {
            await baseErrHandler(async () => {
                const { list, total_count } = await getChallengesReviewAdmin(this.requestData);

                this.data = list;
                this.total_count = total_count;
            });
        },
        async selectedItem(val) {
            this.name_selected = val;
            this.requestData.type = val;

            await this.getQuestChallengesByType();
        },
        typeSelect() {
            return typesArray?.[this.name_selected] ? typesArray[this.name_selected] : '';
        },
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
        async openReview(val) {
            await this.getChallengeData(val.challenge_id);

            this.answer = val;

            await this.$nextTick(() => {
                this.$refs.challengeReviewedModal.openModal();
            });
        },
        async closeModal(confirm) {
            if (confirm) {
                await this.getQuestWithCounts();
                await this.getQuestChallengesByType();
            }

            this.answer = {};
            this.challenge = {};
            this.$refs.challengeReviewedModal.show = false;
        },
        async getChallengeData(challenge_id) {
            await baseErrHandler(async () => {
                const { challenge } = await getChallengeById(challenge_id);

                if (challenge) {
                    this.challenge = challenge;
                }
            });
        },
        openRejectModal() {
            this.$refs.challengeRejectModal.openModal();
        },
        async challengeReject() {
            await this.$refs.challengeReviewedModal.approve(false, this.reject_message);

            await this.$nextTick(async () => {
                this.$refs.challengeRejectModal.closeModal();
            });
        },
    },
    computed: {
        message() {
            let challenge_name = this.typeSelect();
            return `No ${challenge_name} challenges yet`
        },
        conditions() {
            const type = this.typeSelect();
            if (type !== 'quest post' && type !== 'blog') {
                return `${type}_conditions`;
            }

            return '';
        },
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
        is_archive() {
            if (this.name_selected === 'archive') {
                return true;
            }
            return false;
        }
    },
    watch: {
        ['requestData.page']() {
            this.getQuestChallengesByType();
        },
        ['requestData.perPage']() {
            this.requestData.page = 1;
            this.getQuestChallengesByType();
        },
        ['requestData.sort']() {
            this.requestData.page = 1;
            this.getQuestChallengesByType();
        },
        ['requestData.type']() {
            this.requestData.page = 1;
            this.getQuestChallengesByType();
        },
        // ['requestData.search']() {
        //     if (this.is_archive) {
        //         this.requestData.page = 1;
        //         this.getQuestChallengesByType();
        //     }
        // },
        // reject_message(val, old) {
        //     console.log('reject', val);
        //     console.log('reject', old);
        // }
    },
}
</script>
