<template>
    <div class="input_type_select_body" @click.stop="toggleDropdown">
        <div
            class="text-blue-cast label-title label_emit"
            :class="{
                change_select: is_open_dropdown || selected,
                _opacity_null: is_opacity && (is_open_dropdown || selected)
            }">
            {{ title }}
        </div>

        <div class="input_emit pb-2 pt-0 px-0" :class="{is_disabled: is_disabled}">
            {{selected}}
        </div>

        <div class="dropdown_body" v-if="is_open_dropdown">
            <div
                v-for="item in view_items"
                :key="item.id"
                @click.stop="selectedItem(item)"
                class="dropdown_item dropdown_item_hovered w-full">
                {{item[view]}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'InputTypeSelect',
    props: {
        name: {
            type: String,
            default: 'select_name',
        },
        title: {
            type: String,
            default: 'Title',
        },
        modelValue: {
            type: Object,
            required: true,
        },
        view: {
            type: String,
            default: 'name',
        },
        is_opacity: {
            type: Boolean,
            default: false,
        },
        is_disabled: {
            type: Boolean,
            default: false,
        },
        view_items: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        name: '1 day',
                        value: 1,
                    },
                    {
                        id: 2,
                        name: '7 days',
                        value: 7,
                    },
                    {
                        id: 3,
                        name: '14 days',
                        value: 14,
                    }
                ];
            },
        }
    },
    emits: {
        'update:modelValue': null,
    },
    computed: {
        id() {
            return Math.random()
                .toString(36)
                .replace(/[^a-z0-9]+/g, '')
                .substr(0, 10);
        },
        selected() {
            if (this.modelValue[this.view]) {
                return this.modelValue[this.view];
            }
            return '';
        }
    },
    data() {
        return {
            is_open_dropdown: false,
        };
    },
    methods: {
        toggleDropdown() {
            if (!this.is_disabled) {
                this.is_open_dropdown = !this.is_open_dropdown;
            }
        },
        outsideClick(e) {
            if (!this.$el.contains(e.target)) {
                this.is_open_dropdown = false;
            }
        },
        selectedItem(item) {
            this.is_open_dropdown = false;
            this.$emit('update:modelValue', item);
        },
    },
    unmounted() {
        window.removeEventListener('click', this.outsideClick);
    },
    mounted() {
        window.addEventListener('click', this.outsideClick);
    },
}
</script>
<style lang="scss" scoped>
.input_type_select_body {
    width: 100%;
    position: relative;
}

.border-blue-cast {
    border-color: #599FD2;
}

.input_emit {
    width: 100%;
    height: 39px;
    border-bottom: 2px solid #599FD2;
    display: flex;
    align-items: center;
}

.dropdown_body {
    position: absolute;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    gap: 16px;
    width: 100%;
    max-height: 250px;
    overflow-y: scroll;

    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 4px;
}

.dropdown_item {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: #0C1F38;
}

.label_emit {
    transition: all 0.8s;
    position: absolute;
    top: 10px;
}

.change_select {
    top: -16px;
    font-size: 12px;
    line-height: 16px;
}

._opacity_null {
    opacity: 0;
}

.gray_text {
    color: #9CA8B6;
}

.is_disabled {
    color: #9CA8B6;
    border-color: #9CA8B6;
}
</style>
