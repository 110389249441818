<template>
    <div class="challenge_card"
         :class="{bonus_card: challenge.bonus}"
         :id="`challenge_card__${challenge.id}`" v-if="challenge?.id">
        <div class="challenge_inf">
            <div class="challenge_title">
                {{ challenge.name }}
            </div>
            <div class="challenge_sub_inf">
                <div class="category">
                    {{category_title}}
                </div>
                <div class="category" v-if="challenge.is_mandatory">
                    /
                </div>
                <div class="type" v-if="challenge.is_mandatory">
                    Mandatory
                </div>
            </div>
        </div>
        <div class="challenge_actions">
            <button
                @click="openEditChallenge(challenge)"
                class="btn_edit_pencil">
                <edit-icon/>
            </button>

            <button
                @click="deleteChallenge(challenge.id)"
                class="btn_delete_box">
                <delete-icon/>
            </button>

            <button
                draggable="true"
                class="btn_drag_icon">
                <drag-icon/>
            </button>
        </div>
    </div>
</template>
<script>
import EditIcon from "@/components/svg/EditIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import DragIcon from "@/components/svg/DragIcon.vue";
export default {
    name: 'ChallengeCard',
    components: {
        DragIcon,
        DeleteIcon,
        EditIcon
    },
    props: {
        challenge: {
            type: Object,
            default() {
                return {};
            },
        },
        category_title: {
            type: String,
            default: '',
        },
        index: {
            type: Number,
            default: 0,
        }
    },
    emits: {
        edit_challenge: null,
        delete_challenge: null,
        drag_challenge: null,
    },
    methods: {
        openEditChallenge(item) {
            this.$emit('edit_challenge', item);
        },
        deleteChallenge(challenge_id) {
            this.$emit('delete_challenge', challenge_id);
        },
    }
}
</script>
<style lang="scss" scoped>
.challenge_card {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
}

.bonus_card {
    box-shadow: 0 2px 3px rgba(255,140,0, 0.32);
}

.challenge_inf {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.challenge_title {
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}

.challenge_sub_inf {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    gap: 4px;

    .category {
        color: #364652;
    }

    .type {
        color: #FF3D00;
    }
}

.challenge_actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
</style>
