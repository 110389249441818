<template>
    <revision-quest-layouts
        :quest_id="id_this_quest"
        :with_copy="with_copy"
        :quest_name="quest_name"
        :trailblazer_id="trailblazer_id"
        :active_item="active_item"
        :is_complete="is_complete"
        :active_tab="active_tab">

        <div class="w-full centered_body">
            <div class="card_body">
                <div class="w-full card_title">
                    General Info
                </div>

                <div class="w-full item_in_card">
                    <div class="item_title">
                        Quest start:
                    </div>

                    <div class="w-full sub_item_row">
                        <date-and-time-row-imit
                            :date="quest_start_time"
                            :timezone="quest_start_timezone"/>
                    </div>

                    <div class="item_title">
                        Quest end (optional):
                    </div>

                    <date-and-time-row-imit
                        :date="quest_end_time"
                        :timezone="quest_end_timezone"/>

                    <div class="item_title">
                        Quest info:
                    </div>

                    <input-type-text
                        :set_disabled="true"
                        :title="`Quest name`"
                        :name="`quest-name`"
                        v-model="quest_name"/>

                    <input-type-text
                        :set_disabled="true"
                        :title="`Quest subtitle`"
                        :name="quest_subtitle"
                        v-model="quest_subtitle"/>

                    <div class="emit_view_input">
                        <div class="label_emit">
                            Quest description
                        </div>

                        <textarea
                            class="text_emit area_emit"
                            disabled
                            ref="textAreaInput"
                            :style="`height: ${height}px; resize:none; overflow: hidden;`"
                            rows="auto" v-model="quest_description">
                        </textarea>
                    </div>

                    <div class="item_title">
                        Quest cover photo:
                    </div>

                    <div class="cover_photo">
                        <img :src="image" alt="cover"/>
                    </div>

                    <div class="item_title">
                        Quest location:
                    </div>

                    <input-type-select
                        :is_opacity="true"
                        :is_disabled="true"
                        :name="'types_by_locations'"
                        :title="'Quest location'"
                        :view_items="types_by_locations"
                        :view="'name'"
                        v-model="type_location"/>

                    <div class="item_title">
                        Quest categories:
                    </div>

                    <multi-select
                        :is_disabled="true"
                        :pre_selected_ids="pre_selected_ids"
                        :items="categories_quest"
                        :view="'name'"
                        id="multiselect_categories_quests"
                        :key="'multiselect_categories_quests'"/>

                    <div class="item_title">
                        Quest blog challenge
                    </div>

                    <input-type-checkbox
                        :disabled="true"
                        :title="`Enable quest blog challenge`"
                        :name="`is_quest_blog`"
                        v-model="is_quest_blog"/>

                    <quest-blog-inf
                        :is_disabled="true"
                        v-if="is_quest_blog"
                        v-model:users_offered="users_offered"
                        v-model:coins_reward="coins_reward"
                        v-model:character_number="character_number"
                        v-model:number_photos="number_photos"/>

                </div>
            </div>

            <div class="card_body">
                <div class="w-full card_title">
                    Trailblazer
                </div>

                <div class="w-full item_in_card">
                    <input-type-checkbox
                        :disabled="true"
                        :title="`Enable trailblazer for this quest`"
                        :name="`is_trailblazer`"
                        v-model="is_trailblazer"/>

                    <div v-if="is_trailblazer" class="item_title">
                        Trailblazer start:
                    </div>

                    <date-and-time-row-imit
                        v-if="is_trailblazer"
                        :date="trailblazer_start_time"
                        :timezone="trailblazer_start_timezone"/>

                    <div v-if="is_trailblazer" class="item_title">
                        Trailblazer end:
                    </div>

                    <date-and-time-row-imit
                        v-if="is_trailblazer"
                        :date="trailblazer_end_time"
                        :timezone="trailblazer_end_timezone"/>
                </div>
            </div>

            <div class="card_body">
                <div class="w-full card_title">
                    Budget
                </div>

                <div class="w-full item_in_card">
                    <input-type-number
                        :is_disabled="true"
                        :title="'Quest access price (Xcoins)'"
                        v-model="quest_access_price"
                        :id="'quest_access_price'" />
                </div>
            </div>

            <div v-if="is_trailblazer" class="card_body">
                <div class="w-full card_title">
                    Trailblazer budget
                </div>

                <div class="w-full item_in_card">
                    <input-type-number
                        :is_disabled="true"
                        :id="'prize_amount'"
                        :name="`prize_amount`"
                        :title="`Prize amount`"
                        v-model="prize_amount"/>

                    <div class="item_title">
                        Prize pool distribution
                    </div>

                    <div class="parent_range">
                        <label class="form-label range_label gray_text">1st place prize amount</label>

                        <div class="range_body is_disabled">
                            <div id="_trailblazer_budget" class="relative w_range">
                                <div class="range_line gray_background"></div>
                                <input
                                    disabled
                                    type="range"
                                    class="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
                                    min="0"
                                    max="100"
                                    step="1"
                                    v-model="place_1"
                                />
                            </div>

                            <div class="percent_view gray_text">
                                {{place_1}}%
                            </div>
                        </div>
                    </div>

                    <div class="parent_range">
                        <label class="form-label range_label gray_text">2nd place prize amount</label>

                        <div class="range_body">
                            <div class="relative w_range">
                                <div class="range_line gray_background"></div>
                                <input
                                    disabled
                                    type="range"
                                    class="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
                                    min="0"
                                    max="100"
                                    step="1"
                                    v-model="place_2"
                                />
                            </div>

                            <div class="percent_view gray_text">
                                {{place_2}}%
                            </div>
                        </div>
                    </div>

                    <div class="parent_range">
                        <label class="form-label range_label gray_text">3rd place prize amount</label>

                        <div class="range_body">
                            <div class="relative w_range">
                                <div class="range_line gray_background"></div>
                                <input
                                    disabled
                                    type="range"
                                    class="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
                                    min="0"
                                    max="100"
                                    step="1"
                                    v-model="place_3"
                                />
                            </div>

                            <div class="percent_view gray_text">
                                {{place_3}}%
                            </div>
                        </div>
                    </div>

                    <div class="item_title">
                        Sponsor’s extra prize
                    </div>

                    <input-type-text
                        :set_disabled="true"
                        :name="`place_extra_prize_1`"
                        :title="`1st place extra prize (Xcoins)`"
                        v-model="place_extra_prize_1"/>

                    <input-type-text
                        :set_disabled="true"
                        :name="`place_extra_prize_2`"
                        :title="`2nd place extra prize (Xcoins)`"
                        v-model="place_extra_prize_2"/>

                    <input-type-text
                        :set_disabled="true"
                        :name="`place_extra_prize_3`"
                        :title="`3rd place extra prize (Xcoins)`"
                        v-model="place_extra_prize_3"/>

                </div>

                <div class="w-full item_in_card">
                    <div class="switch_qr_row">
                        <switch-elem
                            :is_disabled="true"
                            v-model="is_private"/>

                        <div class="item_title">
                            Private Quest {{is_private ? 'On' : 'Off'}}
                        </div>
                    </div>

                    <div class="item_title" v-if="private_link && is_private">
                        {{ private_link }}
                    </div>
                </div>
            </div>
        </div>

    </revision-quest-layouts>
</template>
<script>
import MultiSelect from "@/components/UI/inputs/MultiSelect.vue";
import {
    allCategoriesQuest,
    allTimezones,
    baseErrHandler, getQuestTypeByLocation,
    questInfoGet
} from "@/services/api";
import InputTypeText from "@/components/UI/inputs/InputTypeText.vue";
import InputTypeCheckbox from "@/components/UI/inputs/InputTypeCheckbox.vue";
import QuestBlogInf from "@/components/admin/quest/QuestBlogInf.vue";
import RevisionQuestLayouts from "@/components/layouts/create_quests/RevisionQuestLayouts.vue";
import InputTypeNumber from "@/components/UI/inputs/InputTypeNumber.vue";
import DateAndTimeRowImit from "@/components/UI/imitations/DteaAndTimeRowImit.vue";
import InputTypeSelect from "@/components/UI/inputs/InputTypeSelect.vue";
import SwitchElem from "@/components/UI/SwitchElem.vue";

export default {
    name: 'RevisionQuestInfo',
    components: {
        SwitchElem,
        InputTypeSelect,
        DateAndTimeRowImit,
        InputTypeNumber,
        RevisionQuestLayouts,
        QuestBlogInf,
        InputTypeCheckbox,
        InputTypeText,
        MultiSelect,
    },
    props: {
        active_item: {
            type: String,
            default: '',
        },
        active_tab: {
            type: String,
            default: 'Info',
        },
        with_copy: {
            type: [Number, String],
            default: '',
        },
        is_complete: {
            type: [Number, String, Boolean],
            default: '',
        },
        parent_rout_name: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            height: 44,

            timezones: [],
            categories_quest: [],
            timezone_pre_search: null,
            pre_selected_ids: [],
            types_by_locations: [],
            type_location: {
                id: null,
                name: null,
            },

            quest_id: null,
            quest_name: '',
            quest_subtitle: '',
            quest_description: '',
            image: null,
            quest_categories: null,
            is_trailblazer: false,
            is_quest_blog: false,
            quest_start_timezone: null,
            quest_start_time: '',
            quest_end_timezone: {},
            quest_end_time: '',
            location_type_id: null,
            is_private: false,
            private_link: '',

            quest_budget_id: null,
            quest_access_price: null,

            quest_blog_challenge_id: null,
            users_offered: null,
            coins_reward: null,
            character_number: null,
            number_photos: null,

            trailblazer_id: null,
            trailblazer_start_timezone: {},
            trailblazer_start_time: '',
            trailblazer_end_timezone: {},
            trailblazer_end_time: '',

            trailblazer_budget_id: null,
            prize_amount: '',
            place_1: 0,
            place_2: 0,
            place_3: 0,
            place_extra_prize_1: null,
            place_extra_prize_2: null,
            place_extra_prize_3: null,
        };
    },
    async beforeMount() {
        await this.getTypesByLocations();
        await this.getTimezones();
        await this.getCategoriesQuest();
        if (this.$route?.query?.quest_id) {
            await this.getQuestInfo(this.$route.query.quest_id);
        }
        this.auto_grow();
    },
    async mounted() {
    },
    methods: {
        auto_grow() {
            const height = this.$refs.textAreaInput.scrollHeight;
            if (height > this.height) {
                this.height = height;
            }
        },
        async getTimezones() {
            await baseErrHandler(async () => {
                const { timezones } = await allTimezones();
                this.timezones = timezones;
                this.timezone_pre_search = this.preSearchTimezone();
                this.quest_start_timezone = this.timezone_pre_search;
                this.quest_end_timezone = this.timezone_pre_search;
                this.trailblazer_start_timezone = this.timezone_pre_search;
                this.trailblazer_end_timezone = this.timezone_pre_search;
            });
        },
        async getCategoriesQuest() {
            await baseErrHandler(async () => {
                const { categories } = await allCategoriesQuest();
                this.categories_quest = categories;
            });
        },
        preSearchTimezone() {
            if (this.timezones && this.timezones.length) {
                for (const elem of this.timezones) {
                    if (elem.gmt === this.timezoneOffset) {
                        return elem;
                    }
                }
            }
        },
        async getQuestInfo(quest_id) {
            await baseErrHandler(async () => {
                const {
                    quest,
                    quest_budget,
                    trailblazer,
                    trailblazer_budget,
                    quest_blog_challenge
                } = await questInfoGet(quest_id);

                await this.initQuestData(quest);
                await this.initQuestBudgetData(quest_budget);
                await this.initTrailblazerData(trailblazer);
                await this.initTrailblazerBudgetData(trailblazer_budget);
                await this.initQuestBlogChallengeData(quest_blog_challenge);
            });
        },
        async initQuestData(quest) {
            if (quest) {
                const pre_selected_ids = quest.categories ? quest.categories.map((el) => el.id) : [];
                this.quest_id = quest.id ? quest.id : null;
                this.quest_name = quest.name;
                this.quest_subtitle = quest.subtitle;
                this.quest_description = quest.description ? quest.description : '';
                this.is_quest_blog = quest.is_blog_challenge;
                this.is_trailblazer = quest.is_trailblazer;
                this.quest_start_time = quest.start_time ? quest.start_time : '';
                this.quest_end_time = quest.end_time ? quest.end_time : '';
                this.quest_start_timezone = this.setTimezoneById(quest.start_timezone_id);
                this.quest_end_timezone = this.setTimezoneById(quest.end_timezone_id);
                this.quest_categories = this.categories_quest.filter((el) => pre_selected_ids.includes(el.id));
                this.image = quest.cover;
                this.pre_selected_ids = pre_selected_ids;
                this.location_type_id = quest.location_type_id;
                if (this.location_type_id) {
                    this.type_location = this.getTypeLocationById(this.location_type_id);
                }
                this.is_private = quest.is_private;
                this.private_link = quest.private_link;
            }
        },
        getTypeLocationById(id) {
            for (const item of this.types_by_locations) {
                if (item.id === id) {
                    return item;
                }
            }
        },
        async initQuestBudgetData(quest_budget) {
            if (quest_budget) {
                this.quest_budget_id = quest_budget.id ? quest_budget.id : null;
                this.quest_access_price = quest_budget.access_price;
            }
        },
        async initTrailblazerData(trailblazer) {
            if (trailblazer) {
                this.trailblazer_id = trailblazer.id ? trailblazer.id : null;
                this.trailblazer_start_timezone = this.setTimezoneById(trailblazer.start_timezone_id);
                this.trailblazer_start_time = trailblazer.start_time;
                this.trailblazer_end_timezone = this.setTimezoneById(trailblazer.end_timezone_id);
                this.trailblazer_end_time = trailblazer.end_time;
            }
        },
        async initTrailblazerBudgetData(trailblazer_budget) {
            if (trailblazer_budget) {
                this.trailblazer_budget_id = trailblazer_budget.id ? trailblazer_budget.id : null;
                this.prize_amount = trailblazer_budget?.prize_amount ? trailblazer_budget.prize_amount : '';
                this.place_extra_prize_1 = trailblazer_budget.place_extra_prize_1;
                this.place_extra_prize_2 = trailblazer_budget.place_extra_prize_2;
                this.place_extra_prize_3 = trailblazer_budget.place_extra_prize_3;
                this.place_1 = trailblazer_budget.place_1 ? trailblazer_budget.place_1 : 0;
                this.place_2 = trailblazer_budget.place_2 ? trailblazer_budget.place_2 : 0;
                this.place_3 = trailblazer_budget.place_3 ? trailblazer_budget.place_3 : 0;
            }
        },
        async initQuestBlogChallengeData(quest_blog_challenge) {
            if (quest_blog_challenge) {
                this.quest_blog_challenge_id = quest_blog_challenge.id ? quest_blog_challenge.id : null;
                this.users_offered = quest_blog_challenge.users_offered;
                this.coins_reward = quest_blog_challenge.coins_reward;
                this.character_number = quest_blog_challenge.character_number;
                this.number_photos = quest_blog_challenge.number_photos;
            }
        },
        setTimezoneById(id) {
            if (!this.timezones.length || !id) {
                return {};
            }
            for (const timezone of this.timezones) {
                if (timezone.id === id) {
                    return timezone;
                }
            }
        },
        addParamToUrl(key, param) {
            const url = new URL(window.location.href);
            url.searchParams.set(key, param);
            window.history.replaceState(null, null, url);
        },
        async getTypesByLocations() {
            await baseErrHandler(async () => {
                const { types } = await getQuestTypeByLocation();
                if (types) {
                    this.types_by_locations = types.sort(function (a, b) {
                        if (a.id > b.id) {
                            return 1;
                        }
                        if (a.id < b.id) {
                            return -1;
                        }
                        return 0;
                    });
                }
            });
        },
    },
    computed: {
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
        id_this_quest() {
            return this.quest_id;
        },
    },
    watch: {
        trailblazer_id(val) {
            if (val) {
                this.addParamToUrl('trailblazer_id', val);
            }
        },
        is_complete(val) {
            console.log({val});
        }
    }
}
</script>
<style lang="scss" scoped>
.centered_body {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.in_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card_body {
    width: 675px;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.card_title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: #214B41;
}

.item_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}

.item_in_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sub_item_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.sub_item {
    width: 327px;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.border-red-cast {
    border-color: #DD1760;;
}

.gp-20 {
    gap: 20px;
}

.checked_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    input[type='checkbox'] {
        outline: none !important;
        color: #61CC2C;
    }
}

.label_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}

.parent_range {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.range_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.range_label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.percent_view {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: right;

    color: #000000;
}

.w_range {
    width: 611px;
    display: flex;
    align-items: center;

    .range_line {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 3px;
        background: #CCEAFF;
        top: calc(50% - 3px/2);
    }

    .gray_background {
        background: #9CA8B6;
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            background: #6b7280;
        }
    }

    .error_line {
        background: #DD1760;
    }
}

.image_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_div {
    width: 300px;
    height: 300px;

    img {
        width: 100%;
        height: 100%;
    }
}

.default_image_div {
    width: 150px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
    }
}


.cover_photo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
    }
}

.emit_view_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 12px;
    border-bottom: 2px solid #9CA8B6;

    .label_emit {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 23px;
        color: #9CA8B6;
    }

    .text_emit {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 23px;
        color: #9CA8B6;
        text-align: justify;
    }
}

.area_emit {
    background: none;
}

.is_disabled {
    color: #9CA8B6;
    border-color: #9CA8B6;
}

.gray_text {
    color: #9CA8B6;
}
</style>
