<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-in-news :title="'Variables'" :key_view="false"/>

            <div class="variables_body">
<!--                <div class="variables_row">-->
<!--                    <div class="row_title">-->
<!--                        Xcoin bundles-->
<!--                    </div>-->

<!--                    <div class="inputs_row">-->
<!--                        <input-type-number-->
<!--                            v-for="bundle in bundles"-->
<!--                            :id="`${bundle.id}_bundles_${bundle.xcoins}`"-->
<!--                            :key="`${bundle.id}_bundles_${bundle.xcoins}`"-->
<!--                            :title="`${bundle.xcoins} Xcoins bundle cost, $`"-->
<!--                            @changes="toggleButton"-->
<!--                            :input_width="true"-->
<!--                            v-model="bundle.price"/>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="variables_row">
                    <div class="row_title">
                        User bonus
                    </div>

                    <div class="inputs_row" v-if="bonus?.id">
                        <input-type-number
                            :id="`registration_bonus`"
                            :key="`registration_bonus`"
                            :title="`Registration bonus`"
                            :input_width="true"
                            v-model="bonus.registration_bonus"/>

                        <input-type-number
                            :id="`xcoins_marker_completed_user_bonus`"
                            :key="`xcoins_marker_completed_user_bonus`"
                            :title="`Marker completed`"
                            :input_width="true"
                            v-model="bonus.xcoins_marker_completed"/>

                        <input-type-number
                            :id="`xcoins_marker_first_to_find_user_bonus`"
                            :key="`xcoins_marker_first_to_find_user_bonus`"
                            :title="`Marker first to find`"
                            :input_width="true"
                            v-model="bonus.xcoins_marker_first_to_find"/>
                    </div>
                </div>

                <div class="variables_row">
                    <div class="row_title">
                        The price for creating a quest depends on the type of location
                    </div>

                    <div class="inputs_row" v-if="quest_locations_types?.length">
                        <input-type-number
                            v-for="item in quest_locations_types"
                            :id="`${item.id}_xcoins_pay_quest_create`"
                            :key="`${item.id}_xcoins_pay_quest_create_key`"
                            :title="item.name"
                            @changes="toggleButton"
                            :input_width="true"
                            v-model="item.price"/>
                    </div>
                </div>

                <div class="variables_row">
                    <div class="row_title">
                        News Xcoins bonus
                    </div>

                    <div class="inputs_row" v-if="news?.id">
                        <input-type-number
                            :id="`news_dayle`"
                            :key="`news_dayle`"
                            :title="`Daily bonus`"
                            :input_width="true"
                            v-model="news.dayle"/>

                        <input-type-number
                            :id="`news_monthly`"
                            :key="`news_monthly`"
                            :title="`Monthly bonus`"
                            :input_width="true"
                            v-model="news.monthly"/>

                        <input-type-number
                            :id="`news_news`"
                            :key="`news_news`"
                            :title="`News post reward`"
                            :input_width="true"
                            v-model="news.news"/>
                    </div>
                </div>

                <div class="variables_row">
                    <div class="row_title">
                        Default points reward
                    </div>

                    <div class="inputs_row" v-if="points?.id">
                        <input-type-number
                            :id="`points_quest_complete`"
                            :key="`points_quest_complete`"
                            :title="`For quest completion`"
                            :input_width="true"
                            v-model="points.quest_complete"/>

                        <input-type-number
                            :id="`points_marker_checking`"
                            :key="`points_marker_checking`"
                            :title="`For marker completion`"
                            :input_width="true"
                            v-model="points.checking_marker"/>
                    </div>

                    <div class="subtitle_in_row">
                        Points and Xcoins for marker check-in
                    </div>

                    <div class="inputs_row" v-if="difficulty_rewards?.length">
                        <div class="cell_with_two_inputs" v-for="item in difficulty_rewards">
                            <div class="subtitle_second capitalize">
                                {{ item.name }}
                            </div>

                            <div class="inputs_row">
                                <input-type-number
                                    :id="`${item.id}_by_difficulty_points`"
                                    :key="`${item.order}_by_difficulty_points`"
                                    :title="'Points'"
                                    @changes="toggleButton"
                                    :input_width="true"
                                    v-model="item.points"/>

                                <input-type-number
                                    :id="`${item.id}_by_difficulty_xcoins`"
                                    :key="`${item.order}_by_difficulty_xcoins`"
                                    :title="'Xcoins'"
                                    @changes="toggleButton"
                                    :input_width="true"
                                    v-model="item.xcoins"/>

                                <input-type-number
                                    :is_disabled="true"
                                    :id="`${item.id}_by_difficulty_first_to_find`"
                                    :key="`${item.order}_by_difficulty_first_to_find`"
                                    :title="'First to find'"
                                    @changes="toggleButton"
                                    :input_width="true"
                                    v-model="item.first_to_find"/>
                            </div>
                        </div>
                    </div>

                    <div class="subtitle_in_row">
                        Point for challenge completion
                    </div>

                    <div class="inputs_row" v-if="points?.id && points?.points_by_challenge_category_id">
                        <input-type-number
                            v-for="item in points.points_by_challenge_category_id"
                            :id="`${item.category_id}_points_by_categories_`"
                            :key="`${item.category_id}_points_by_categories_`"
                            :title="item.title"
                            @changes="toggleButton"
                            :input_width="true"
                            v-model="item.points"/>
                    </div>

                    <div class="subtitle_in_row">
                        Xcoins bonus for challenge completion
                    </div>

                    <div class="inputs_row" v-if="points?.id && points?.xcoins_by_challenge_category_id">
                        <div
                             v-for="item in points.xcoins_by_challenge_category_id"
                             :key="`${item.category_id}_xcoins_by_categories_`">
                            <div class="input_width" v-if="item.title !== 'Review challenge'">
                                <input-type-number
                                    :id="`${item.category_id}_xcoins__by_categories_`"
                                    :title="item.title"
                                    @changes="toggleButton"
                                    :input_width="false"
                                    v-model="item.xcoins"/>
                            </div>

                            <div class="dubl_item" v-else>
                                <input-type-number
                                    :id="`${item.category_id}_xcoins__by_categories_stars_xcoins`"
                                    :title="item.title + ' stars'"
                                    @changes="toggleButton"
                                    :input_width="false"
                                    v-model="item.stars_xcoins"/>

                                <input-type-number
                                    :id="`${item.category_id}_xcoins__by_categories_review_xcoins`"
                                    :title="item.title + ' review'"
                                    @changes="toggleButton"
                                    :input_width="false"
                                    v-model="item.review_xcoins"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="button_row">
                    <button
                        class="white_green_btn"
                        :disabled="disabled_button"
                        @click="goBack">
                        Cancel
                    </button>

                    <button
                        class="white_green_btn"
                        :disabled="disabled_button"
                        @click="saveChanges">
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import FirstRowInNews from "@/components/admin/FirstRowInNews.vue";
import {
    baseErrHandler,
    getAllBundles, getAllDifficultyAndRewards,
    getPointsConstants, getQuestTypeByLocation, getUserBonus,
    getXcoinsConstants,
    setBundlesBatch, setDifficultyAndRewards, setPointsConstants, setQuestTypeByLocation, setUserBonus,
    setXcoinsConstants
} from "@/services/api";
import InputTypeNumber from "@/components/UI/inputs/InputTypeNumber.vue";

export default {
    name: 'VariablesAdmin',
    props: {
        active_item: {
            type: String,
            default: '',
        }
    },
    components: {
        InputTypeNumber,
        FirstRowInNews,
        AdminLayouts,
    },
    data() {
        return {
            // bundles: [],
            quest_locations_types: [],
            news: {},
            points: {},
            bonus: {},
            difficulty_rewards: [],
            disabled_button: true,
        };
    },
    methods: {
        async initData() {
            // await this.getBundles();
            await this.getNewsConst();
            await this.getPoints();
            await this.getDifficulty();
            await this.getUserBonusValue();
            await this.getLocationsTypes();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.disabled_button = true;
        },
        // async getBundles() {
        //     await baseErrHandler(async () => {
        //         const { bundles } = await getAllBundles();
        //
        //         if (bundles) {
        //             this.bundles = bundles;
        //         }
        //     });
        // },
        async getLocationsTypes() {
            await baseErrHandler(async () => {
                const { types } = await getQuestTypeByLocation();

                if (types) {
                    this.quest_locations_types = types;
                }
            });
        },
        async getPoints() {
            await baseErrHandler(async () => {
                const { constants } = await getPointsConstants();

                if (constants) {
                    this.points = constants;
                }
            });
        },
        async getNewsConst() {
            await baseErrHandler(async () => {
                const { constants } = await getXcoinsConstants();

                if (constants) {
                    this.news = constants;
                }
            });
        },
        async getDifficulty() {
            await baseErrHandler(async () => {
                const { difficulty_rewards } = await getAllDifficultyAndRewards();
                if (difficulty_rewards) {
                    this.initDifficultlyAndRewards(difficulty_rewards);
                }
            });
        },
        initDifficultlyAndRewards(difficulty_rewards) {
            this.difficulty_rewards = difficulty_rewards.sort(function (a, b) {
                if (a.order > b.order) {
                    return 1;
                }
                if (a.order < b.order) {
                    return -1;
                }
                return 0;
            });
        },
        async setPriceQuestByLocations() {
            await baseErrHandler(async () => {
                const { types } = await setQuestTypeByLocation(this.quest_locations_types);

                if (types) {
                    this.quest_locations_types = types;
                }
            });
        },
        async goBack() {
            await this.initData();
        },
        async saveChanges() {
            // await this.saveBundles();
            await this.saveNewsConst();
            await this.savePoints();
            await this.saveDifficultly();
            await this.setUserBonusValue();
            await this.setPriceQuestByLocations();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.disabled_button = true;
        },
        // async saveBundles() {
        //     await baseErrHandler(async () => {
        //         const { bundles } = await setBundlesBatch(this.bundles);
        //
        //         if (bundles) {
        //             this.bundles = bundles;
        //         }
        //     });
        // },
        async saveNewsConst() {
            await baseErrHandler(async () => {
                const { constants } = await setXcoinsConstants(this.news);

                if (constants) {
                    this.news = constants;
                }
            });
        },
        async savePoints() {
            await baseErrHandler(async () => {
                const { constants } = await setPointsConstants(this.points);

                if (constants) {
                    this.points = constants;
                }
            });
        },
        async saveDifficultly() {
            await baseErrHandler(async () => {
                console.log(this.difficulty_rewards);
                const { difficulty_rewards } = await setDifficultyAndRewards({difficulty_rewards: this.difficulty_rewards});
                if (difficulty_rewards) {
                    this.initDifficultlyAndRewards(difficulty_rewards);
                }
            });
        },
        toggleDisabledButton(val, old) {
            if (val !== old) {
                this.toggleButton();
            }
        },
        toggleButton() {
            this.disabled_button = false;
        },
        async getUserBonusValue() {
            await baseErrHandler(async () => {
                const { bonus } = await getUserBonus();

                if (bonus) {
                    this.bonus = bonus;
                }
            });
        },
        async setUserBonusValue() {
            await baseErrHandler(async () => {
                const { bonus } = await setUserBonus(this.bonus);

                if (bonus) {
                    this.bonus = bonus;
                }
            });
        }
    },
    watch: {
        ['points.quest_complete'](val, old) {
            this.toggleDisabledButton(val, old);
        },
        ['points.checking_marker'](val, old) {
            this.toggleDisabledButton(val, old);
        },
        ['news.dayle'](val, old) {
            this.toggleDisabledButton(val, old);
        },
        ['news.monthly'](val, old) {
            this.toggleDisabledButton(val, old);
        },
        ['news.news'](val, old) {
            this.toggleDisabledButton(val, old);
        },
        ['bonus.registration_bonus'](val, old) {
            this.toggleDisabledButton(val, old);
        },
        ['bonus.xcoins_marker_first_to_find'](val, old) {
            this.toggleDisabledButton(val, old);
        },
        ['bonus.xcoins_marker_completed'](val, old) {
            this.toggleDisabledButton(val, old);
        },
        ['difficulty_rewards.0.points'](val, old) {
            if (val !== old) {
                this.difficulty_rewards[0].first_to_find = val * 3;
            }
        },
        ['difficulty_rewards.1.points'](val, old) {
            if (val !== old) {
                this.difficulty_rewards[1].first_to_find = val * 3;
            }
        },
        ['difficulty_rewards.2.points'](val, old) {
            if (val !== old) {
                this.difficulty_rewards[2].first_to_find = val * 3;
            }
        },
        ['difficulty_rewards.3.points'](val, old) {
            if (val !== old) {
                this.difficulty_rewards[3].first_to_find = val * 3;
            }
        },
    },
    async beforeMount() {
        await this.initData();
    }
}
</script>
<style lang="scss" scoped>
.variables_body {
    margin-top: 44px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    align-items: center;
}

.variables_row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.row_title {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;

    text-align: center;

    color: #214B41;
}

.subtitle_in_row {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;

    text-align: center;

    color: #214B41;
}

.inputs_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.cell_with_two_inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.subtitle_second {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;

    text-align: center;

    color: #214B41;
}

.button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.input_width {
    gap: 10px;
    max-width: 212px;
}

.dubl_item {
    display: flex;
    flex-direction: row;
    max-width: 424px;
    gap: 10px;
}
</style>
