<template>
    <div class="input_block w-full">
        <label>
            {{title}}
        </label>

        <div class="active_input w-full">
            <input :class="{error_background: error}"
                   :value="modelValue"
                   @input="$emit('update:modelValue', $event.target.value)"
                   :type="type_input">

            <div class="eye_icon" @click="eye = !eye">
                <eye-open-green v-if="eye"/>
                <eye-close-green v-else/>
            </div>

            <div class="error_message">
                {{error}}
            </div>
        </div>
    </div>
</template>
<script>
import EyeOpenGreen from "@/components/svg/EyeOpenGreen";
import EyeCloseGreen from "@/components/svg/EyeCloseGreen";

export default {
    name: 'PasswordInput',
    emits: ['update:modelValue'],
    props: {
        modelValue: String,
        title: {
            type: String,
            default: 'New password',
        },
        error: {
            type: String,
            default: '',
        }
    },
    components: {
        EyeCloseGreen,
        EyeOpenGreen
    },
    data() {
        return {
            eye: false,
        }
    },
    computed: {
        type_input() {
            return this.eye ? 'text' : 'password';
        }
    }
}
</script>
<style lang="scss" scoped>
label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding-left: 10px;
    color: #599FD2;
}

.input_block {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.active_input {
    position: relative;

    input {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 10px 6px;
        height: 44px;
        background: #FFFFFF;
        box-shadow: 0 2px 12px rgba(12, 31, 56, 0.05);
        border-radius: 10px;
    }

    .eye_icon {
        width: 24px;
        height: 24px;
        position: absolute;
        cursor: pointer;
        right: 10px;
        top: 11px;
        transition: all 0.5s;
    }

    .error_message {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #DD1760;
    }

    .error_background {
        background: #F8E0E8;
    }
}
</style>
