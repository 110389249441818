<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body add_news_body_template">
            <first-active-row
                :name="id && title ? title : 'Add news'"
                :rout_name="'NewsAdmin'"
                :title="'News'"/>

            <div class="create_form">
                <div class="date_row" v-if="id">
                    <div class="post_info_col">
                        <div class="sub_title_item">
                            Date:
                        </div>
                        <div class="text_item" v-if="create_time">
                            {{returnViewDate(create_time)}}
                        </div>
                    </div>

                    <div class="post_info_col">
                        <div class="sub_title_item">
                            Added by:
                        </div>
                        <div class="text_item" v-if="author_email">
                            {{author_email}}
                        </div>
                    </div>
                </div>

                <input-type-text
                    :title="'Title'"
                    :name="'news_title'"
                    v-model="title"/>

                <div class="date_row">
                    <date-and-time-picker
                        :title="'Start date and time'"
                        :preset_time="start_time"
                        :selected_timezone="selected_timezone"
                        @time_select="startTimeSelect"
                        :key="'data_picker__start'"/>

                    <date-and-time-picker
                        :title="'End date and time'"
                        :preset_time="end_time"
                        :selected_timezone="selected_timezone"
                        @time_select="endTimeSelect"
                        :key="'data_picker__end'"/>
                </div>

                <input-text-area-news
                    :title="'Text'"
                    :name="'news_text'"
                    v-model="text"/>

                <input-type-text
                    :title="'Link to article'"
                    :name="'article_link'"
                    v-model="article_link"/>

                <div class="photo_title">
                    News cover photo:
                </div>

                <upload-image-cropper
                    :is_square="false"
                    :pre_image="cover ? cover : ''"
                    :is_little="true"
                    @delete_image_in_base="deleteCoverInBase"
                    :key="`news_image_cover`"
                    @selected_image="selectedCover" />

            </div>

            <div class="button_row">
                <button class="white_green_btn" @click="$router.push('/admin/news')">
                    Cancel
                </button>

                <button
                    class="white_green_btn"
                    :disabled="is_disabled_save"
                    @click="saveChanges">
                    {{ id ? 'Edit' : 'Save' }}
                </button>
            </div>
        </div>
    </admin-layouts>
</template>
<script>
import FirstActiveRow from "@/components/admin/FirstActiveRow.vue";
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import InputTypeText from "@/components/UI/inputs/InputTypeText.vue";
import InputTextAreaNews from "@/components/UI/inputs/InputTextAreaNews.vue";
import UploadImageCropper from "@/components/UI/images/UploadImage.vue";
import UploadImage from "@/components/UI/images/UploadImage.vue";
import DateAndTimePicker from "@/components/UI/datepickers/DateAndTimePicker.vue";
import {getDateFormat, utsTimeISO} from "@/helpers/time.helper";
import {baseErrHandler, deleteCoverNewsById, getNewsById, saveNews} from "@/services/api";
export default {
    name: 'AddNews',
    components: {
        DateAndTimePicker,
        UploadImage,
        UploadImageCropper,
        InputTextAreaNews,
        InputTypeText,
        AdminLayouts,
        FirstActiveRow,
    },
    props: {
        active_item: {
            type: String,
            default: '',
        },
        id: {
            type: [Number, String],
            default: '',
        }
    },
    data() {
        return {
            title: '',
            text: '',
            start_time: null,
            end_time: null,
            cover: null,
            article_link: '',
            create_time: null,
            author_email: null,
            selected_timezone: {
                gmt: null,
            }
        };
    },
    methods: {
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
        selectedCover(image) {
            this.cover = image;
        },
        setSelectTime(item, val) {
            if (val) {
                this[item] = utsTimeISO(val);
            } else {
                this[item] = '';
            }
        },
        async deleteCoverInBase() {
            if (!this.id) {
                return;
            }

            await baseErrHandler(async () => {
                const { news } = await deleteCoverNewsById(this.id);
                if (news) {
                    this.initData(news);
                }
            });
        },
        async saveChanges() {
            await baseErrHandler(async () => {
                const { news } = await saveNews({
                    id: this.id  ? this.id : null,
                    title: this.title,
                    text: this.text,
                    cover: this.cover,
                    article_link: this.article_link ? this.article_link : '',
                    start_time: this.start_time,
                    end_time: this.end_time,
                });

                this.initData(news);

                if (news && news?.id) {
                    await this.$router.push(`/admin/revision-news/${news.id}`);
                }
            });
        },
        initData(news) {
            this.title = news?.title ? news.title : '';
            this.text = news?.text ? news.text : '';
            this.cover = news?.cover ? news.cover : null;
            this.start_time = news?.start_time ? news.start_time : null;
            this.end_time = news?.end_time ? news.end_time : null;
            this.create_time = news?.create_time ? news.create_time : null;
            this.author_email = news?.author_email ? news.author_email : null;
            this.article_link = news?.article_link ? news.article_link : '';
        },
        startTimeSelect(val) {
            this.setSelectTime('start_time', val);
        },
        endTimeSelect(val) {
            this.setSelectTime('end_time', val);
        },
        async getNews() {
            await baseErrHandler(async () => {
                const { news } = await getNewsById(this.id);

                if (news) {
                    this.initData(news);
                }
            });
        }
    },
    computed: {
        is_disabled_save() {
            return !this.title?.length ||
                !this.text?.length ||
                !this.time_validate ||
                !this.cover;
        },
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
        time_validate() {
            if (this.start_time && this.end_time) {
                return this.start_time < this.end_time;
            }
            return false;
        },
    },
    async beforeMount() {
        this.selected_timezone.gmt = this.timezoneOffset;
        if (this.id) {
            await this.getNews();
        }
    }
}
</script>
<style lang="scss" scoped>
.add_news_body_template {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 44px;
}

.create_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.photo_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #214B41;
}

.button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.date_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.post_info_col {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.sub_title_item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #599FD2;
}

.text_item {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #364652;
}
</style>
