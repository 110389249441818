<template>
    <div class="modal_shadow" v-if="show">
        <div class="inner" id="middle">
            <div class="modal_title">
                Change <span class="change_field">{{type}}</span> {{ prefix }}
            </div>

            <div class="current_val_row" v-if="type !== 'password'">
                <div class="current_title">
                    Current {{ prefix }}:
                </div>

                <div class="value_text">
                    {{value}} <span v-if="prefix" class="change_field">{{type}}</span>
                </div>
            </div>

            <div class="relative w-full z-0" v-if="prefix">
                <input
                    type="number"
                    name="quest_access_price"
                    placeholder=" "
                    class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
                    v-model.number="amount"
                />
                <label for="quest_access_price" class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title">
                    Change {{ prefix }} to:
                </label>
            </div>

            <input-type-text
                v-else
                :title="'Change to:'"
                :name="'change_user_data'"
                v-model="amount"/>

            <div class="button_row">
                <button
                    class="white_green_btn btn_width"
                    @click="closeModal">
                    Close
                </button>

                <button
                    :disabled="is_disabled"
                    class="white_green_btn btn_width"
                    @click="setChange">
                    Save changes
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import InputTypeText from "@/components/UI/inputs/InputTypeText.vue";

export default {
    name: 'ChangeUserBalance',
    components: {InputTypeText},
    props: {
        type: {
            type: String,
            default: 'xcoins'
        },
        value: {
            type: [Number, String],
            default: 0,
        }
    },
    emits: {
        'set_change': null,
        'close_modal': null,
    },
    watch: {},
    methods: {
        openModal() {
            this.amount = null;
            this.show = true;
            this.$nextTick(() => {
                this.scrollCenter();
            });
        },
        closeModal() {
            const body = document.querySelector("body");
            body.style.overflow = "auto";

            this.$emit('close_modal');
            this.show = false;
        },
        setChange() {
            this.$emit('set_change', this.amount);
        },
        scrollCenter() {
            const element = window.document.getElementById('middle');
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            const middle = absoluteElementTop - (window.innerHeight / 2  - element.offsetHeight / 2);
            window.scrollTo(0, middle);

            const body = document.querySelector("body");
            body.style.overflow = "hidden";
        }
    },
    data() {
        return {
            show: false,
            amount: null,
        };
    },
    computed: {
        is_disabled() {
            return !this.amount;
        },
        prefix() {
            return this.type !== 'email' && this.type !== 'password' ? 'amount' : '';
        }
    },
}
</script>
<style lang="scss" scoped>
.modal_shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: #0C1F38;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 440px;
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
    position: relative;
}

.modal_title {
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    color: #214B41;
}

.current_val_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.current_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;

    text-align: right;

    color: #364652;
}

.value_text {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;

    color: #364652;
}

.button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.btn_width {
    width: calc((100% - 20px) / 2);
}

.change_field {
    text-transform: capitalize;
}
</style>
