<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5008 6.23901C13.5008 5.41059 12.8292 4.73901 12.0008 4.73901C11.1724 4.73901 10.5008 5.41059 10.5008 6.23901V8.17773C9.50761 8.32832 8.56205 8.62609 7.69255 9.04849L6.15803 7.51397C5.57225 6.92818 4.6225 6.92818 4.03671 7.51397C3.45092 8.09975 3.45092 9.0495 4.03671 9.63529L5.20196 10.8005C4.00342 11.9782 3.14099 13.4733 2.77433 15.1592C2.59827 15.9687 3.11178 16.7677 3.92128 16.9437C4.73078 17.1198 5.52974 16.6063 5.7058 15.7968C6.27814 13.1652 8.81988 11.065 12.0008 11.065C15.1817 11.065 17.7234 13.1652 18.2958 15.7968C18.4718 16.6063 19.2708 17.1198 20.0803 16.9437C20.8898 16.7677 21.4033 15.9687 21.2273 15.1592C20.8606 13.4733 19.9982 11.9782 18.7996 10.8005L19.9649 9.63529C20.5507 9.0495 20.5507 8.09975 19.9649 7.51397C19.3791 6.92818 18.4293 6.92818 17.8435 7.51397L16.309 9.04849C15.4395 8.62609 14.494 8.32832 13.5008 8.17773V6.23901ZM14.4999 15.4999C14.4999 16.8806 13.3806 17.9999 11.9999 17.9999C10.6192 17.9999 9.49988 16.8806 9.49988 15.4999C9.49988 14.1192 10.6192 12.9999 11.9999 12.9999C13.3806 12.9999 14.4999 14.1192 14.4999 15.4999Z" fill="#61CC2C"/>
    </svg>
</template>
<script>
export default {
    name: 'EyeOpenGreen'
}
</script>
