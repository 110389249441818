<template>
    <div class="qr_template_body">
        <QRCodeVue3
            :width="200"
            :height="200"
            :value="value"
            :qrOptions="qrOptions"
            :imageOptions="imageOptions"
            :dotsOptions="dotsOptions"
            :backgroundOptions="backgroundOptions"
            :cornersSquareOptions="cornersSquareOptions"
            :cornersDotOptions="cornersDotOptions"
            fileExt="png"
            :download="false"
            myclass="my-qur"
            imgclass="img-qr"
        />

        <div class="item_title" v-if="quest_name">
            Quest: {{quest_name}}
        </div>

        <div class="item_subtitle" v-if="marker_name">
            Marker: {{marker_name}}
        </div>

        <div class="item_subtitle" v-if="challenge_name">
            Challenge: {{challenge_name}}
        </div>
    </div>
</template>

<script>
import QRCodeVue3 from "qrcode-vue3";

export default {
    name: 'QRCodeTemplate',
    components: {
        QRCodeVue3
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        quest_name: {
            type: String,
            default: '',
        },
        marker_name: {
            type: String,
            default: '',
        },
        challenge_name: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'H'
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0
            },
            dotsOptions: {
                type: 'classy',
                color: '#000000',
                gradient: {
                    type: 'linear',
                    rotation: 0,
                    colorStops: [
                        { offset: 0, color: '#000000' },
                        { offset: 1, color: '#000000' },
                    ],
                },
            },
            backgroundOptions: {
                color: '#ffffff',
            },
            cornersSquareOptions: {
                type: 'square',
                color: '#000000'
            },
            cornersDotOptions: {
                type: 'square',
                color: '#000000'
            },
        };
    },
}
</script>
<style lang="scss" scoped>
.qr_template_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.item_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}

.item_subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}
</style>
