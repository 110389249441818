<template>
    <draggable
        class="dragArea w-full"
        :list="modelValue"
        @change="setNewOrder">

        <challenge-card
            v-for="element in modelValue"
            :key="element.id"
            :category_title="categoryTitleById(element.challenge_category_id)"
            :challenge="element"
            @edit_challenge="editChallenge"
            @delete_challenge="deleteChallenge"/>
    </draggable>
</template>
<script>
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import ChallengeCard from "@/components/UI/challenges/ChallengeCard.vue";
export default defineComponent({
    components: {
        ChallengeCard,
        draggable: VueDraggableNext,
    },
    props: {
        modelValue: null,
        challenge_categories: {
            type: Array,
            default() {
                return [];
            },
        }
    },
    emits: {
        'update:modelValue': null,
        edit_element: null,
        delete_element: null,
    },
    data() {
        return {
            enabled: true,
            dragging: false,
        }
    },
    methods: {
        categoryTitleById(category_id) {
            if (!this.challenge_categories.length) {
                return '';
            }

            for (const category of this.challenge_categories) {
                if (category.id === category_id) {
                    return category.title;
                }
            }
            return '';
        },
        setNewOrder() {
            let count = 1;
            for (let [index, val] of this.modelValue.entries()) {
                if (!val.bonus) {
                    val.order = count;
                    count++;
                } else {
                    val.order = null;
                }
                // val.order = Number(index) + 1;
            }
        },
        editChallenge(val) {
            this.$emit('edit_element', val);
        },
        deleteChallenge(val) {
            this.$emit('delete_element', val);
        },
    },
})
</script>
<style lang="scss" scoped>
.dragArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>
