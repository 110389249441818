<template>
    <div class="set_new_body w-full">
        <div class="text_title">
            Error!
        </div>
        <div class="text_message">
            Hash expired!
        </div>
    </div>
</template>
<script>
export default {
    name: 'HashExpired',
}
</script>
<style lang="scss" scoped>
.set_new_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 0 22px;
}

.text_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #f2374d;
}

.text_message {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    align-items: center;
    color: #000000;
}
</style>
