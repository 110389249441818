<template>
    <div class="relative w-full z-0">
        <input
            :disabled="is_disabled"
            type="number"
            :name="name"
            class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
            :class="{is_disabled: is_disabled}"
            :value="modelValue"
            @input="toFixedNumber($event.target.value)"
        />

        <label
            :for="name"
            class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title"
            :class="{_opacity_null: is_disabled}">
            {{ title }}
        </label>

        <div class="currency_body">
            $ USD
        </div>
    </div>
</template>
<script>
export default {
    name: 'InputWithCurrencySelect',
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        is_disabled: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'update:modelValue': null,
    },
    methods: {
        async toFixedNumber(val) {
            await this.$emit('update:modelValue', +val);
        },
    },
}
</script>
<style lang="scss" scoped>
.border-blue-cast {
    border-color: #599FD2;;
}

.is_disabled {
    color: #9CA8B6;
    border-color: #9CA8B6;
}

._opacity_null {
    opacity: 0;
}

.currency_body {
    position: absolute;
    width: 51px;
    height: 32px;
    background: #61CC2C;
    color: #FFFFFF;
    border-radius: 6px;
    top: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    gap: 6px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}
</style>
