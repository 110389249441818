<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="9" width="16" height="2" fill="currentColor"/>
        <rect x="4" y="13" width="16" height="2" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'DragIcon'
}
</script>
