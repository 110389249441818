<template>
    <div
        class="modal-shadow saved_popup min-h-screen flex flex-col flex flex-col"
        v-if="show"
        @click.self="closeModal(false)">
        <div class="inner flex-col flex p-6">
            <div class="login_header">
                <div class="logo_div">
                    <img src="../../assets/img/logo.png" alt="logo">
                </div>
            </div>
            <div class="content">
                <div class="message in_center">
                    {{ message }}
                </div>

                <div class="w-full button_row">
                    <button
                        class="white_green_btn"
                        @click.stop="closeModal(false)">
                        Cancel
                    </button>

                    <button
                        class="white_red_btn"
                        @click.stop="closeModal(true)">
                        {{ conf_btn }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ConfirmModal',
    props: {
        message: {
            type: String,
            default: 'Are you sure? It will be impossible to cancel the changes!',
        },
        param: {
            type: [Number, String, Boolean, Object, Array],
            default() {
                return undefined;
            }
        },
        conf_btn: {
            type: String,
            default: 'Ok'
        }
    },
    emits: {
        'confirm_actions': null,
        'no_confirm_actions': null,
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        closeModal(is_confirm = false) {
            if (is_confirm) {
                this.$emit('confirm_actions', this.param);
            } else {
                this.$emit('no_confirm_actions');
            }

            this.show = false;
        },
        openModal() {
            this.show = true;
        },
    },
}
</script>
<style lang="scss" scoped>
.modal-shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.39);
}
.saved_popup {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px;
    gap: 20px;
    width: 675px;
    background: #FFFFFF;
    border-radius: 24px 24px 20px 20px;
}

.inner .login_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    gap: 10px;
    border-radius: 20px 20px 0 0;

    width: 675px;
    height: 170px;

    background: #0F2542;
}

.inner .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    gap: 20px;

    width: 675px;
}

.inner .btn {
    width: 100px;
    height: 40px;
    margin: 0 20px;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.75;
}
.inner .btn:hover {
    opacity: 1;
}

.logo_div {
    width: 250px;
    height: 110px;
    img {
        width: 100%;
        height: 100%;
    }
}

.tile {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #214B41;
    text-align: left;
}

.message {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #F12121;

    span {
        text-align: center;
    }
}

.button_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.sub_title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #61CC2C;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.label-title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
}

.button-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.border_error {
    border-color: #F44A3E;
}

.in_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
</style>
