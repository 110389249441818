<template>
    <div class="modal_shadow" v-if="show">
        <div class="inner" id="middle">
            <div class="modal_title">
                Add quest category
            </div>

            <input-type-text
                :title="'Category name'"
                :name="'add_category_quest'"
                v-model="text"/>

            <div class="button_row">
                <button
                    class="white_green_btn btn_width"
                    @click="closeModal">
                    Close
                </button>

                <button
                    :disabled="is_disabled"
                    class="white_green_btn btn_width"
                    @click="saveCategory">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import InputTypeText from "@/components/UI/inputs/InputTypeText.vue";
import {baseErrHandler, createQuestCategory} from "@/services/api";

export default {
    name: 'AddQuestCategoryModal',
    components: {InputTypeText},
    emits: {
        'create_category': null,
        'close_modal': null,
    },
    data() {
        return {
            show: false,
            text: '',
        };
    },
    methods: {
        openModal() {
            this.text = '';
            this.show = true;
            this.$nextTick(() => {
                this.scrollCenter();
            });
        },
        closeModal() {
            const body = document.querySelector("body");
            body.style.overflow = "auto";

            this.$emit('close_modal');
            this.show = false;
        },
        async saveCategory() {
            // this.$emit('create_category', this.text);
            await this.createCategory();
        },
        async createCategory() {
            await baseErrHandler(async () => {
                const { data } = await createQuestCategory(this.text);

                if (data) {
                    this.$emit('create_category', data);
                }
            });
        },
        scrollCenter() {
            const element = window.document.getElementById('middle');
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            const middle = absoluteElementTop - (window.innerHeight / 2  - element.offsetHeight / 2);
            window.scrollTo(0, middle);

            const body = document.querySelector("body");
            body.style.overflow = "hidden";
        }
    },
    computed: {
        is_disabled() {
            return !this.text?.length;
        },
    },
}
</script>
<style lang="scss" scoped>
.modal_shadow {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: #0C1F38;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 440px;
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
    position: relative;
}

.modal_title {
    width: 100%;
    text-align: left;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    color: #214B41;
}

.current_val_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.current_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;

    text-align: right;

    color: #364652;
}

.value_text {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;

    color: #364652;
}

.button_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.btn_width {
    width: calc((100% - 20px) / 2);
}

.change_field {
    text-transform: capitalize;
}
</style>
