<template>
    <div class="parent_range">
        <label class="form-label range_label"
               :class="{gray_text: is_disabled}">
            {{ title }}
        </label>

        <div class="range_body">
            <div class="relative w_range" :class="{class_disabled: is_disabled}">
                <div class="range_line"></div>
                <input
                    :disabled="is_disabled"
                    type="range"
                    class="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
                    :min="min"
                    :max="max"
                    :step="step"
                    @change="$emit('update:modelValue', $event.target.value)"
                    :value="modelValue"
                />
            </div>

            <div class="percent_view" :class="{gray_text: is_disabled}">
                {{ modelValue }}
                <span v-if="name">{{name}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'InputTypeRange',
    props: {
        modelValue: 0,
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        step: {
            type: Number,
            default: 1,
        },
        is_disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'update:modelValue': null,
    },
}
</script>
<style lang="scss" scoped>
.parent_range {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.range_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.range_label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.percent_view {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: right;

    color: #000000;
}

.w_range {
    width: 571px;
    display: flex;
    align-items: center;
    z-index: 0;

    .range_line {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 3px;
        background: #CCEAFF;
        top: calc(50% - 3px/2);
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            z-index: 2;
            background-color: #61CC2C;
            width: 15px;
            -webkit-appearance: none;
            height: 15px;
            border-radius: 100%;
        }
    }

    .error_line {
        background: #DD1760;
    }
}

.class_disabled {
    color: #9CA8B6;
    //border-color: #9CA8B6;

    .range_line {
        background: #9CA8B6;
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            background-color: #4a5568;
        }
    }
}

.gray_text {
    color: #9CA8B6;
}
</style>
