<template>
    <router-view/>
    <Loader/>
</template>
<script>
import Loader from "./components/Loader";

export default {
    components:{
        Loader
    },
    watch: {
        bookList: {
            handler(val, oldVal) {
                console.log('book list changed')
            },
            deep: true
        },
    },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import "~toastify-js/src/toastify.css";

a {
    text-decoration: none;
}

.min-h-screen {
    min-height: 100vh;
}

.w-full {
    width: 100%;
}

button, input, optgroup, select, textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
}

button, [role="button"] {
 cursor: pointer;
}

button {
 background-color: transparent;
 background-image: none;
}

button, [type='button'], [type='reset'], [type='submit'] {
 -webkit-appearance: button;
}

button, select {
 text-transform: none;
}

button, input, optgroup, select, textarea {
 font-family: inherit;
 font-size: 100%;
 line-height: 1.15;
 margin: 0;
}

*, ::before, ::after {
 --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
 --tw-ring-offset-width: 0px;
 --tw-ring-offset-color: #fff;
 --tw-ring-color: rgba(63, 131, 248, 0.5);
 --tw-ring-offset-shadow: 0 0 #0000;
 --tw-ring-shadow: 0 0 #0000;
}

*, ::before, ::after {
 --tw-shadow: 0 0 #0000;
}

*, ::before, ::after {
 --tw-border-opacity: 1;
 border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

*, ::before, ::after {
 box-sizing: border-box;
 border-width: 0;
 border-style: solid;
 border-color: currentColor;
}

*, ::before, ::after {
 box-sizing: border-box;
}

.bg-transparent {
    background-color: transparent;
}

input[type='range'] {
    &::-webkit-slider-thumb {
        background-color: #61CC2C;
        width: 15px;
        -webkit-appearance: none;
        height: 15px;
        border-radius: 100%;
    }
}

html, body, #app {
    padding: 0;
    margin: 0;
    font-family: 'Quicksand', normal, sans-serif;
    min-height: 100vh;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    //text-rendering: optimizeLegibility !important;
    //-webkit-font-smoothing: antialiased !important;
}

.body-content {
    min-height: 100vh;
    min-width: 100%;
}
.toastify {
    width: 100%;
    max-width: 328px !important;
    margin-right: 0;
    margin-left: auto;
    padding: 15px;
    position: absolute;
    top: 0 !important;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #E6ECF4;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(12, 88, 176, 0.08);
    border-radius: 10px;
    font-size: 12px;
    line-height: 20px;
    &:before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
    }
    &:after {
        content: '';
        width: 44px;
        height: 100%;
        position: absolute;
        left: 2px;
        margin: auto;
        top: 0;
        bottom: 0;
        border-radius: 8px;
    }
    &.error {
        &:before {
            background-image: url('~@/assets/img/stop.svg');
        }
        &:after {
            background: #F2374D;
        }
    }
    &.info {
        &:before {
            background-image: url('~@/assets/img/info.svg');
        }
        &:after {
            background: #2468CA;
        }
    }
    &.warning {
        &:before {
            background-image: url('~@/assets/img/warning.svg');
        }
        &:after {
            background: #FFC01F;
        }
    }
    &.success {
        &:before {
            background-image: url('~@/assets/img/success.svg');
        }
        &:after {
            background: #00DDC6;
        }
    }
    .toast-close {
        color: transparent;
        background-image: url('~@/assets/img/close.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border-radius: 16px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    transition: all 0.8s;

    .btn_green {
        color: #FFFFFF;
        background: #61CC2C;
        border: 1px solid #61CC2C;

        &:hover {
            color: #61CC2C;
            background: #FFFFFF;
            border: 2px solid #61CC2C;
        }
    }
}

.btn_emit {
    cursor: pointer;
}

.green_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #61CC2C;
    border-radius: 100px;
    color: white;

    height: 44px;
    width: 177px;

    transition: all 0.8s;

    &:hover {
        background: white;
        border: 2px solid #61CC2C;
        color: #61CC2C;
    }

    &:disabled {
        background: #6b7280;
        color: white;
        border: none;
    }
}

.white_green_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 25px;
    gap: 10px;

    height: 44px;
    background: #FFFFFF;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    border: 2px solid #61CC2C;
    color: #61CC2C;
    border-radius: 16px;

    transition: all 0.8s;

    &:hover {
        background: #61CC2C;
        color: white;
    }

    &:disabled {
        background: #6b7280;
        color: white;
        border: none;
    }
}

.white_red_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 25px;
    gap: 10px;

    height: 44px;
    background: #FFFFFF;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    border: 2px solid #F12121;
    color: #F12121;
    border-radius: 16px;

    transition: all 0.8s;

    &:hover {
        background: #F12121;
        color: white;
    }

    &:disabled {
        background: #6b7280;
        color: white;
        border: none;
    }
}

.btn_edit_pencil {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 44px;
    height: 44px;
    background: #FFFFFF;
    color: #61CC2C;
    border: none;
    border-radius: 4px;

    &:hover {
        background: #61CC2C;
        color: white;
    }

    &:disabled {
        background: #6b7280;
        color: white;
        border: none;
    }
}

.btn_add_plus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 44px;
    height: 44px;
    background: #FFFFFF;
    color: #61CC2C;
    border: 2px solid #61CC2C;
    border-radius: 16px;

    &:hover {
        background: #61CC2C;
        color: white;
    }

    &:disabled {
        background: #6b7280;
        color: white;
        border: none;
    }
}

.pwr_btn_off {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 44px;
    height: 44px;
    background: #FFFFFF;
    color: #61CC2C;
    border: none;
    border-radius: 4px;

    &:hover {
        background: #F12121;
        color: white;
    }

    &:disabled {
        background: #6b7280;
        color: white;
        border: none;
    }
}

.pwr_btn_on {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 44px;
    height: 44px;
    background: #FFFFFF;
    color: #F12121;
    border: none;
    border-radius: 4px;

    &:hover {
        background: #61CC2C;
        color: white;
    }

    &:disabled {
        background: #6b7280;
        color: white;
        border: none;
    }
}

.btn_delete_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 44px;
    height: 44px;
    background: #FFFFFF;
    color: #F12121;
    border: none;
    border-radius: 4px;

    &:hover {
        background: #F12121;
        color: white;
    }

    &:disabled {
        background: #6b7280;
        color: white;
        border: none;
    }
}

.btn_drag_icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 44px;
    height: 44px;
    background: #FFFFFF;
    color: #006CBA;
    border: none;
    border-radius: 4px;

    &:hover {
        background: #006CBA;
        color: white;
    }

    &:disabled {
        background: #6b7280;
        color: white;
        border: none;
    }
}

.-z-1 {
    z-index: -1;
}

.origin-0 {
    transform-origin: 0%;
}

.subtitle_three {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #214B41;
}

.subtitle_four {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #006CBA;
}

.text_three {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.text_four {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.text_five {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #364652;
}

input:focus ~ label,
input:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value='']):valid ~ label {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
    --tw-translate-y: -1.5rem;
}

textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value='']):valid ~ label {
    --tw-translate-x: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
    --tw-translate-y: -2.5rem;
}

input:focus ~ label,
select:focus ~ label {
    --tw-text-opacity: 1;
    color: rgba(89, 159, 210, var(--tw-text-opacity));
    left: 0;
}

.text-blue-cast {
    color: #599FD2;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.border-green-cast {
    border-color: #61CC2C;
}

.bg-green-cast {
    background-color: #61CC2C;
}

.text-green-cast {
    color: #61CC2C;
}

.border_error {
    border-color: #F44A3E;
}

.title_page {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #214B41;
}

.quests_body {
    padding: 35px 30px 30px 45px;
    min-height: calc(100vh - 75px);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
