<template>
    <admin-layouts
        :active_item="active_item">
        <div class="w-full quests_body">
            <first-row-reports :title="'Challenges'"/>

            <table-template
                v-if="table_view"
                v-model:search="requestData.search"
                v-model:perPage="requestData.perPage"
                v-model:page="requestData.page"
                v-model:sort="requestData.sort"
                :clicked_row="true"
                @clicked_row="openChallengesInf"
                :columns="columns"
                :data="quests"
                :total_count="total_count"/>

            <empty-challenges-data
                v-if="!quests.length"
                :message="!table_view ? 'No pending challenges yet' : 'Sorry, nothing found.'"/>
        </div>
    </admin-layouts>
</template>
<script>
import AdminLayouts from "@/components/layouts/AdminLayouts.vue";
import FirstRowReports from "@/components/admin/FirstRowReports.vue";
import EmptyChallengesData from "@/components/admin/EmptyChallengesData.vue";
import TableTemplate from "@/components/TableTemplate.vue";
import {baseErrHandler, getQuestsWithChallengesList} from "@/services/api";
export default {
    name: 'ChallengesAdmin',
    props: {
        active_item: {
            type: String,
            default: '',
        }
    },
    components: {
        TableTemplate,
        EmptyChallengesData,
        AdminLayouts,
        FirstRowReports,
    },
    data() {
        return {
            quests: [],
            columns: [
                {
                    title: 'Quest',
                    field: 'name',
                    width: 'w-6/12',
                    sort: true,
                },
                {
                    title: 'Author',
                    width: 'w-4/12',
                    field: 'author_full_name',
                    sort: true,
                },
                {
                    title: 'Pending',
                    width: 'w-2/12',
                    field: 'pending',
                    sort: true,
                },
            ],
            requestData: {
                sort: {
                    type: 'desc',
                    field: 'start_time',
                },
                page: 1,
                perPage: 10,
                search: '',
                active: 1,
            },
            total_count: 0,
        }
    },
    methods: {
        async openChallengesInf(row) {
            await this.$router.push({
                name: 'ChallengesToReview',
                query: {
                    quest_id: row.id,
                }
            });
        },
        async getQuestsWithPending() {
            await baseErrHandler(async () => {
                const { list, total_count } = await getQuestsWithChallengesList(this.requestData);

                this.quests = list;
                this.total_count = total_count;
            });
        }
    },
    watch: {
        ['requestData.page']() {
            this.getQuestsWithPending();
        },
        ['requestData.perPage']() {
            this.requestData.page = 1;
            this.getQuestsWithPending();
        },
        ['requestData.sort']() {
            this.requestData.page = 1;
            this.getQuestsWithPending();
        },
        ['requestData.search']() {
            this.requestData.page = 1;
            this.getQuestsWithPending();
        }
    },
    computed: {
        table_view() {
            if (!this.quests.length && !this.requestData.search) {
                return false;
            }
            return true;
        }
    },
    async beforeMount() {
        await this.getQuestsWithPending();
    }
}
</script>
