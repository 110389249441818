<template>
    <revision-quest-layouts
        :quest_id="quest_id"
        :quest_name="quest_name"
        :trailblazer_id="trailblazer_id"
        :active_item="active_item"
        :active_tab="active_tab">
        <div class="w-full centered_body">
            <div class="card_body">
                <div class="w-full item_in_card">
                    <div class="item_title">
                        Default marker icon (optional):
                    </div>

                    <image-upload-imit
                        :default_image="'DefMarkerIcon.png'"
                        :default_marker="default_marker"/>

                    <input-type-checkbox
                        :disabled="true"
                        :title="`Enable quest sequence`"
                        :name="`quest_sequence`"
                        v-model="quest_sequence"/>

                    <input-type-checkbox
                        :disabled="true"
                        :title="`Enable auto check in`"
                        :name="`auto_check`"
                        v-model="auto_check"/>

                    <div class="item_title">
                        First to find marker bonus:
                    </div>

                    <input-type-checkbox
                        :disabled="true"
                        :title="`Enable first to find markers bonus`"
                        :name="`first_to_find`"
                        v-model="first_to_find"/>

                    <input-type-range
                        :is_disabled="true"
                        v-if="first_to_find"
                        :title="`How many users will be gifted`"
                        :max="20"
                        v-model="how_many"/>

                    <input-type-range
                        :is_disabled="true"
                        v-if="first_to_find"
                        :title="`Extra Xcoins`"
                        :max="15"
                        v-model="extra_xcoins"/>

                    <div class="item_title">
                        List of markers:
                    </div>

                    <div class="range_label">
                        At least 1 marker must be added
                    </div>

                    <div v-if="markers?.length" class="w-full marker_list">
                        <draggable-emit
                            :default_marker="default_marker"
                            v-model="markers"/>
                    </div>
                </div>
            </div>
        </div>
    </revision-quest-layouts>
</template>
<script>
import RevisionQuestLayouts from "@/components/layouts/create_quests/RevisionQuestLayouts.vue";
import {
    baseErrHandler,
    getAllMarkersByListId,
    getMarkersListInfo
} from "@/services/api";
import InputTypeCheckbox from "@/components/UI/inputs/InputTypeCheckbox.vue";
import InputTypeRange from "@/components/UI/inputs/InputTypeRange.vue";
import DraggableEmit from "@/components/UI/imitations/DraggableEmit.vue";
import ImageUploadImit from "@/components/UI/imitations/ImageUploadImit.vue";
export default {
    name: 'RevisionMarkerList',
    components: {
        ImageUploadImit,
        DraggableEmit,
        InputTypeRange,
        InputTypeCheckbox,
        RevisionQuestLayouts,
    },
    props: {
        active_item: {
            type: String,
            default: '',
        },
        active_tab: {
            type: String,
            default: 'Info',
        },
        quest_id: {
            type: Number,
            default: null,
        },
        quest_name: {
            type: String,
            default: '',
        },
        trailblazer_id: {
            type: [String, Number],
            default: '',
        }
    },
    data() {
        return {
            // confirm_modal_param: null,

            id: null,
            default_marker: null,
            quest_sequence: false,
            auto_check: false,
            first_to_find: false,
            how_many: 0,
            extra_xcoins: 0,

            // errors: {
            //     extra_xcoins: false,
            //     how_many: false,
            // },

            markers: [],
        };
    },
    async beforeMount() {
        if (this.$route?.query?.quest_id) {
            await this.getMarkersListByQuestId();
        }
        if (this.id) {
            await this.getAllMarkers();
        }
    },
    methods: {
        async getMarkersListByQuestId() {
            await baseErrHandler(async () => {
                const { markers_list } = await getMarkersListInfo(this.quest_id);
                await this.initMarkersListData(markers_list);
            })
        },
        async initMarkersListData(markers_list) {
            if (markers_list) {
                this.id = markers_list.id;
                this.default_marker = markers_list.icon;
                this.quest_sequence = markers_list.quest_sequence ? markers_list.quest_sequence : false;
                this.auto_check = markers_list.auto_check ? markers_list.auto_check : false;
                this.first_to_find = markers_list.markers_bonus ? markers_list.markers_bonus : false;
                this.extra_xcoins = markers_list.extra_coins ? markers_list.extra_coins : 0;
                this.how_many = markers_list.users_gifted ? markers_list.users_gifted : 0;
            }
        },
        async getAllMarkers() {
            if (!this.id) {
                return;
            }

            await baseErrHandler(async () => {
                const { markers } = await getAllMarkersByListId(this.id);
                this.markers = markers;
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.centered_body {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.in_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card_body {
    width: 675px;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.card_title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: #214B41;
}

.item_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: #214B41;
}

.item_in_card {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sub_item_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.sub_item {
    width: 327px;
}

.border-blue-cast {
    border-color: #599FD2;;
}

.border-red-cast {
    border-color: #DD1760;;
}

.gp-20 {
    gap: 20px;
}

.checked_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    input[type='checkbox'] {
        outline: none !important;
        color: #61CC2C;
    }
}

.label_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;

    color: #000000;
}

.parent_range {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.range_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.range_label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.percent_view {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: right;

    color: #000000;
}

.w_range {
    width: 611px;
    display: flex;
    align-items: center;

    .range_line {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 3px;
        background: #CCEAFF;
        top: calc(50% - 3px/2);
    }

    input[type='range'] {
        &::-webkit-slider-thumb {
            background: #61CC2C;
        }
    }

    .error_line {
        background: #DD1760;
    }
}

.image_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog_challenge_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .item_in_row {
        width: 327px;
    }
}
</style>
