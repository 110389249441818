<template>
    <div
        class="relative z-0"
        :class="{
            input_width: input_width,
            'w-full': !input_width
        }">
        <input
            :disabled="is_disabled"
            type="number"
            :id="id"
            class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-blue-cast"
            :class="{is_disabled: is_disabled}"
            :value="modelValue"
            @input="parseInput($event.target.value)"
        />
        <label
            :for="id"
            class="absolute duration-300 top-3 -z-1 origin-0 text-blue-cast label-title"
            :class="{gray_text: is_disabled}">
            {{ title }}
        </label>
    </div>
</template>
<script>
export default {
    name: 'InputTypeNumber',
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [Number, String],
            default: '',
        },
        input_width: {
            type: Boolean,
            default: false,
        },
        is_disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'update:modelValue': null,
        changes: null,
    },
    methods: {
        parseInput(value) {
            this.$emit('update:modelValue', value);
            this.$emit('changes');
        }
    }
}
</script>
<style lang="scss" scoped>
.input_width {
    gap: 10px;
    width: calc(100% / 4);
    max-width: 212px;
}

.is_disabled {
    color: #9CA8B6;
    border-color: #9CA8B6;
}

.gray_text {
    color: #9CA8B6;
}
</style>
