export const getQuestId = (route) => {
    let quest_id = null;

    if (route.params?.quest_id) {
        quest_id = Number.parseInt(route.params.quest_id, 10);
    } else if (route.query?.quest_id) {
        quest_id = Number.parseInt(route.query.quest_id, 10);
    }

    return quest_id;
};

export const getMarkersListId = (route) => {
    let marker_list_id = null;

    if (route.params?.marker_list_id) {
        marker_list_id = Number.parseInt(route.params.marker_list_id, 10);
    } else if (route.query?.marker_list_id) {
        marker_list_id = Number.parseInt(route.query.marker_list_id, 10);
    }

    return marker_list_id;
};

export const getQuestName = (route) => {
    let quest_name = '';

    if (route.params?.quest_name) {
        quest_name = route.params.quest_name;
    } else if (route.query?.quest_name) {
        quest_name = route.query?.quest_name;
    }

    return quest_name;
};

export const isTrailblazer = (route) => {
    let is_trailblazer = '';

    if (route.params?.is_trailblazer) {
        is_trailblazer = route.params.is_trailblazer;
    } else if (route.query?.is_trailblazer) {
        is_trailblazer = route.query?.is_trailblazer;
    }

    return is_trailblazer;
};

export const getTrailblazerId = (route) => {
    let trailblazer_id = '';

    if (route.params?.trailblazer_id) {
        trailblazer_id = Number.parseInt(route.params.trailblazer_id, 10);
    } else if (route.query?.trailblazer_id) {
        trailblazer_id = Number.parseInt(route.query?.trailblazer_id, 10);
    }

    return trailblazer_id;
};

export const getUserId = (route) => {
    let user_id = '';

    if (route.params?.user_id) {
        user_id = Number.parseInt(route.params.user_id, 10);
    } else if (route.query?.user_id) {
        user_id = Number.parseInt(route.query.user_id, 10);
    }

    return user_id;
};

export const getId = (route) => {
    let id = '';

    if (route.params?.id) {
        id = Number.parseInt(route.params.id, 10);
    } else if (route.query?.id) {
        id = Number.parseInt(route.query.id, 10);
    }

    return id;
};

export const isCreate = (route) => {
    let is_create = 1;

    if (route.params?.is_create) {
        is_create = route.params.is_create;
    } else if (route.query?.is_create) {
        is_create = route.query?.is_create;
    }

    return is_create;
};

export const withCopy = (route) => {
    let with_copy = '';

    if (route.params?.with_copy) {
        with_copy = route.params.with_copy;
    } else if (route.query?.with_copy) {
        with_copy = route.query?.with_copy;
    }
    return with_copy;
}

export const withIsComplete = (route) => {
    let is_complete = '';

    if (route.params?.is_complete) {
        is_complete = route.params.is_complete;
    } else if (route.query?.is_complete) {
        is_complete = route.query?.is_complete;
    }
    return is_complete;
}

export const getParentRout = (route) => {
    let parent_rout_name = '';
    if (route.params?.parent_rout_name) {
        parent_rout_name = route.params.parent_rout_name;
    } else if (route.query?.parent_rout_name) {
        parent_rout_name = route.query?.parent_rout_name;
    }
    return parent_rout_name;
};
