<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.57142e-10 3.50003C-1.81197e-05 1.56702 1.56699 0 3.5 0H14.4995C16.4325 0 17.9995 1.567 17.9995 3.5V10H21.4995C22.1404 10 22.791 10.2202 23.2852 10.7144C23.7793 11.2085 23.9995 11.8592 23.9995 12.5V19.5C23.9995 22.0084 22.0079 24 19.4995 24L19.4848 24L4.50015 24C2.01488 24 0.000167966 21.9853 0.000145316 19.5001L1.57142e-10 3.50003ZM20.9995 19.5V13H17.9995V19.5C17.9995 20.3487 18.6435 20.9956 19.4907 21L19.4995 21H19.5C20.3513 20.9998 20.9995 20.3514 20.9995 19.5ZM14.9995 3.5V19.5C14.9995 20.0274 15.0875 20.532 15.2499 21L4.50015 21C3.67173 21 3.00015 20.3285 3.00015 19.5L3 3.5C3 3.22386 3.22385 3 3.5 3H14.4995C14.7756 3 14.9995 3.22386 14.9995 3.5ZM4.99998 7.5C4.99998 6.67157 5.67155 6 6.49998 6H11.5C12.3284 6 13 6.67157 13 7.5C13 8.32843 12.3284 9 11.5 9H6.49998C5.67155 9 4.99998 8.32843 4.99998 7.5ZM6.49998 12C5.67155 12 4.99998 12.6716 4.99998 13.5C4.99998 14.3284 5.67155 15 6.49998 15H8.49998C9.32841 15 9.99998 14.3284 9.99998 13.5C9.99998 12.6716 9.32841 12 8.49998 12H6.49998Z" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'NewsIcon',
}
</script>
