<template>
    <div class="selected_user_inf">
        <div class="first_row">
            <div class="title_page">
                <span @click="returnToRout" class="btn_emit span_green">Users</span> / {{ user.full_name }}
            </div>
        </div>

        <div class="info_row">
            <upload-avatar
                @selected_image="selectAvatar"
                :pre_image="user.avatar"/>

            <button
                class="white_red_btn"
                @click="changeValue('password')">
                Change password
            </button>
        </div>

        <div class="info_row">
            <div class="info_column">
                <div class="item_title">
                    ID
                </div>
                <div class="item_text">
                    {{user.id}}
                </div>
            </div>

            <div class="info_column">
                <div class="item_title">
                    Registration date:
                </div>
                <div class="item_text">
                    {{returnViewDate(user.registration_time)}}
                </div>
            </div>

            <div class="info_column">
                <div class="item_title">
                    Email:
                </div>
                <div class="item_text email_wrap">
                    <div class="email_wrap">
                        {{user.email}}
                    </div>
                    <div
                        @click="changeValue('email')"
                        class="btn_emit green_strong">
                        Change
                    </div>
                </div>
            </div>
        </div>

        <div class="info_row">
            <div class="info_column">
                <div class="item_title">
                    Xcoins
                </div>
                <div class="item_text">
                    <div>
                        {{user?.xcoins ? user.xcoins : 0}}
                    </div>
                    <div
                        @click="changeValue('xcoins')"
                        class="btn_emit green_strong">
                        Change
                    </div>
                </div>
            </div>

            <div class="info_column">
                <div class="item_title">
                    Points
                </div>
                <div class="item_text">
                    <div>
                        {{user?.points ? user.points : 0}}
                    </div>
                    <div
                        @click="changeValue('points')"
                        class="btn_emit green_strong">
                        Change
                    </div>
                </div>
            </div>

            <div class="info_column">
                <div class="item_title">
                    Global leaderboard position:
                </div>
                <div class="item_text">
                    {{ user?.global_leaderboard_position ? user.global_leaderboard_position : 0}}
                </div>
            </div>
        </div>

        <div class="info_row">
            <div class="info_column">
                <div class="item_title">
                    Completed quests:
                </div>
                <div class="item_text">
                    {{user.completed_quests}}
                </div>
            </div>

            <div class="info_column">
                <div class="item_title">
                    Checked in markers:
                </div>
                <div class="item_text">
                    {{user.checked_in_markers}}
                </div>
            </div>

            <div class="info_column">
                <div class="item_title">
                    Completed challenges:
                </div>
                <div class="item_text">
                    {{user.completed_challenges}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {getDateFormat} from "@/helpers/time.helper";
import UploadCloudIcon from "@/components/svg/UploadCloudIcon.vue";
import EditPencilIcon from "@/components/svg/EditPencilIcon.vue";
import UploadAvatar from "@/components/UI/images/UploadAvatar.vue";

export default {
    name: 'FirstRowUsersDetail',
    components: {UploadAvatar, EditPencilIcon, UploadCloudIcon},
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    emits: {
        'change_value': null,
        'selected_avatar': null,
    },
    methods: {
        async returnToRout() {
            await this.$router.push({name: 'UsersAdmin'});
        },
        returnViewDate(val) {
            return getDateFormat(val, this.timezoneOffset);
        },
        changeValue(type) {
            this.$emit('change_value', type);
        },
        selectAvatar(image) {
            this.$emit('selected_avatar', image);
        },
    },
    computed: {
        timezoneOffset() {
            const timezoneOffset = new Date().getTimezoneOffset();
            const offset = Math.abs(timezoneOffset);
            const offsetOperator = timezoneOffset < 0 ? '+' : '-';
            const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
            const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');

            return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
        },
    }
}
</script>
<style lang="scss" scoped>
.selected_user_inf {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 44px;
}

.first_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.span_green {
    color: #61CC2C;
}

.info_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.avatar_row {
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.info_column {
    width: calc((100% - 40px) / 3);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item_title {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;

    color: #599FD2;
}

.item_text {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;

    color: #364652;
}

.green_strong {
    color: #61CC2C;
    font-weight: 700;
}

.email_wrap {
    overflow-wrap: anywhere;
}

.avatar_div {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 16px;

    img {
        width: 100%;
        height: 100%;
    }
}
</style>
