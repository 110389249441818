<template>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 6C1 3.51472 3.01472 1.5 5.5 1.5H9.5C10.3284 1.5 11 2.17157 11 3C11 3.82843 10.3284 4.5 9.5 4.5H5.5C4.67157 4.5 4 5.17157 4 6V19C4 19.8284 4.67157 20.5 5.5 20.5H9.5C10.3284 20.5 11 21.1716 11 22C11 22.8284 10.3284 23.5 9.5 23.5H5.5C3.01472 23.5 1 21.4853 1 19V6ZM15.9393 6.93934C16.5251 6.35355 17.4749 6.35355 18.0607 6.93934L22.5607 11.4393C23.1464 12.0251 23.1464 12.9749 22.5607 13.5607L18.0607 18.0607C17.4749 18.6464 16.5251 18.6464 15.9393 18.0607C15.3536 17.4749 15.3536 16.5251 15.9393 15.9393L17.8787 14H10C9.17157 14 8.5 13.3284 8.5 12.5C8.5 11.6716 9.17157 11 10 11H17.8787L15.9393 9.06066C15.3536 8.47487 15.3536 7.52513 15.9393 6.93934Z" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'LogOutIcon',
}
</script>
