<template>
    <div class="first_row">
        <div class="title_page">
            <span @click="returnToRout" class="btn_emit">{{ title }}</span> / {{ name }}
        </div>

        <button v-if="rout_button" class="white_green_btn" @click="$router.push(rout_button)">
            Edit
        </button>
    </div>
</template>
<script>
export default {
    name: 'FirstActiveRow',
    props: {
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        rout_name: {
            type:String,
            default: '',
        },
        rout_button: {
            type: String,
            default: '',
        },
    },
    methods: {
        async returnToRout() {
            if (this.rout_name) {
                await this.$router.push({name: this.rout_name});
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.first_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
        color: #61CC2C;
    }
}
</style>
