<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4762_9177)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.58832 2.56249C5.17512 1.97771 5.17676 1.02797 4.59198 0.441171C4.00721 -0.145625 3.05746 -0.147263 2.47066 0.437511C1.57644 1.32866 0.806216 2.34485 0.188429 3.45818C-0.213528 4.18256 0.0478459 5.09563 0.772223 5.49759C1.4966 5.89955 2.40967 5.63817 2.81163 4.9138C3.29198 4.04814 3.89152 3.25689 4.58832 2.56249ZM21.5294 0.437511C20.9426 -0.147264 19.9929 -0.145625 19.4081 0.441172C18.8233 1.02797 18.8249 1.97771 19.4117 2.56249C20.1085 3.25689 20.7081 4.04814 21.1884 4.9138C21.5904 5.63817 22.5035 5.89955 23.2278 5.49759C23.9522 5.09563 24.2136 4.18256 23.8116 3.45818C23.1938 2.34485 22.4236 1.32866 21.5294 0.437511ZM3.00003 10C3.00003 5.02943 7.02947 1 12 1C16.9706 1 21 5.02944 21 10V21.0001H22.5C23.3285 21.0001 24 21.6716 24 22.5001C24 23.3285 23.3285 24.0001 22.5 24.0001H1.50003C0.671604 24.0001 3.05019e-05 23.3285 3.05019e-05 22.5001C3.05019e-05 21.6716 0.671604 21.0001 1.50003 21.0001H3.00003V10ZM18 10V21.0001H6.00003V10C6.00003 6.68629 8.68632 4 12 4C15.3137 4 18 6.68629 18 10ZM13.5 8.5C13.5 7.67157 12.8284 7 12 7C11.1716 7 10.5 7.67157 10.5 8.5V13.5C10.5 14.3284 11.1716 15 12 15C12.8284 15 13.5 14.3284 13.5 13.5V8.5ZM13.5 18.0001C13.5 18.8285 12.8285 19.5001 12 19.5001C11.1716 19.5001 10.5 18.8285 10.5 18.0001C10.5 17.1716 11.1716 16.5001 12 16.5001C12.8285 16.5001 13.5 17.1716 13.5 18.0001Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_4762_9177">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
<script>
export default {
    name: 'ReportsMenuIcon',
}
</script>
